<template>
  <div>
    {{ /*enter new trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      v-model="searchForTradesModal"
      :title="$t('fx_options.titles.enter_new_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('fx_options.modals.create_trade_modal_ok')"
      :cancel-title="$t('fx_options.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->

                  <b-card
                    :title="$t('fx_options.titles.client_counterparty_details')"
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.client') "
                        >
                          <div>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.client')"
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="true"
                                class="compact_vue-select"
                                :placeholder="
                                  $t('fx_options.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :filterable="true"
                                ref="client"
                                @option:selected="selectedClient"
                                
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.client_account') 
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.client_account')"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.client_account')
                              "
                              ref="clientAcc"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counterparty -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty') 
                          "
                        >
                          <div>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.counterparty')"
                            >
                              <v-select
                                ref="cpty"
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                class="compact_vue-select"
                                @option:selected="selectedCounterparty"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.cpty_account') 
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cpty_account')"
                          >
                            <b-form-input
                              ref="cptyAcc"
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* trade Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                ref="TradeDate"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_date')
                                "
                                v-model="tradeDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearTradeDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_date')"
                          >
                            <b-input-group
                            >
                              <flat-pickr
                                ref="settlDate"
                                :placeholder="
                                  $t('fx_options.trade_form.settlement_date')
                                "
                                v-model="settleDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearSettleDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- order time-->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_time')"
                          >
                          <div>
                              <b-input-group>
                                <cleave
                                  id="orderTime"
                                  ref="orderTime"
                                  v-model="tradeForm.orderTime"
                                  class="form-control"
                                  :raw="false"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('fx_options.trade_form.min')
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  
                                />
                                <cleave
                                  id="orderTime"
                                  ref="orderTime"
                                  v-model="tradeForm.orderTimeEnd"
                                  class="form-control"
                                  :raw="false"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('fx_options.trade_form.max')
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  
                                />
                              </b-input-group>
                            </div>
                            
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.execution_time')"
                           
                          >
                          <div>
                              <b-input-group>
                                <cleave
                                  id="executionTime"
                                  ref="executionTime"
                                  v-model="tradeForm.executionTime"
                                  class="form-control"
                                  :raw="false"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('fx_options.trade_form.min')
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  
                                />
                                <cleave
                                  id="executionTime"
                                  ref="executionTime"
                                  v-model="tradeForm.executionTimeEnd"
                                  class="form-control"
                                  :raw="false"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('fx_options.trade_form.max')
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  
                                />
                              </b-input-group>
                            </div>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party trader -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              ref="cptyTrader"
                              v-model="tradeForm.counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- TC user -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.sfc_user')"
                          >
                            <v-select
                              ref="tcUser"
                              v-model="tradeForm.tcUser"
                              :options="optTcUsers"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_taken_via')"
                          >
                            <v-select
                              ref="orderTakenVia"
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken given through -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              ref="orderGivenThrough"
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.acting_capacity')"
                          >
                            <v-select
                              ref="actingCap"
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cut off time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.cut_off_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cut_off_time')"
                          >
                            <v-select
                              v-model="tradeForm.cutOffTime"
                              :options="optCutOffTimes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.cut_off_time')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- deliverable ccy-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.deliverable_ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.deliverable_ccy')"
                          >
                            <v-select
                              ref="deliverableCcy"
                              v-model="tradeForm.deliverableCcy"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.deliverable_ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="traderNote"
                              id="form-textarea"
                              :placeholder="$t('fx_options.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.settlement_details')">
                    <b-row>
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationDate')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationTime')"
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Confo Date -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationDateClient')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTimeClient')
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CpRef')"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CptyUti')"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.TcUti')"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.UniqueLinkId')
                              "
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Clearing Member Id -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClearingMemberId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="clearingMemberId"
                          >
                            <b-form-input
                              id="clearingMemberId"
                              ref="clearingMemberId"
                              v-model="tradeForm.clearingMemberId"
                              :name="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- trade ref -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('fx_options.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_ref')
                                "
                                
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                <!-- /*******************************Option Details***************************************************************** */ -->

                <b-card
                    :title="$t('fx_options.titles.option_details')"
                  >
                    <b-row>
                      <!-- buy/sell -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.buy_sell')"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="true"
                              ref="buySell"
                              :placeholder="
                                $t('fx_options.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- settlement type-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_type')"
                          >
                            <v-select
                              ref="settlementType"
                              v-model="tradeForm.settlementType"
                              :options="optSettlementTypes"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                          >
                            <v-select
                              ref="costCenter"
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Currency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency1')"
                          >
                            <v-select
                              ref="ccy1"
                              v-model="tradeForm.ccy1"
                              :options="optCurrency1Types"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency1(tradeForm.ccy1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- currency 2 -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency2')"
                          >
                            <v-select
                              ref="ccy2"
                              v-model="tradeForm.ccy2"
                              :options="optCurrency2Types"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency2')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency2(tradeForm.ccy2)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Exercise -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_exercise')"
                          >
                            <v-select
                              ref="optionExercise"
                              v-model="tradeForm.optionExercise"
                              :options="optOptionExercise"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_option_exercise'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Type -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_type')"
                          >
                            <v-select
                              ref="optionType"
                              v-model="tradeForm.optionType"
                              :options="optOptionTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.select_option_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    
                      <!-- Type1 Exercise -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_exercise')"
                          >
                            <v-select
                              ref="type1Exercise"
                              v-model="tradeForm.type1Exercise"
                              :options="optOptionExercise"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.type1_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 1 -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1')"
                          >
                            <v-select
                              ref="type1"
                              v-model="tradeForm.type1"
                              :options="optTypes"
                              :clearable="true"
                              :placeholder="$t('fx_options.trade_form.type1')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 1 Level -->
                      <b-col  v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_level')"
                          >
                            
                            <div>
                              <b-input-group>
                                <cleave
                                    id="type1Level"
                                    ref="type1Level"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.type1Level"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="
                                      $t('fx_options.trade_form.min')
                                    "
                                    
                                  />
                                  <cleave
                                    id="type1Level"
                                    ref="type1Level"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.type1LevelMax"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="
                                      $t('fx_options.trade_form.max')
                                    "
                                    
                                  />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 Exercise -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_exercise')"
                          >
                            <v-select
                              ref="type2Exercise"
                              v-model="tradeForm.type2Exercise"
                              :options="optOptionExercise"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.type2_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2')"
                          >
                            <v-select
                              ref="type2"
                              v-model="tradeForm.type2"
                              :options="optTypes"
                              :clearable="true"
                              :placeholder="$t('fx_options.trade_form.type2')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 2 Level -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_level')"
                          >
                            
                            <div>
                              <b-input-group>
                                <cleave
                                    id="type2Level"
                                    ref="type2Level"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.type2Level"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="
                                      $t('fx_options.trade_form.min')
                                    "
                                    
                                  />
                                  <cleave
                                    id="type2Level"
                                    ref="type2Level"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.type2LevelMax"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="
                                      $t('fx_options.trade_form.max')
                                    "
                                    
                                  />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window Start-->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_start')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_start')"
                          >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_start')
                              "
                              ref="windowStart"
                              v-model="windowStartRange"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <b-input-group-append>
                                <b-button
                                  @click="clearWindowStartDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window End -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_end')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_end')"
                          >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_end')
                              "
                              ref="windowEnd"
                              v-model="windowEndRange"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              
                            />
                            <b-input-group-append>
                                <b-button
                                  @click="clearWindowEndDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CallPut 1 -->
                      <b-col  cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut1')"
                          >
                            <v-select
                              ref="callPut1"
                              v-model="tradeForm.callPut1"
                              :options="optCallPut"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCallPut(tradeForm.callPut1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- callPut 2 -->
                      <b-col  cols="6" xl="3" lg="3" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut2')"
                          >
                            <b-form-input
                              id="callPut2"
                              ref="callPut2"
                              v-model="tradeForm.callPut2"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut2')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Strike -->
                      <b-col  cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Strike')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Strike')"
                            
                          >
                            
                            <div>
                              <b-input-group>
                                <cleave
                                    id="strike"
                                    ref="strike"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.strike"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="$t('fx_options.trade_form.min')"
                                    
                                  />
                                  <cleave
                                    id="strike"
                                    ref="strike"
                                    :state="errors.length > 0 ? false : null"
                                    v-model="tradeForm.strikeMax"
                                    class="form-control"
                                    :raw="true"
                                    :options="cleave4DecimalOptions.number"
                                    :placeholder="$t('fx_options.trade_form.max')"
                                    
                                  />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Expiry date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Expiry')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Expiry')"
                          >
                            <b-input-group>
                              <flat-pickr
                                ref="expiry"
                                :placeholder="
                                  $t('fx_options.trade_form.Expiry')
                                "
                                v-model="expiryDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearExpiryDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- value Date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ValueDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ValueDate')"
                          >
                            <b-input-group>
                              <flat-pickr
                                ref="valueDate"
                                :placeholder="
                                  $t('fx_options.trade_form.ValueDate')
                                "
                                v-model="valueDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearValueDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- NotionalCurrency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.NotionalCurrency')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.NotionalCurrency')"
                          >
                            <v-select
                              ref="notionalCcy"
                              v-model="tradeForm.notionalCurrency"
                              :options="optNotionalCurrencies"
                              :clearable="true"
                              :placeholder="
                                $t('fx_options.trade_form.NotionalCurrency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedNotionalCurrency(
                                  tradeForm.notionalCurrency
                                )
                              "
                              :disabled="tradeForm.ccy1 == null && tradeForm.ccy2 == null"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notional -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Notional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Notional')"
                          >
                          <div>
                              <b-input-group>
                                <cleave
                                  id="notional"
                                  ref="notional"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.notional"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_options.trade_form.min')
                                  "
                                  
                                />
                                <cleave
                                  id="notional"
                                  ref="notional"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.notionalMax"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_options.trade_form.max')
                                  "
                                  
                                />
                              </b-input-group>
                            </div>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- spotRef -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            
                          >
                            
                            <div>
                              <b-input-group>
                                <cleave
                                  id="spot_ref"
                                  ref="spotRef"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spotRef"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_options.trade_form.min')
                                  "
                                  
                                />
                                <cleave
                                  id="spot_ref"
                                  ref="spotRef"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spotRefMax"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_options.trade_form.max')
                                  "
                                  
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty prem Counterparty Pct Premium Currency -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t(
                              'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyPctPremiumCurrency"
                              :options="optCounterpartyPctPreCurrencies"
                              :clearable="true"
                              ref="premCcy"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCounterpartyPctPremiumCurrency(
                                  tradeForm.counterpartyPctPremiumCurrency
                                )
                              "
                              :disabled="tradeForm.ccy1 == null && tradeForm.ccy2 == null"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPctPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPctPremium')
                            "
                            :rules="'min_value:0|max_value:100'"
                          >
                          <div>
                            <b-input-group prepend="%" :append="tradeForm.counterpartyPctPremiumCurrency">
                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.counterpartyPctPremium
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                ref="cptyPctPrem"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.min'
                                  )
                                "
                                
                              />

                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.counterpartyPctPremiumMax
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                ref="cptyPctPrem"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.max'
                                  )
                                "
                                
                              />
                            </b-input-group>
                          </div>
                            

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPctPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPctPremium')"
                            :rules="'min_value:0|max_value:100'"
                          >
                          <div>
                            <b-input-group :append="tradeForm.clientPctPremiumCurrency" prepend="%">
                              <b-form-input
                                id="clientPctPremium"
                                v-model.number="tradeForm.clientPctPremium"
                                name="clientPctPremium"
                                ref="clientPctPrem"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />

                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.clientPctPremiumMax
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                ref="cptyPctPrem"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.max'
                                  )
                                "
                                
                              />
                            </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- use client cpty percent or client percent value radio button -->
                      <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" class="mt-1 padleft-0">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.usePercentOrPrice"
                            :options="optPercentOrPrice"
                            name="radios-stacked"
                            stacked
                            plain
                            class="fxDetailRadio"
                            
                          />
                        </b-form-group>
                      </b-col> -->

                      

                      <!-- CounterpartyPremium price -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPremium')
                            "
                          >
                          <div>
                            <b-input-group :append="tradeForm.counterpartyPremiumCurrency" class="compact_form_input-group" ref="groupAppend">
                              <cleave
                                id="counterpartyPremium"
                                ref="cptyPrem"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyPremium"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />
                              <cleave
                                id="counterpartyPremium"
                                ref="cptyPrem"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyPremiumMax"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.max')
                                "
                                
                              />
                            </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Premium -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPremium')"
                            
                          >
                          <div>
                            <b-input-group :append="tradeForm.clientPremiumCurrency" class="compact_form_input-group" ref="groupAppend">
                              <cleave
                                id="clientPremium"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientPremium"
                                class="form-control"
                                ref="clientPrem"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />
                              <cleave
                                id="clientPremium"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientPremiumMax"
                                class="form-control"
                                ref="clientPrem"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.max')
                                "
                                
                              />
                            </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    

                      <!-- Tc Commision -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcCommision')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcCommision')"
                          >
                          <div>
                            <b-input-group :append="tradeForm.tcCommisionCurrency" class="compact_form_input-group" ref="groupAppend">
                              <cleave
                                id="tcCommision"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.tcCommision"
                                ref="tcComm"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />
                              <cleave
                                id="tcCommision"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.tcCommisionMax"
                                ref="tcComm"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.max')
                                "
                                
                              />
                            </b-input-group>
                        </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Collateral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Collateral')"
                          >
                          <div>
                            <b-input-group :append="tradeForm.collateralCcy" class="compact_form_input-group" ref="groupAppend">

                              <cleave
                                ref="collateral"
                                id="collateral"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collateral"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />
                              <cleave
                                ref="collateral"
                                id="collateral"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collateralMax"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.max')
                                "
                                
                              />
                            </b-input-group>
                        </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- CollateralPct -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CollateralPct')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CollateralPct')"
                            :rules="'min_value:0|max_value:100'"
                          >

                            <div>
                              <b-input-group ref="groupAppend" prepend="%" :append="tradeForm.collateralCcy" class="compact_form_input-group">
                                <b-form-input
                                  ref="collateralPct"
                                  id="collateralPct"
                                  v-model.number="tradeForm.collateralPct"
                                  name="collateralPct"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('fx_options.trade_form.min')
                                  "
                                  
                                />
                                <b-form-input
                                  ref="collateralPct"
                                  id="collateralPct"
                                  v-model.number="tradeForm.collateralPctMax"
                                  name="collateralPct"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('fx_options.trade_form.max')
                                  "
                                  
                                />
                              </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>


                      <!-- cpty open  -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.OpenNotional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.OpenNotional')"
                          >
                          <div>
                          <b-input-group ref="groupAppend" :append="tradeForm.openNotionalCcy" class="compact_form_input-group">

                            <cleave
                              ref="openNotional"
                              id="openNotional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.openNotional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.min')
                              "
                              
                            />
                            <cleave
                              ref="openNotional"
                              id="openNotional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.openNotionalMax"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.max')
                              "
                              
                            />
                          </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                     
                      <!-- Client Open -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientOpen')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientOpen')"
                          >
                          <div>
                            <b-input-group ref="groupAppend" :append="tradeForm.clientOpenCcy" class="compact_form_input-group">
                              <cleave
                                id="clientOpen"
                                ref="clientOpen"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientOpen"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.min')
                                "
                                
                              />
                              <cleave
                                id="clientOpen"
                                ref="clientOpen"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientOpenMax"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.max')
                                "
                                
                              />
                            </b-input-group>
                          </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  </b-card>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("fx_options.overlay_messages.searching_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingSavedSearch"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showSearchInputs"
                class="float-right compact_form_checkbox"
                style="margin-right:0.8rem"
                plain
              >
                {{ "Save this search" }}
              </b-form-checkbox>

              <validation-observer
                ref="tradeSearchValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showSearchInputs ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showSearchInputs"
                    v-model="searchName"
                    placeholder="Enter search name"
                    id="searchName"
                    name="searchName"
                    class="float-right animate__animated animate__fadeIn compact_form-input"
                    type="text"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                class="btn gradient-info animate__animated animate__fadeIn modal-btn"
                size="sm"
                variant="gradient-info"
                @click="saveSearch"
                v-if="showSearchInputs"
              >
                Save this search
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              text="My Saved Searches"
              dropup
              right
              class="mr-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="savedSearchesList.length > 0"
            >
              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1 modal-btn"
                  style="color:red;"
                  @click="deleteSavedSearch(n.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectSavedSearch(n.SearchData)"
                  >{{ n.SearchName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-secondary mr-1"
              @click="resetSearchForTradesModal"
              size="sm"
            >
              {{ $t("fx_options.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-primary mr-1 modal-btn"
              variant="primary"
              @click="searchForTrades"
              size="sm"
            >
              {{ $t("fx_options.modals.search_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** enter new trade modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXOptionsStoreModule from "./FXOptionsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
    // Register module
    if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXOPTION_APP_STORE_MODULE_NAME,
        fXOptionsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      searchForTradesModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      loadingSavedSearch: false,
      searchName: null,
      optSearches: [],
      showSearchInputs: false,
      updownButtonText: "down",
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optbuySells: ["Buy", "Sell"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optOptionExercise: ["American", "European"],
      optCallPut: ["Call", "Put"],
      optOptionTypes: ["Vanilla", "Exotic"],
      optTypes: ["KI", "KO", "OT", "NT"],
      optPercentOrPrice: [
        {
          text: "Calculate Client & Cpty from Percent",
          value: "Calculate Client & Cpty from Percent",
        },
        {
          text: "Calculate Client & Cpty from Price",
          value: "Calculate Client & Cpty from Price",
        },
      ],
      optPriceTypes: [],
      optCurrency1Types: [],
      optCurrency2Types: [],
      optNotionalCurrencies: [],
      optCounterpartyPctPreCurrencies: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optCounterPartyTraders: [],
      optTcUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,

      expiryDateRange: null,
      tradeDateRange: null,
      settleDateRange: null,
      windowStartRange: null,
      windowEndRange:null,
      valueDateRange:null,
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: null,
        prop: false,
        settlementType: null,
        currency1: null,
        currency2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type1LevelMax:null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        type2LevelMax:null,
        windowStart: null,
        windowStart_end: null,
        windowEnd: null,
        windowEnd_end: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        strikeMax:null,
        expiryDate: null,
        expiryDateName: null,
        expiryDateEnd: null,
        valueDate: null,
        valueDateName: null,
        valueDateEnd:null,
        notionalCurrency: null,
        notional: null,
        notionalMax:null,
        spotRef: null,
        spotRefMax:null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        counterpartyPctPremiumMax:null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        clientPctPremiumMax:null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        counterpartyPremiumMax:null,
        clientPremiumCurrency: null,
        clientPremium: null,
        clientPremiumMax:null,
        tcCommisionCurrency: null,
        tcCommision: null,
        tcCommisionMax:null,
        collateralPct: null,
        collateralPctMax:null,
        collateralCcy: null,
        collateral: null,
        collateralMax:null,
        openNotionalCcy: null,
        openNotional: null,
        openNotionalMax:null,
        clientOpenCcy: null,
        clientOpen: null,
        clientOpenMax:null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        tradeDateEnd: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        settlementDateEnd: null,
        orderTime: null,
        orderTimeEnd:null,
        executionTime: null,
        executionTimeEnd:null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
        tradeRef:null
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },

      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      }
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    savedSearchesList() {
      return this.optSearches;
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxOption"]),
    deleteSavedSearch(id) {
      store
        .dispatch("fx_options/deleteSavedSearch", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {
      this.loadingSavedSearch = true;
      this.tradeForm.tradeDateRange = this.tradeDateRange;
      this.tradeForm.settleDateRange = this.settleDateRange;
      this.tradeForm.windowStartRange = this.windowStartRange;
      this.tradeForm.windowEndRange = this.windowEndRange;
      this.tradeForm.expiryDateRange = this.expiryDateRange;
      this.tradeForm.valueDateRange = this.valueDateRange;
      store
        .dispatch("fx_options/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: "FxOption",
          LastSearch: 0
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
          this.loadingSavedSearch = false;
        })
        .catch((error) => {
          this.errorMessage(error);
          this.loadingSavedSearch = false;
          console.log(error);
        });
    },

    selectSavedSearch(savedTrade) {
      if (savedTrade) {
        this.tradeDateRange = savedTrade.tradeDateRange;
        this.settleDateRange = savedTrade.settleDateRange;
        this.expiryDateRange = savedTrade.expiryDateRange;
        this.windowStartRange = savedTrade.windowStartRange;
        this.windowEndRange = savedTrade.windowEndRange;
        this.valueDateRange = savedTrade.valueDateRange;

        this.tradeForm = savedTrade;
      }
    },

    getSavedSearches() {
      store
        .dispatch("fx_options/getSavedSearches", { SearchType: "FxOption" })
        .then((res) => {
          if(res.data.savedSearches.length > 0){
            this.optSearches = _.remove(res.data.savedSearches, {
                LastSearch: false
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fx_options/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fx_options/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("fx_options.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fx_options/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
            this.optCurrency1Types = res.data.currencies;
            this.optCurrency2Types = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("FxOption", "Search").then((res) => {
        if (res.data.valid) {
          this.tradeModalLoading = true;
          //set and check search data
          let searchData = this.setSearchData();

          store
            .dispatch("fx_options/searchFxOption", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              this.saveLastSearchFxOption(searchData);
              this.resetSearchForTradesModal();
              this.tradeModalLoading = false;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.tradeModalLoading = false;
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },

    selectedClient(search) {
      this.tradeForm.clientAccount = null;
      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      console.log(search)
    },
    selectedCounterparty(search) {
      
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );

      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },

    selectedCallPut(type) {
      if (type == "Call") {
        this.tradeForm.callPut2 = "Put";
      } else {
        this.tradeForm.callPut2 = "Call";
      }
    },
    selectedCurrency1(ccy) {
      if (this.optPriceTypes.length > 0) {
        // remove same currency
        let clonedCurrencies = JSON.parse(JSON.stringify(this.optPriceTypes));

        var index = clonedCurrencies.indexOf(this.tradeForm.ccy1);
        if (index !== -1) {
          clonedCurrencies.splice(index, 1);
        }

        this.optCurrency2Types = clonedCurrencies;
      }
      
      this.arrangePremiumCurrencies(true);
      this.copyPremiumCCY1Currencies();
    },
    selectedCurrency2(ccy) {
      if (this.optPriceTypes.length > 0) {
        // remove same currency
        let clonedCurrencies = JSON.parse(JSON.stringify(this.optPriceTypes));

        var index = clonedCurrencies.indexOf(this.tradeForm.ccy2);
        if (index !== -1) {
          clonedCurrencies.splice(index, 1);
        }

        this.optCurrency1Types = clonedCurrencies;
        this.arrangePremiumCurrencies(true);
      }
    },
    selectedNotionalCurrency(ccy) {
      this.tradeForm.collateralCcy = this.tradeForm.notionalCurrency;
      this.tradeForm.openNotionalCcy = this.tradeForm.notionalCurrency;
      this.tradeForm.clientOpenCcy = this.tradeForm.notionalCurrency;
    },
    selectedCounterpartyPctPremiumCurrency(ccy) {
      if (
        ccy != this.tradeForm.ccy1 &&
        this.tradeForm.counterpartyPctPremiumCurrency != this.tradeForm.ccy2
      ) {
        this.tradeForm.counterpartyPctPremiumCurrency = this.tradeForm.ccy1;
      }
    },
    arrangePremiumCurrencies(clearBefore) {
      if (clearBefore) {
        this.optNotionalCurrencies = [];
        this.optNotionalCurrencies = [this.tradeForm.ccy1, this.tradeForm.ccy2];
        this.tradeForm.notionalCurrency = this.tradeForm.ccy1;
      }

      if (clearBefore) {
        this.optCounterpartyPctPreCurrencies = [];
        this.optCounterpartyPctPreCurrencies = [
          this.tradeForm.ccy1,
          this.tradeForm.ccy2,
        ];
        this.tradeForm.counterpartyPctPremiumCurrency = this.tradeForm.ccy1;
      }

      if (this.tradeForm.ccy1 != null && this.tradeForm.ccy2 != null) {
        if (this.tradeForm.ccy1.localeCompare(this.tradeForm.ccy2) == -1) {
          this.tradeForm.deliverableCcy = this.tradeForm.ccy1;
        } else {
          this.tradeForm.deliverableCcy = this.tradeForm.ccy2;
        }
      }

      
    },
    copyPremiumCCY1Currencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.ccy1;

      this.tradeForm.openNotionalCcy = this.tradeForm.ccy1;
      this.tradeForm.clientOpenCcy = this.tradeForm.ccy1;
      this.tradeForm.collateralCcy = this.tradeForm.ccy1;
    },
    copyCounterpartyPctCurrencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.counterpartyPctPremiumCurrency;

    },
    clearTradeDate() {
      this.tradeDateRange = null;
      this.tradeForm.tradeDate = null;
      this.tradeForm.tradeDateEnd = null;
    },
    clearSettleDate() {
      this.settleDateRange = null;
      this.tradeForm.settlementDate = null;
      this.tradeForm.settlementDateEnd = null;
    },
    clearExpiryDate() {
      this.expiryDateRange = null;
      this.tradeForm.expiryDate = null;
      this.tradeForm.expiryDateEnd = null;
    },
    clearWindowStartDate() {
      this.windowStartRange = null;
      this.tradeForm.windowStart = null;
      this.tradeForm.windowStart_end = null;
    },
    clearValueDate() {
      this.valueDateRange = null;
      this.tradeForm.valueDate = null;
      this.tradeForm.valueDateEnd = null;
    },
    clearWindowEndDate() {
      this.windowEndRange = null;
      this.tradeForm.windowEnd = null;
      this.tradeForm.windowEnd_end = null;
    },

    resetSearchForTradesModal() {
      this.tradeModalLoading = false;
      this.checkedHolidayDate = null;
      this.fetchedTradeData = {};

      this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: null,
        prop: false,
        settlementType: null,
        currency1: null,
        currency2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type1LevelMax:null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        type2LevelMax:null,
        windowStart: null,
        windowStart_end: null,
        windowEnd: null,
        windowEnd_end: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        strikeMax:null,
        expiryDate: null,
        expiryDateName: null,
        expiryDateEnd: null,
        valueDate: null,
        valueDateName: null,
        valueDateEnd:null,
        notionalCurrency: null,
        notional: null,
        notionalMax:null,
        spotRef: null,
        spotRefMax:null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        counterpartyPctPremiumMax:null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        clientPctPremiumMax:null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        counterpartyPremiumMax:null,
        clientPremiumCurrency: null,
        clientPremium: null,
        clientPremiumMax:null,
        tcCommisionCurrency: null,
        tcCommision: null,
        tcCommisionMax:null,
        collateralPct: null,
        collateralPctMax:null,
        collateralCcy: null,
        collateral: null,
        collateralMax:null,
        openNotionalCcy: null,
        openNotional: null,
        openNotionalMax:null,
        clientOpenCcy: null,
        clientOpen: null,
        clientOpenMax:null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        tradeDateEnd: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        settlementDateEnd: null,
        orderTime: null,
        orderTimeEnd:null,
        executionTime: null,
        executionTimeEnd:null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
        tradeRef:null
      },

      this.optPriceTypes = [];
      this.optCurrency1Types = [];
      this.optCurrency2Types = [];
      this.optNotionalCurrencies = [];
      this.optCounterPartyTraders = [];
      this.optTcUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.tradeDateRange = null;
      this.settleDateRange = null;
      this.expiryDateRange = null;
      this.valueDateRange = null;
      this.windowStartRange = null;
      this.windowEndRange = null;
      this.searchForTradesModal = false;
    },

    
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_options.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_options.toast_messages.trade_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    saveDefaultEntrySuccessToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("general_title.save_default_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("fx_options.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (value == undefined || value == null) return Math.round(0);
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val != null) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    roundToPointFour(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 10000) / 10000;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setSearchData() {
      let setSearch = {
        CostCenter: this.tradeForm.costCenter,
        Client: this.tradeForm.client != null ? this.tradeForm.client : null,
        ClientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        Cpty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        CptyAcc:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,

        BuySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        SettlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        Ccy1: this.tradeForm.ccy1 != null ? this.tradeForm.ccy1 : null,
        Ccy2: this.tradeForm.ccy2 != null ? this.tradeForm.ccy2 : null,
        OptionType:
          this.tradeForm.optionType != null ? this.tradeForm.optionType : null,
        OptionExercise:
          this.tradeForm.optionExercise != null
            ? this.tradeForm.optionExercise
            : null,
        Type1: this.tradeForm.type1 != null ? this.tradeForm.type1 : null,
        Type1Level:
          this.tradeForm.type1Level != null
            ? parseFloat(this.tradeForm.type1Level)
            : null,
        Type1LevelMax:
          this.tradeForm.type1LevelMax != null
            ? parseFloat(this.tradeForm.type1LevelMax)
            : null,
        Type1Exercise:
          this.tradeForm.type1Exercise != null
            ? this.tradeForm.type1Exercise
            : null,
        Type2: this.tradeForm.type2 != null ? this.tradeForm.type2 : null,
        Type2Level:
          this.tradeForm.type2Level != null
            ? parseFloat(this.tradeForm.type2Level)
            : null,
        Type2LevelMax:
          this.tradeForm.type2LevelMax != null
            ? parseFloat(this.tradeForm.type2LevelMax)
            : null,
        Type2Exercise:
          this.tradeForm.type2Exercise != null
            ? this.tradeForm.type2Exercise
            : null,
        WindowStart:
          this.tradeForm.windowStart != null
            ? this.YMDdateFormat(this.tradeForm.windowStart)
            : null,
        WindowStart_end:
          this.tradeForm.windowStart_end != null
            ? this.YMDdateFormat(this.tradeForm.windowStart_end)
            : null,
        WindowEnd:
          this.tradeForm.windowEnd != null
            ? this.YMDdateFormat(this.tradeForm.windowEnd)
            : null,
        WindowEnd_end:
          this.tradeForm.windowEnd_end != null
            ? this.YMDdateFormat(this.tradeForm.windowEnd_end)
            : null,
        CallPut1:
          this.tradeForm.callPut1 != null ? this.tradeForm.callPut1 : null,
        CallPut2:
          this.tradeForm.callPut2 != null ? this.tradeForm.callPut2 : null,
        Strike:
          this.tradeForm.strike != null
            ? parseFloat(this.tradeForm.strike)
            : null,
        StrikeMax:
          this.tradeForm.strikeMax != null
            ? parseFloat(this.tradeForm.strikeMax)
            : null,
        Expiry:
          this.tradeForm.expiryDate != null
            ? this.YMDdateFormat(this.tradeForm.expiryDate)
            : null,
        ExpiryEnd:
          this.tradeForm.expiryDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.expiryDateEnd)
            : null,
        ValueDate:
          this.tradeForm.valueDate != null
            ? this.YMDdateFormat(this.tradeForm.valueDate)
            : null,
        ValueDateEnd:
          this.tradeForm.valueDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.valueDateEnd)
            : null,
        NotionalCcy:
          this.tradeForm.notionalCurrency != null
            ? this.tradeForm.notionalCurrency
            : null,
        Notional:
          this.tradeForm.notional != null
            ? parseFloat(this.tradeForm.notional)
            : null,
        NotionalMax:
          this.tradeForm.notionalMax != null
            ? parseFloat(this.tradeForm.notionalMax)
            : null,
        OpenNotional:
          this.tradeForm.openNotional != null
            ? parseFloat(this.tradeForm.openNotional)
            : null,
        OpenNotionalMax:
          this.tradeForm.openNotionalMax != null
            ? parseFloat(this.tradeForm.openNotionalMax)
            : null,
        SpotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        SpotRefMax:
          this.tradeForm.spotRefMax != null
            ? parseFloat(this.tradeForm.spotRefMax)
            : null,
        // counterpartyPctPremiumCurrency
        PremCcy:
          this.tradeForm.counterpartyPctPremiumCurrency != null
            ? this.tradeForm.counterpartyPctPremiumCurrency
            : null,
        CptyPctPrem:
          this.tradeForm.counterpartyPctPremium != null
            ? parseFloat(this.tradeForm.counterpartyPctPremium)
            : null,
        CptyPctPremMax:
          this.tradeForm.counterpartyPctPremiumMax != null
            ? parseFloat(this.tradeForm.counterpartyPctPremiumMax)
            : null,
        ClientPctPrem:
          this.tradeForm.clientPctPremium != null
            ? parseFloat(this.tradeForm.clientPctPremium)
            : null,
        ClientPctPremMax:
          this.tradeForm.clientPctPremiumMax != null
            ? parseFloat(this.tradeForm.clientPctPremiumMax)
            : null,
        CptyPrem:
          this.tradeForm.counterpartyPremium != null
            ? parseFloat(this.tradeForm.counterpartyPremium)
            : null,
        CptyPremMax:
          this.tradeForm.counterpartyPremiumMax != null
            ? parseFloat(this.tradeForm.counterpartyPremiumMax)
            : null,
        ClientPrem:
          this.tradeForm.clientPremium != null
            ? parseFloat(this.tradeForm.clientPremium)
            : null,
        ClientPremMax:
          this.tradeForm.clientPremiumMax != null
            ? parseFloat(this.tradeForm.clientPremiumMax)
            : null,
        TcComm:
          this.tradeForm.tcCommision != null
            ? parseFloat(this.tradeForm.tcCommision)
            : null,
        TcCommMax:
          this.tradeForm.tcCommisionMax != null
            ? parseFloat(this.tradeForm.tcCommisionMax)
            : null,
        TradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        TradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        SettlDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        SettlDateEnd:
          this.tradeForm.settlementDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
            : null,

        OrderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        OrderTimeEnd:
          this.tradeForm.orderTimeEnd != null
            ? this.timeFormat(this.tradeForm.orderTimeEnd)
            : null,
        ExecutionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        ExecutionTimeEnd:
          this.tradeForm.executionTimeEnd != null
            ? this.timeFormat(this.tradeForm.executionTimeEnd)
            : null,

        CptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,

        TcUser: this.tradeForm.tcUser != null ? this.tradeForm.tcUser : null,

        OrderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,

        OrderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,

        ActingCap:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,

        TraderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        CollateralPct:
          this.tradeForm.collateralPct != null
            ? parseFloat(this.tradeForm.collateralPct)
            : null,
        CollateralPctMax:
          this.tradeForm.collateralPctMax != null
            ? parseFloat(this.tradeForm.collateralPctMax)
            : null,

        Collateral:
          this.tradeForm.collateral != null
            ? parseFloat(this.tradeForm.collateral)
            : null,
        CollateralMax:
          this.tradeForm.collateralMax != null
            ? parseFloat(this.tradeForm.collateralMax)
            : null,

        CollateralCcy:
          this.tradeForm.collateralCcy != null
            ? this.tradeForm.collateralCcy
            : null,

        ClientOpen:
          this.tradeForm.clientOpen != null
            ? parseFloat(this.tradeForm.clientOpen)
            : null,
        ClientOpenMax:
          this.tradeForm.clientOpenMax != null
            ? parseFloat(this.tradeForm.clientOpenMax)
            : null,

        CpRef: this.tradeForm.cpRef != null ? this.tradeForm.cpRef : null,

        CutOffTime:
          this.tradeForm.cutOffTime != null ? this.tradeForm.cutOffTime : null,

        DeliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,

        ConfirmationDate:
          this.tradeForm.confirmationDate != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDate)
            : null,

        ConfirmationTime:
          this.tradeForm.confirmationTime != null
            ? this.timeFormat(this.tradeForm.confirmationTime)
            : null,

        ConfirmationTrader:
          this.tradeForm.confirmationTrader != null
            ? this.tradeForm.confirmationTrader
            : null,

        CptyUti: this.tradeForm.cptyUti != null ? this.tradeForm.cptyUti : null,

        UniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,

        ClearingMemberId:
          this.tradeForm.clearingMemberId != null
            ? this.tradeForm.clearingMemberId
            : null,

        ConfirmationDateClient:
          this.tradeForm.confirmationDateClient != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDateClient)
            : null,

        ConfirmationTimeClient:
          this.tradeForm.confirmationTimeClient != null
            ? this.timeFormat(this.tradeForm.confirmationTimeClient)
            : null,
        ID: this.tradeForm.tradeRef,
        TcUti: this.tradeForm.tcUti,
      };

      return setSearch;
    },
  },
  watch: {
    "tradeForm.client": {
      handler: function(val, before) {
        if(val == null){
          this.tradeForm.clientAccount = null
        }
      },
      deep: true,
    },
    "tradeForm.counterparty": {
      handler: function(val, before) {
        if(val == null){
          this.tradeForm.cptyAccount = null
        }
      },
      deep: true,
    },
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();

          // this.tradeForm.ccy1 = "USD";
          // this.tradeForm.ccy2 = "TRY";
          // this.optCounterpartyPctPreCurrencies = [];
          // this.optCounterpartyPctPreCurrencies = [
          //   this.tradeForm.ccy1,
          //   this.tradeForm.ccy2,
          // ];
          // this.optNotionalCurrencies = [];
          // this.optNotionalCurrencies = [
          //   this.tradeForm.ccy1,
          //   this.tradeForm.ccy2,
          // ];
        }
      },
    },
    expiryDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.expiryDate = val.split("to")[0];
            this.tradeForm.expiryDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.expiryDate = val;
          }
        }
      },
    },
    valueDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.valueDate = val.split("to")[0];
            this.tradeForm.valueDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.valueDate = val;
          }
        }
      },
    },
    tradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.tradeDate = val.split("to")[0];
            this.tradeForm.tradeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.tradeDate = val;
          }
        }
      },
    },
    settleDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.settlementDate = val.split("to")[0];
            this.tradeForm.settlementDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.settlementDate = val;
          }
        }
      },
    },
    windowStartRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.windowStart = val.split("to")[0];
            this.tradeForm.windowStart_end = val.split("to")[1];
          } else {
            this.tradeForm.windowStart = val;
          }
        }
      },
    },

    windowEndRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.windowEnd = val.split("to")[0];
            this.tradeForm.windowEnd_end = val.split("to")[1];
          } else {
            this.tradeForm.windowEnd = val;
          }
        }
      },
    },

    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
        }
      },
    },
  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.shortcut-buttons-flatpickr-button {
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
}

.form-group.prop_checkbox {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// multiple vue select input
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.6rem !important;
}
</style>


<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// multiple vue select input
.compact_form_vue-multi-select::v-deep .vs__selected {
  position: relative !important;
}

//
</style>
