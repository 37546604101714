<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="copyTradeModal"
      :title="$t('fx_options.titles.copy_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_options.modals.copy_trade_modal_ok')"
      :cancel-title="$t('fx_options.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->

                  <b-card
                    :title="$t('fx_options.titles.client_counterparty_details')"
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.client') + '*'"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.client')"
                              style="width: 100%;"
                              :rules="'required'"
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_options.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selecting="selectedClient"
                                ref="client"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchClient(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedClients.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item @click="seeFilteredClientModal">
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.client_account') + '*'
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.client_account')"
                            :rules="'required'"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.client_account')
                              "
                              :disabled="true"
                              ref="clientAcc"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counterparty -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty') + '*'
                          "
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              style="width: 100%;"
                              :name="$t('fx_options.trade_form.counterparty')"
                              :rules="'required'"
                            >
                              <v-select
                                ref="cpty"
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selecting="selectedCounterparty"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchCounterparty(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedCounterparties.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredCounterpartyModal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.cpty_account') + '*'
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cpty_account')"
                            :rules="'required'"
                          >
                            <b-form-input
                              ref="cptyAcc"
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* trade Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_date')"
                            rules="required"
                          >
                            <b-input-group :append="tradeForm.tradeDateName">
                              <flat-pickr
                                ref="TradeDate"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkTraSettlCptyDate('Trade Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="6"
                        style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.orderTPlus"
                              name="T+"
                              placeholder="T+"
                              @change="orderArrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Settlement Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_date')"
                            rules="required"
                          >
                            <b-input-group
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                ref="settlDate"
                                :placeholder="
                                  $t('fx_options.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Settlement Date')
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- order time-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_time')"
                            rules="required"
                          >
                            <cleave
                              id="orderTime"
                              ref="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeOrderTime(tradeForm.orderTime)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.execution_time')"
                            rules="required"
                          >
                            <cleave
                              id="executionTime"
                              ref="executionTime"
                              v-model="tradeForm.executionTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeExecutionTime(tradeForm.executionTime)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party trader -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              ref="cptyTrader"
                              v-model="tradeForm.counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- TC user -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.sfc_user')"
                            rules="required"
                          >
                            <v-select
                              ref="tcUser"
                              v-model="tradeForm.tcUser"
                              :options="optTcUsers"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_taken_via')"
                          >
                            <v-select
                              ref="orderTakenVia"
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken given through -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              ref="orderGivenThrough"
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.acting_capacity')"
                          >
                            <v-select
                              ref="actingCap"
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cut off time -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.cut_off_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cut_off_time')"
                          >
                            <v-select
                              v-model="tradeForm.cutOffTime"
                              :options="optCutOffTimes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.cut_off_time')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- deliverable ccy-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.deliverable_ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.deliverable_ccy')"
                            :rules="
                              tradeForm.settlementType != 'PHYSICAL'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              ref="deliverableCcy"
                              v-model="tradeForm.deliverableCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.deliverable_ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="tradeForm.settlementType == 'PHYSICAL'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="TraderNote"
                              id="form-textarea"
                              :placeholder="$t('fx_options.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.settlement_details')">
                    <b-row style="margin-bottom:2.2rem">
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationDate')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                selectedConfirmationDate(
                                  tradeForm.confirmationDate
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationTime')"
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeConfirmationTime(
                                  tradeForm.confirmationTime
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                              @option:selected="
                                selectedConfirmationTrader(
                                  tradeForm.confirmationTrader
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationDateClient')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                selectedConfirmationDateClient(
                                  tradeForm.confirmationDateClient
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTimeClient')
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeConfirmationTimeClient(
                                  tradeForm.confirmationTimeClient
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CpRef')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CptyUti')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.TcUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.UniqueLinkId')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Clearing Member Id -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClearingMemberId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="clearingMemberId"
                          >
                            <b-form-input
                              id="clearingMemberId"
                              ref="clearingMemberId"
                              v-model="tradeForm.clearingMemberId"
                              :name="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- trade ref -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('fx_options.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_ref')
                                "
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /*******************************Option Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.option_details')">
                    <b-row>
                      <!-- buy/sell -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.buy_sell')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="false"
                              ref="buySell"
                              :placeholder="
                                $t('fx_options.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedBuySell(tradeForm.buySell)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- settlement type-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_type')"
                            rules="required"
                          >
                            <v-select
                              ref="settlementType"
                              v-model="tradeForm.settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedSettlementType(tradeForm.settlementType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              ref="costCenter"
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Currency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency1')"
                            rules="required"
                          >
                            <v-select
                              ref="ccy1"
                              v-model="tradeForm.ccy1"
                              :options="optCurrency1Types"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency1(tradeForm.ccy1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- currency 2 -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency2')"
                            rules="required"
                          >
                            <v-select
                              ref="ccy2"
                              v-model="tradeForm.ccy2"
                              :options="optCurrency2Types"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency2')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCurrency2(tradeForm.ccy2)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Exercise -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_exercise')"
                            rules="required"
                          >
                            <v-select
                              ref="optionExercise"
                              v-model="tradeForm.optionExercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_option_exercise'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedOptionExercise(tradeForm.optionExercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Type -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_type')"
                            rules="required"
                          >
                            <v-select
                              ref="optionType"
                              v-model="tradeForm.optionType"
                              :options="optOptionTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_option_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedOptionType(tradeForm.optionType)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type1 Exercise -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_exercise')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <v-select
                              ref="type1Exercise"
                              v-model="tradeForm.type1Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type1_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedType1Exercise(tradeForm.type1Exercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 1 -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <v-select
                              ref="type1"
                              v-model="tradeForm.type1"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type1')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="selectedType1(tradeForm.type1)"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 1 Level -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_level')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <cleave
                              id="type1Level"
                              ref="type1Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type1Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type1_level')
                              "
                              @blur.native="
                                changeType1Level(tradeForm.type1Level)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 Exercise -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_exercise')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <v-select
                              ref="type2Exercise"
                              v-model="tradeForm.type2Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type2_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedType2Exercise(tradeForm.type2Exercise)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <v-select
                              ref="type2"
                              v-model="tradeForm.type2"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type2')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="selectedType2(tradeForm.type2)"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 2 Level -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_level')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <cleave
                              id="type2Level"
                              ref="type2Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type2Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type2_level')
                              "
                              @blur.native="
                                changeType2Level(tradeForm.type2Level)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window Start-->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_start')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_start')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_start')
                              "
                              ref="windowStart"
                              v-model="tradeForm.windowStart"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeWindowsDate('Window Start')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window End -->
                      <b-col
                        v-if="tradeForm.optionType == 'Exotic'"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_end')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_end')"
                            :rules="
                              tradeForm.optionType == 'Exotic' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_end')
                              "
                              ref="windowEnd"
                              v-model="tradeForm.windowEnd"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeWindowsDate('Window End')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CallPut 1 -->
                      <b-col
                        v-if="visibleStrikeAndCallPuts"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut1')"
                            :rules="visibleStrikeAndCallPuts ? 'required' : ''"
                          >
                            <v-select
                              ref="callPut1"
                              v-model="tradeForm.callPut1"
                              :options="optCallPut"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCallPut(tradeForm.callPut1)
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- callPut 2 -->
                      <b-col
                        v-if="visibleStrikeAndCallPuts"
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        class="animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut2')"
                            :rules="visibleStrikeAndCallPuts ? 'required' : ''"
                          >
                            <b-form-input
                              id="callPut2"
                              ref="callPut2"
                              v-model="tradeForm.callPut2"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut2')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Strike -->
                      <b-col
                        v-if="visibleStrikeAndCallPuts"
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        class="padleft-0 animate__animated animate__fadeIn"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Strike')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Strike')"
                            rules="required"
                          >
                            <cleave
                              id="strike"
                              ref="strike"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.strike"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="$t('fx_options.trade_form.Strike')"
                              @blur.native="changeStrike(tradeForm.strike)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Expiry date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Expiry')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Expiry')"
                          >
                            <b-input-group :append="tradeForm.expiryDateName">
                              <flat-pickr
                                ref="expiry"
                                :placeholder="
                                  $t('fx_options.trade_form.Expiry')
                                "
                                v-model="tradeForm.expiryDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkExpiryValueDate('Expiry Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- option arrange T+ -->
                      <b-col cols="6" xl="1" lg="1" md="2" sm="6">
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.optionTplus"
                              name="T+"
                              placeholder="T+"
                              @change="optionArrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- value Date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ValueDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ValueDate')"
                          >
                            <b-input-group :append="tradeForm.valueDateName">
                              <flat-pickr
                                ref="valueDate"
                                :placeholder="
                                  $t('fx_options.trade_form.ValueDate')
                                "
                                v-model="tradeForm.valueDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkExpiryValueDate('Value Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- NotionalCurrency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.NotionalCurrency')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.NotionalCurrency')"
                            rules="required"
                          >
                            <v-select
                              ref="notionalCcy"
                              v-model="tradeForm.notionalCurrency"
                              :options="optNotionalCurrencies"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.NotionalCurrency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedNotionalCurrency(
                                  tradeForm.notionalCurrency
                                )
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notional -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Notional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Notional')"
                            rules="required"
                          >
                            <cleave
                              id="notional"
                              ref="notional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.notional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.Notional')
                              "
                              @blur.native="changeNotional(tradeForm.notional)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- spotRef -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            rules="min_value:0"
                          >
                            <cleave
                              id="spot_ref"
                              ref="spotRef"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.spot_ref')
                              "
                              @blur.native="changeSpotRef(tradeForm.spotRef)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty prem Counterparty Pct Premium Currency -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                              )
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyPctPremiumCurrency"
                              :options="optCounterpartyPctPreCurrencies"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              ref="premCcy"
                              @option:selected="
                                selectedCounterpartyPctPremiumCurrency(
                                  tradeForm.counterpartyPctPremiumCurrency
                                )
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPctPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPctPremium')
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required|min_value:0|max_value:100'
                                : ''
                            "
                          >
                            <b-input-group
                              prepend="%"
                              :append="tradeForm.counterpartyPctPremiumCurrency"
                            >
                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.counterpartyPctPremium
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                ref="cptyPctPrem"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.CounterpartyPctPremium'
                                  )
                                "
                                @change="
                                  changeCptyPremium(
                                    tradeForm.counterpartyPctPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Price'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPctPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPctPremium')"
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Percent'
                                ? 'required|min_value:0|max_value:100'
                                : ''
                            "
                          >
                            <b-input-group
                              :append="tradeForm.clientPctPremiumCurrency"
                              prepend="%"
                            >
                              <b-form-input
                                id="clientPctPremium"
                                v-model.number="tradeForm.clientPctPremium"
                                name="clientPctPremium"
                                ref="clientPctPrem"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.ClientPctPremium')
                                "
                                @change="
                                  changeClientPremium(
                                    tradeForm.clientPctPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Price'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- use client cpty percent or client percent value radio button -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="4"
                        sm="6"
                        class="mt-1 padleft-0"
                      >
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.usePercentOrPrice"
                            :options="optPercentOrPrice"
                            name="radios-stacked"
                            stacked
                            plain
                            class="fxDetailRadio"
                            @change="
                              changedCalcPercentOrPrice(
                                tradeForm.usePercentOrPrice
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <!-- CounterpartyPremium -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPremium')
                            "
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group
                              :append="tradeForm.counterpartyPremiumCurrency"
                              class="compact_form_input-group"
                              ref="groupAppend"
                            >
                              <cleave
                                id="counterpartyPremium"
                                ref="cptyPrem"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyPremium"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.CounterpartyPremium'
                                  )
                                "
                                @blur.native="
                                  changeCounterpartyPremium(
                                    tradeForm.counterpartyPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Percent'
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPremium -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPremium')"
                            :rules="
                              tradeForm.usePercentOrPrice ==
                              'Calculate Client & Cpty from Price'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group
                              :append="tradeForm.clientPremiumCurrency"
                              class="compact_form_input-group"
                              ref="groupAppend"
                            >
                              <cleave
                                id="clientPremium"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientPremium"
                                class="form-control"
                                ref="clientPrem"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.ClientPremium')
                                "
                                @blur.native="
                                  changeClientPricePremium(
                                    tradeForm.clientPremium
                                  )
                                "
                                :disabled="
                                  tradeForm.usePercentOrPrice ==
                                    'Calculate Client & Cpty from Percent'
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Tc Commision -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcCommision')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcCommision')"
                          >
                            <b-input-group
                              :append="tradeForm.tcCommisionCurrency"
                              class="compact_form_input-group"
                              ref="groupAppend"
                            >
                              <cleave
                                id="tcCommision"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.tcCommision"
                                ref="tcComm"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.TcCommision')
                                "
                                @blur.native="
                                  changeTcCommision(tradeForm.tcCommision)
                                "
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Collateral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Collateral')"
                            :rules="
                              tradeForm.buySell == 'Sell' ? 'required' : ''
                            "
                          >
                            <b-input-group
                              :append="tradeForm.collateralCcy"
                              class="compact_form_input-group"
                              ref="groupAppend"
                            >
                              <cleave
                                ref="collateral"
                                id="collateral"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.collateral"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.Collateral')
                                "
                                @blur.native="
                                  changeCollateral(tradeForm.collateral)
                                "
                                :disabled="tradeForm.buySell == 'Buy'"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- CollateralPct -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CollateralPct')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CollateralPct')"
                            :rules="
                              tradeForm.buySell == 'Sell'
                                ? 'required|min_value:0|max_value:100'
                                : ''
                            "
                          >
                            <b-input-group
                              ref="groupAppend"
                              prepend="%"
                              :append="tradeForm.collateralCcy"
                              class="compact_form_input-group"
                            >
                              <b-form-input
                                ref="collateralPct"
                                id="collateralPct"
                                v-model.number="tradeForm.collateralPct"
                                name="collateralPct"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.CollateralPct')
                                "
                                @change="
                                  changeCollateralPct(tradeForm.collateralPct)
                                "
                                :disabled="tradeForm.buySell == 'Buy'"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty open  -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.OpenNotional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.OpenNotional')"
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.openNotionalCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                ref="openNotional"
                                id="openNotional"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.openNotional"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.OpenNotional')
                                "
                                @blur.native="
                                  changeOpenNotional(tradeForm.openNotional)
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Open -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientOpen')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientOpen')"
                          >
                            <b-input-group
                              ref="groupAppend"
                              :append="tradeForm.clientOpenCcy"
                              class="compact_form_input-group"
                            >
                              <cleave
                                id="clientOpen"
                                ref="clientOpen"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientOpen"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_options.trade_form.ClientOpen')
                                "
                                @blur.native="
                                  changeClientOpen(tradeForm.clientOpen)
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("fx_options.overlay_messages.copy_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-between align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("fx_options.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("fx_options.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("fx_options.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right"
              @click="tradeOkOperation"
            >
              {{ $t("fx_options.modals.copy_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXOptionsStoreModule from "./FXOptionsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
    // Register module
    if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXOPTION_APP_STORE_MODULE_NAME,
        fXOptionsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},
  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      loadingDefaultEntry: false,
      copyTradeModal: false,
      tradeModalLoading: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      showDefaultEntry: false,
      defaultEntryName: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optbuySells: ["Buy", "Sell"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOptionExercise: ["American", "European"],
      optCallPut: ["Call", "Put"],
      optOptionTypes: ["Vanilla", "Exotic"],
      optTypes: ["KI", "KO", "OT", "NT"],
      optPercentOrPrice: [
        {
          text: "Calculate Client & Cpty from Percent",
          value: "Calculate Client & Cpty from Percent",
        },
        {
          text: "Calculate Client & Cpty from Price",
          value: "Calculate Client & Cpty from Price",
        },
      ],
      optPriceTypes: [],
      optCurrency1Types: [],
      optCurrency2Types: [],
      optNotionalCurrencies: [],
      optCounterpartyPctPreCurrencies: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optCounterPartyTraders: [],
      optTcUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,
      optDefaultEntries: [],
      visibleStrikeAndCallPuts: true,
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        ccy1: null,
        ccy2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiryDate: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCurrency: null,
        notional: null,
        spotRef: null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        clientPremiumCurrency: null,
        clientPremium: null,
        tcCommisionCurrency: null,
        tcCommision: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlementDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },

      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxOptions"]),
    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fx_options/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fx_options/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("fx_options.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fx_options/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
            this.optCurrency1Types = res.data.currencies;
            this.optCurrency2Types = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("FxOptions", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("fx_options/copySelectedFxOptionTrade", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.copyTradeModal = false;
                    this.$emit("copiedFxoption", true);
                    this.copiedTradeMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },

    // option details ********************************************************* /////////////////////////////////////////---------------------------
    selectedBuySell(type) {
      if (type == "Buy") {
        this.tradeForm.collateralPct = null;
        this.tradeForm.collateral = null;
      } else {
        this.tradeForm.collateralPct = 0;
        this.tradeForm.collateral = 0;
        this.tradeForm.collateral = Number.parseFloat(
          this.tradeForm.collateral
        ).toFixed(2);
      }

      this.arrangeTcCommision();
      this.calculateOpenNotional();
    },

    selectedSettlementType(type) {
      this.arrangePremiumCurrencies(false);

      if (type == "PHYSICAL") {
        this.tradeForm.deliverableCcy = null;
      }
    },
    selectedCurrency1(ccy) {
      if (this.optPriceTypes.length > 0) {
        // remove same currency
        let clonedCurrencies = JSON.parse(JSON.stringify(this.optPriceTypes));

        var index = clonedCurrencies.indexOf(this.tradeForm.ccy1);
        if (index !== -1) {
          clonedCurrencies.splice(index, 1);
        }

        this.optCurrency2Types = clonedCurrencies;
      }
      this.arrangePremiumCurrencies(true);
      this.calculateOpenNotional();
      this.copyPremiumCCY1Currencies();
    },
    selectedCurrency2(ccy) {
      if (this.optPriceTypes.length > 0) {
        // remove same currency
        let clonedCurrencies = JSON.parse(JSON.stringify(this.optPriceTypes));

        var index = clonedCurrencies.indexOf(this.tradeForm.ccy2);
        if (index !== -1) {
          clonedCurrencies.splice(index, 1);
        }

        this.optCurrency1Types = clonedCurrencies;
      }
      this.arrangePremiumCurrencies(true);
      this.calculateOpenNotional();
    },
    selectedOptionExercise(exercise) {},
    selectedOptionType(type) {
      if (type == "Exotic") {
        this.tradeForm.type1Exercise = "European";
        this.tradeForm.type1 = "KI";
        this.tradeForm.type1Level = null;
        this.tradeForm.type2Exercise = null;
        this.tradeForm.type2 = null;
        this.tradeForm.type2Level = null;
        this.tradeForm.windowStart = null;
        this.tradeForm.windowEnd = null;
      } else {
        this.tradeForm.type1Exercise = null;
        this.tradeForm.type1 = null;
        this.tradeForm.type1Level = null;
        this.tradeForm.type2Exercise = null;
        this.tradeForm.type2 = null;
        this.tradeForm.type2Level = null;
        this.tradeForm.windowStart = null;
        this.tradeForm.windowEnd = null;
      }

      this.calculateOpenNotional();
    },
    selectedType1Exercise(type) {},
    selectedType1(type) {
      this.arrangeType1();
      this.calculateOpenNotional();
    },
    changeType1Level() {
      this.calculateOpenNotional();
    },
    selectedType2Exercise(type) {},
    selectedType2(type) {
      this.arrangeType2();
      this.calculateOpenNotional();
    },
    changeType2Level() {
      this.calculateOpenNotional();
    },
    changeWindowsDate(type) {
      if (type == "Window Start") {
        if (
          this.tradeForm.windowStart != null &&
          this.tradeForm.windowEnd != null
        ) {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.windowStart)) >
            Date.parse(this.YMDdateFormat(this.tradeForm.windowEnd))
          ) {
            this.checkedDateMessage(
              this.$t("fx_options.messages.window_start_after_window_end_date")
            );
            this.tradeForm.windowStart = null;
          }
        }
      } else if (type == "Window End") {
        if (
          this.tradeForm.windowStart != null &&
          this.tradeForm.windowEnd != null
        ) {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.windowEnd)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.windowStart))
          ) {
            this.checkedDateMessage(
              this.$t("fx_options.messages.window_end_after_window_start_date")
            );
            this.tradeForm.windowEnd = null;
          }
        }
      }
      this.calculateOpenNotional();
    },
    selectedCallPut(type) {
      if (type == "Call") {
        this.tradeForm.callPut2 = "Put";
      } else {
        this.tradeForm.callPut2 = "Call";
      }
      this.calculateOpenNotional();
    },
    changeStrike() {
      this.convertNumberInputFormat();
      this.arrangeClientPremiums();
      this.arrangeCounterpartyPremiums();
      this.calculateOpenNotional();
    },
    checkExpiryValueDate(type) {
      if (type == "Expiry Date") {
        let expiryDate = new Date(
          this.MDYdateFormat(this.tradeForm.expiryDate)
        );

        //set trade date name
        this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

        //arrange as T+
        if (this.tradeForm.optionTplus > 0) {
          this.optionArrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDateDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.valueDate))
        ) {
          this.checkedDateMessage(
            this.$t("fx_options.messages.expiry_date_after_value_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Value Date") {
        let valueDate = new Date(this.MDYdateFormat(this.tradeForm.valueDate));

        //set value date name
        this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];

        //arrange as T+
        // if (this.tradeForm.optionTplus > 0) {
        //   this.optionArrangeTPlus();
        // }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.expiryDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.valueDate))
        ) {
          this.checkedDateMessage(
            this.$t("fx_options.messages.expiry_date_after_value_date")
          );
          this.tradeForm.valueDate = null;
        }
      }
      this.calculateOpenNotional();
    },
    optionArrangeTPlus() {
      if (
        this.tradeForm.expiryDate != null &&
        this.tradeForm.expiryDateName != null
      ) {
        if (this.tradeForm.optionTplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let expiryDate = new Date(
                this.MDYdateFormat(this.tradeForm.expiryDate)
              );

              let period = this.tradeForm.optionTplus;
              expiryDate.setDate(expiryDate.getDate() + period);

              this.checkHolidaysDate(holidays, expiryDate);

              //found value date name
              let valueDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend value date
              if (valueDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (valueDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set value date
              this.tradeForm.valueDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.valueDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let expiryDate = new Date(
                this.MDYdateFormat(this.tradeForm.expiryDate)
              );

              let period = this.tradeForm.optionTplus;

              expiryDate.setDate(expiryDate.getDate() + period);

              //found value date name
              let valueDateName = this.weekday[expiryDate.getDay()];

              //check weekend value date
              if (valueDateName == "Saturday") {
                expiryDate.setDate(expiryDate.getDate() + 2);
              } else if (valueDateName == "Sunday") {
                expiryDate.setDate(expiryDate.getDate() + 1);
              }

              // set value date
              this.tradeForm.valueDate = this.dateFormat(expiryDate);
              this.tradeForm.valueDateName = this.weekday[expiryDate.getDay()];
            }
          });
        }
      }
    },
    selectedNotionalCurrency(ccy) {
      this.tradeForm.collateralCcy = this.tradeForm.notionalCurrency;
      this.tradeForm.openNotionalCcy = this.tradeForm.notionalCurrency;
      this.tradeForm.clientOpenCcy = this.tradeForm.notionalCurrency;

      this.arrangeCounterpartyPremiums();
      this.arrangeClientPremiums();
      this.calculateOpenNotional();
    },
    changeNotional() {
      this.convertNumberInputFormat();
      if (this.tradeForm.notional != null) {
        if (this.tradeForm.collateralPct != null) {
          if (
            this.tradeForm.collateralPct > 0 &&
            this.tradeForm.collateralPct < 100 &&
            this.tradeForm.notional > 0
          ) {
            this.tradeForm.collateral =
              (this.tradeForm.collateralPct * this.tradeForm.notional) / 100;
          }
        }
      }

      this.arrangeClientPremiums();
      this.arrangeCounterpartyPremiums();
      this.calculateOpenNotional();
    },
    changeSpotRef() {
      this.arrangeInputFormats();
    },
    selectedCounterpartyPctPremiumCurrency(ccy) {
      if (
        ccy != this.tradeForm.ccy1 &&
        this.tradeForm.counterpartyPctPremiumCurrency != this.tradeForm.ccy2
      ) {
        this.tradeForm.counterpartyPctPremiumCurrency = this.tradeForm.ccy1;
      }

      this.arrangeCounterpartyPremiums();
      this.arrangeClientPremiums();
      this.copyCounterpartyPctCurrencies();
      this.arrangeInputFormats();
    },

    changeCptyPremium(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.counterpartyPctPremium = null;
      } else {
        this.arrangeCounterpartyPremiums();
        this.arrangeInputFormats();
      }
    },
    selectedClientPctPremiumCurrency(ccy) {},
    changeClientPremium(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.clientPctPremium = null;
      } else {
        this.arrangeClientPremiums();
        this.arrangeInputFormats();
      }
    },

    changedCalcPercentOrPrice(type) {
      if (type == "Calculate Client & Cpty from Percent") {
        // this.tradeForm.counterpartyPremiumCurrency = null;
        this.tradeForm.counterpartyPremium = null;
        // this.tradeForm.clientPremiumCurrency = null;
        this.tradeForm.clientPremium = null;
      }

      if (type == "Calculate Client & Cpty from Price") {
        // this.tradeForm.counterpartyPctPremiumCurrency = null;
        this.tradeForm.counterpartyPctPremium = null;
        // this.tradeForm.clientPctPremiumCurrency = null;
        this.tradeForm.clientPctPremium = null;
      }
    },

    selectedCounterpartyPremiumCurrency(ccy) {},
    changeCounterpartyPremium(price) {
      this.arrangeCounterpartyPremiums();
      //this.arrangeTcCommision()
    },
    selectedClientPremiumCurrency(ccy) {},
    changeClientPricePremium(price) {
      this.arrangeClientPremiums();
      //this.arrangeTcCommision()
    },
    selectedTcCommisionCurrency(ccy) {},
    changeTcCommision(price) {
      this.arrangeInputFormats();
    },
    changeCollateralPct(value) {
      if (value < 0 || value > 100) {
        this.tradeForm.collateralPct = null;
      } else {
        this.convertNumberInputFormat();
        if (this.tradeForm.collateralPct != null) {
          if (this.tradeForm.openNotional != null) {
            if (this.tradeForm.openNotional > 0) {
              this.tradeForm.collateral =
                (this.tradeForm.collateralPct * this.tradeForm.openNotional) /
                100;
            }
          }
          this.arrangeInputFormats();
        }
      }
    },

    selectedCollateralCcy(ccy) {
      if (this.tradeForm.buySell == "Sell") {
        this.tradeForm.collateralCcy = this.tradeForm.ccy1;
      }
    },

    changeCollateral(price) {
      this.convertNumberInputFormat();
      if (this.tradeForm.collateral != null) {
        if (this.tradeForm.openNotional != null) {
          if (
            this.tradeForm.collateralPct > 0 &&
            this.tradeForm.collateralPct < 100
          ) {
            this.tradeForm.collateralPct =
              (this.tradeForm.collateral * 100) / this.tradeForm.openNotional;
          }
        }
        this.arrangeInputFormats();
      }
    },

    selectedOpenNotionalCcy(ccy) {},

    changeOpenNotional(price) {
      this.arrangeInputFormats();
    },
    selectedClientOpenCcy(ccy) {},

    changeClientOpen(price) {
      this.arrangeInputFormats();
    },
    // END option details ********************************************************* /////////////////////////////////////////---------------------------

    // order details ********************************************************* /////////////////////////////////////////---------------------------

    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.orderTPlus > 0) {
          this.orderArrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedDateMessage(
            this.$t("fx_options.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedDateMessage(
            this.$t("fx_options.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
      }
    },

    orderArrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.orderTPlus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.orderTPlus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.orderTPlus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },

    changeOrderTime(time) {
      if (
        this.tradeForm.orderTime != null &&
        this.tradeForm.orderTime.length < 8
      ) {
        for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.orderTime += ":";
          } else if (i == 5) {
            this.tradeForm.orderTime += ":";
          } else {
            this.tradeForm.orderTime += "0";
          }
        }
      }
    },
    changeExecutionTime(time) {
      if (
        this.tradeForm.executionTime != null &&
        this.tradeForm.executionTime.length < 8
      ) {
        for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.executionTime += ":";
          } else if (i == 5) {
            this.tradeForm.executionTime += ":";
          } else {
            this.tradeForm.executionTime += "0";
          }
        }
      }
    },
    // END order details ********************************************************* /////////////////////////////////////////---------------------------

    // settlement details ********************************************************* /////////////////////////////////////////---------------------------
    selectedConfirmationDate(date) {},
    changeConfirmationTime(time) {
      if (
        this.tradeForm.confirmationTime != null &&
        this.tradeForm.confirmationTime.length < 8
      ) {
        for (let i = this.tradeForm.confirmationTime.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.confirmationTime += ":";
          } else if (i == 5) {
            this.tradeForm.confirmationTime += ":";
          } else {
            this.tradeForm.confirmationTime += "0";
          }
        }
      }
    },
    selectedConfirmationTrader(trader) {},
    selectedConfirmationDateClient(date) {},
    changeConfirmationTimeClient(time) {
      if (
        this.tradeForm.confirmationTimeClient != null &&
        this.tradeForm.confirmationTimeClient.length < 8
      ) {
        for (let i = this.tradeForm.confirmationTimeClient.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.confirmationTimeClient += ":";
          } else if (i == 5) {
            this.tradeForm.confirmationTimeClient += ":";
          } else {
            this.tradeForm.confirmationTimeClient += "0";
          }
        }
      }
    },

    // END settlement details ********************************************************* /////////////////////////////////////////---------------------------

    // Client & counterparty details ********************************************************* /////////////////////////////////////////--------------------------------

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;
      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      this.calculateOpenNotional();
    },
    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );

      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
            this.calculateOpenNotional();
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },

    // END Client & counterparty details ********************************************************* ////////////////////////////////////////////

    // Calculate & Arrange functions here ********************************************************* ////////////////////////////////////////////

    arrangeTcCommision() {
      if (
        this.tradeForm.buySell != null &&
        this.tradeForm.clientPremium != null &&
        this.tradeForm.counterpartyPremium != null
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.tcCommision =
            this.tradeForm.clientPremium - this.tradeForm.counterpartyPremium;
        } else {
          this.tradeForm.tcCommision =
            this.tradeForm.counterpartyPremium - this.tradeForm.clientPremium;
        }
        this.arrangeInputFormats();
        this.calculateOpenNotional();
      }
    },
    arrangePremiumCurrencies(clearBefore) {
      if (clearBefore) {
        this.optNotionalCurrencies = [];
        this.optNotionalCurrencies = [this.tradeForm.ccy1, this.tradeForm.ccy2];
        this.tradeForm.notionalCurrency = this.tradeForm.ccy1;
      }

      if (clearBefore) {
        this.optCounterpartyPctPreCurrencies = [];
        this.optCounterpartyPctPreCurrencies = [
          this.tradeForm.ccy1,
          this.tradeForm.ccy2,
        ];
        this.tradeForm.counterpartyPctPremiumCurrency = this.tradeForm.ccy1;
      }

      if (this.tradeForm.ccy1 != null && this.tradeForm.ccy2 != null) {
        if (this.tradeForm.ccy1.localeCompare(this.tradeForm.ccy2) == -1) {
          this.tradeForm.deliverableCcy = this.tradeForm.ccy1;
        } else {
          this.tradeForm.deliverableCcy = this.tradeForm.ccy2;
        }
      }
    },
    copyPremiumCCY1Currencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.ccy1;

      this.tradeForm.openNotionalCcy = this.tradeForm.ccy1;
      this.tradeForm.clientOpenCcy = this.tradeForm.ccy1;
      this.tradeForm.collateralCcy = this.tradeForm.ccy1;
    },
    copyCounterpartyPctCurrencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
    },
    arrangeType1() {
      if (this.tradeForm.type1 == "OT" || this.tradeForm.type1 == "NT") {
        if (this.tradeForm.type2 == "KI" || this.tradeForm.type2 == "KO") {
          this.warningMessage(
            this.$t("fx_options.messages.type1_no_touch_message")
          );
          this.tradeForm.type1 = null;
        } else {
          this.visibleStrikeAndCallPuts = false;
          this.tradeForm.callPut1 = null;
          this.tradeForm.callPut2 = null;
          this.tradeForm.strike = null;
        }
      } else if (this.tradeForm.type1 == "KI" || this.tradeForm.type1 == "KO") {
        if (this.tradeForm.type2 == "OT" || this.tradeForm.type2 == "NO") {
          this.warningMessage(
            this.$t("fx_options.messages.type1_no_touch_message")
          );
          this.tradeForm.type1 = null;
        } else {
          this.visibleStrikeAndCallPuts = true;
          this.tradeForm.callPut1 = "Call";
          this.tradeForm.callPut2 = "Put";
        }
      } else {
        this.tradeForm.type1 = null;
      }
    },
    arrangeType2() {
      if (this.tradeForm.type2 == "OT" || this.tradeForm.type2 == "NT") {
        if (this.tradeForm.type1 == "KI" || this.tradeForm.type1 == "KO") {
          this.warningMessage(
            this.$t("fx_options.messages.type1_no_touch_message")
          );
          this.tradeForm.type2 = null;
        } else {
          this.visibleStrikeAndCallPuts = false;
          this.tradeForm.callPut1 = null;
          this.tradeForm.callPut2 = null;
          this.tradeForm.strike = null;
        }
      } else if (this.tradeForm.type2 == "KI" || this.tradeForm.type2 == "KO") {
        if (this.tradeForm.type1 == "OT" || this.tradeForm.type1 == "NO") {
          this.warningMessage(
            this.$t("fx_options.messages.type1_no_touch_message")
          );
          this.tradeForm.type2 = null;
        } else {
          this.visibleStrikeAndCallPuts = true;
          this.tradeForm.callPut1 = "Call";
          this.tradeForm.callPut2 = "Put";
        }
      } else {
        this.tradeForm.type1 = null;
      }
    },
    convertNumberInputFormat() {
      if (this.tradeForm.strike != null) {
        this.tradeForm.strike = parseFloat(this.tradeForm.strike);
      }

      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = parseFloat(this.tradeForm.spotRef);
      }

      if (this.tradeForm.notional != null) {
        this.tradeForm.notional = parseFloat(this.tradeForm.notional);
      }

      if (this.tradeForm.counterpartyPctPremium != null) {
        this.tradeForm.counterpartyPctPremium = parseFloat(
          this.tradeForm.counterpartyPctPremium
        );
      }

      if (this.tradeForm.clientPctPremium != null) {
        this.tradeForm.clientPctPremium = parseFloat(
          this.tradeForm.clientPctPremium
        );
      }
      if (this.tradeForm.tcCommision != null) {
        this.tradeForm.tcCommision = parseFloat(this.tradeForm.tcCommision);
      }

      if (this.tradeForm.counterpartyPremium != null) {
        this.tradeForm.counterpartyPremium = parseFloat(
          this.tradeForm.counterpartyPremium
        );
      }

      if (this.tradeForm.clientPremium != null) {
        this.tradeForm.clientPremium = parseFloat(this.tradeForm.clientPremium);
      }

      if (this.tradeForm.collateralPct != null) {
        this.tradeForm.collateralPct = parseFloat(this.tradeForm.collateralPct);
      }

      if (this.tradeForm.collateral != null) {
        this.tradeForm.collateral = parseFloat(this.tradeForm.collateral);
      }

      if (this.tradeForm.openNotional != null) {
        this.tradeForm.openNotional = parseFloat(this.tradeForm.openNotional);
      }

      if (this.tradeForm.clientOpen != null) {
        this.tradeForm.clientOpen = parseFloat(this.tradeForm.clientOpen);
      }

      if (this.tradeForm.type1Level != null) {
        this.tradeForm.type1Level = parseFloat(this.tradeForm.type1Level);
      }

      if (this.tradeForm.type2Level != null) {
        this.tradeForm.type2Level = parseFloat(this.tradeForm.type2Level);
      }
    },

    arrangeInputFormats() {
      if (this.tradeForm.strike != null) {
        // this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.strike = Number.parseFloat(
          this.tradeForm.strike
        ).toFixed(4);
      }
      if (this.tradeForm.spotRef != null) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(4);
      }

      if (this.tradeForm.notional != null) {
        this.tradeForm.notional = Number.parseFloat(
          this.tradeForm.notional
        ).toFixed(2);
      }

      if (this.tradeForm.counterpartyPctPremium != null) {
        this.tradeForm.counterpartyPctPremium = Number.parseFloat(
          this.tradeForm.counterpartyPctPremium
        ).toFixed(2);
      }

      if (this.tradeForm.clientPctPremium != null) {
        this.tradeForm.clientPctPremium = Number.parseFloat(
          this.tradeForm.clientPctPremium
        ).toFixed(2);
      }

      if (this.tradeForm.tcCommision != null) {
        this.tradeForm.tcCommision = Number.parseFloat(
          this.tradeForm.tcCommision
        ).toFixed(2);
      }

      if (this.tradeForm.counterpartyPremium != null) {
        this.tradeForm.counterpartyPremium = Number.parseFloat(
          this.tradeForm.counterpartyPremium
        ).toFixed(2);
      }

      if (this.tradeForm.clientPremium != null) {
        this.tradeForm.clientPremium = Number.parseFloat(
          this.tradeForm.clientPremium
        ).toFixed(2);
      }

      if (this.tradeForm.collateralPct != null) {
        this.tradeForm.collateralPct = Number.parseFloat(
          this.tradeForm.collateralPct
        ).toFixed(2);
      }

      if (this.tradeForm.collateral != null) {
        this.tradeForm.collateral = Number.parseFloat(
          this.tradeForm.collateral
        ).toFixed(2);
      }

      if (this.tradeForm.openNotional != null) {
        this.tradeForm.openNotional = Number.parseFloat(
          this.tradeForm.openNotional
        ).toFixed(2);
      }

      if (this.tradeForm.clientOpen != null) {
        this.tradeForm.clientOpen = Number.parseFloat(
          this.tradeForm.clientOpen
        ).toFixed(2);
      }

      if (this.tradeForm.type1Level != null) {
        this.tradeForm.type1Level = Number.parseFloat(
          this.tradeForm.type1Level
        ).toFixed(4);
      }

      if (this.tradeForm.type2Level != null) {
        this.tradeForm.type2Level = Number.parseFloat(
          this.tradeForm.type2Level
        ).toFixed(4);
      }
    },

    arrangeClientPremiums() {
      this.convertNumberInputFormat();
      if (
        this.tradeForm.usePercentOrPrice ==
        "Calculate Client & Cpty from Percent"
      ) {
        if (
          this.tradeForm.clientPctPremium != null &&
          this.tradeForm.notional != null
        ) {
          if (
            this.tradeForm.notionalCurrency ==
            this.tradeForm.clientPctPremiumCurrency
          ) {
            this.tradeForm.clientPremium =
              (this.tradeForm.notional * this.tradeForm.clientPctPremium) / 100;
          } else if (this.tradeForm.strike != null) {
            if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy1 &&
              this.tradeForm.clientPctPremiumCurrency == this.tradeForm.ccy2
            ) {
              this.tradeForm.clientPremium =
                (this.tradeForm.notional *
                  this.tradeForm.clientPctPremium *
                  this.tradeForm.strike) /
                100;
            } else if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy2 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy1
            ) {
              this.tradeForm.clientPremium =
                (this.tradeForm.notional * this.tradeForm.clientPctPremium) /
                this.tradeForm.strike /
                100;
            }
          }
        }
      } else if (
        this.tradeForm.usePercentOrPrice == "Calculate Client & Cpty from Price"
      ) {
        if (
          this.tradeForm.clientPremium != null &&
          this.tradeForm.notional != null
        ) {
          if (
            this.tradeForm.notionalCurrency ==
            this.tradeForm.clientPctPremiumCurrency
          ) {
            this.tradeForm.clientPctPremium =
              (this.tradeForm.clientPremium / this.tradeForm.notional) * 100;
          } else if (this.tradeForm.strike != null) {
            if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy1 &&
              this.tradeForm.clientPctPremiumCurrency == this.tradeForm.ccy2
            ) {
              this.tradeForm.clientPctPremium =
                (this.tradeForm.clientPremium /
                  this.tradeForm.notional /
                  this.tradeForm.strike) *
                100;
            } else if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy2 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy1
            ) {
              this.tradeForm.clientPctPremium =
                (this.tradeForm.clientPremium / this.tradeForm.notional) *
                this.tradeForm.strike *
                100;
            }
          }
        }
      }

      this.arrangeTcCommision();
    },
    arrangeCounterpartyPremiums() {
      this.convertNumberInputFormat();

      if (
        this.tradeForm.usePercentOrPrice ==
        "Calculate Client & Cpty from Percent"
      ) {
        if (
          this.tradeForm.counterpartyPctPremium != null &&
          this.tradeForm.notional != null
        ) {
          if (
            this.tradeForm.notionalCurrency ==
            this.tradeForm.counterpartyPctPremiumCurrency
          ) {
            this.tradeForm.counterpartyPremium =
              (this.tradeForm.notional *
                this.tradeForm.counterpartyPctPremium) /
              100;
          } else if (this.tradeForm.strike != null) {
            if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy1 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy2
            ) {
              this.tradeForm.counterpartyPremium =
                (this.tradeForm.notional *
                  this.tradeForm.counterpartyPctPremium *
                  this.tradeForm.strike) /
                100;
            } else if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy2 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy1
            ) {
              this.tradeForm.counterpartyPremium =
                (this.tradeForm.notional *
                  this.tradeForm.counterpartyPctPremium) /
                this.tradeForm.strike /
                100;
            }
          }
        }
      } else if (
        this.tradeForm.usePercentOrPrice == "Calculate Client & Cpty from Price"
      ) {
        if (
          this.tradeForm.counterpartyPremium != null &&
          this.tradeForm.notional != null
        ) {
          if (
            this.tradeForm.notionalCurrency ==
            this.tradeForm.counterpartyPctPremiumCurrency
          ) {
            this.tradeForm.counterpartyPctPremium =
              (this.tradeForm.counterpartyPremium / this.tradeForm.notional) *
              100;
          } else if (this.tradeForm.strike != null) {
            if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy1 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy2
            ) {
              this.tradeForm.counterpartyPctPremium =
                (this.tradeForm.counterpartyPremium /
                  this.tradeForm.notional /
                  this.tradeForm.strike) *
                100;
            } else if (
              this.tradeForm.notionalCurrency == this.tradeForm.ccy2 &&
              this.tradeForm.counterpartyPctPremiumCurrency ==
                this.tradeForm.ccy1
            ) {
              this.tradeForm.counterpartyPctPremium =
                (this.tradeForm.counterpartyPremium / this.tradeForm.notional) *
                this.tradeForm.strike *
                100;
            }
          }
        }
      }

      this.arrangeTcCommision();
    },

    async calculateOpenNotional() {
      this.convertNumberInputFormat();

      let searchPortfolioObj = null;
      let totalNotional = 0;

      if (
        this.tradeForm.clientAccount != null &&
        this.tradeForm.buySell != null &&
        this.tradeForm.ccy1 != null &&
        this.tradeForm.ccy2 != null &&
        this.tradeForm.optionType != null &&
        this.tradeForm.expiryDate != null &&
        this.tradeForm.notionalCurrency != null &&
        this.tradeForm.notional != null
      ) {
        searchPortfolioObj = {
          clientAccount: this.tradeForm.clientAccount,
          optionType: this.tradeForm.optionType,
          currency1: this.tradeForm.ccy1,
          currency2: this.tradeForm.ccy2,
          notionalCurrency: this.tradeForm.notionalCurrency,
          cancelled: 0,
        };

        if (this.tradeForm.optionExercise != null)
          searchPortfolioObj.optionExercise = this.tradeForm.optionExercise;
        if (this.tradeForm.settlementType != null)
          searchPortfolioObj.settlementType = this.tradeForm.settlementType;
        if (this.tradeForm.type1Exercise != null)
          searchPortfolioObj.type1Exercise = this.tradeForm.type1Exercise;
        if (this.tradeForm.type2Exercise != null)
          searchPortfolioObj.type2Exercise = this.tradeForm.type2Exercise;
        if (this.tradeForm.valueDate != null)
          searchPortfolioObj.valueDate = this.YMDdateFormat(
            this.tradeForm.valueDate
          );
        if (this.tradeForm.cutOffTime != null)
          searchPortfolioObj.cutOffTime = this.tradeForm.cutOffTime;
        if (this.tradeForm.deliverableCcy != null)
          searchPortfolioObj.deliverableCcy = this.tradeForm.deliverableCcy;
        if (this.tradeForm.callPut1 != null)
          searchPortfolioObj.callPut1 = this.tradeForm.callPut1;
        if (this.tradeForm.callPut2 != null)
          searchPortfolioObj.callPut2 = this.tradeForm.callPut2;
        if (this.tradeForm.strike != null)
          searchPortfolioObj.strike = parseFloat(this.tradeForm.strike);

        if (this.tradeForm.expiryDate != null)
          searchPortfolioObj.expiryDate = this.YMDdateFormat(
            this.tradeForm.expiryDate
          );
        if (this.tradeForm.windowStart != null)
          searchPortfolioObj.windowStart = this.YMDdateFormat(
            this.tradeForm.windowStart
          );
        if (this.tradeForm.windowEnd != null)
          searchPortfolioObj.windowEnd = this.YMDdateFormat(
            this.tradeForm.windowEnd
          );
        if (this.tradeForm.type1 != null && this.tradeForm.type1Level != null) {
          searchPortfolioObj.type1 = this.tradeForm.type1;
          searchPortfolioObj.type1Level = parseFloat(this.tradeForm.type1Level);
        }
        if (this.tradeForm.type2 != null && this.tradeForm.type2Level != null) {
          searchPortfolioObj.type2 = this.tradeForm.type2;
          searchPortfolioObj.type2Level = parseFloat(this.tradeForm.type2Level);
        }
      }

      if (
        this.tradeForm.notional != null &&
        this.tradeForm.cptyAccount != null &&
        searchPortfolioObj != null
      ) {
        searchPortfolioObj.cptyAccount = this.tradeForm.cptyAccount;

        // we are using async await for waiting response
        let findedPortfolioData = await this.searchPortfolioTable(
          searchPortfolioObj
        );

        this.tradeForm.buySell == "Buy"
          ? (totalNotional = parseFloat(this.tradeForm.notional))
          : (totalNotional = parseFloat(this.tradeForm.notional * -1));

        // amend kısmı için burayı kullanıcaz hatırlatma?? excel dosyasında var!!!

        if (findedPortfolioData.length > 0) {
          findedPortfolioData.forEach((data) => {
            if (data.BuySell == "Buy") {
              totalNotional = totalNotional + parseFloat(data.Notional);
            } else {
              totalNotional = totalNotional - parseFloat(data.Notional);
            }
          });
        }

        if (this.tradeForm.buySell == "Buy") {
          if (totalNotional > 0) {
            if (totalNotional >= this.tradeForm.notional) {
              this.tradeForm.openNotional = this.tradeForm.notional;
            } else {
              this.tradeForm.openNotional = totalNotional;
            }
          } else {
            this.tradeForm.openNotional = 0;
          }
        } else if (this.tradeForm.buySell == "Sell") {
          if (totalNotional < 0) {
            if (Math.abs(totalNotional) >= this.tradeForm.notional) {
              this.tradeForm.openNotional = this.tradeForm.notional;
            } else {
              this.tradeForm.openNotional = Math.abs(totalNotional);
            }
          } else {
            this.tradeForm.openNotional = 0;
          }
        }
      } else if (
        this.tradeForm.notional != null &&
        searchPortfolioObj != null
      ) {
        // we are using async await for waiting response
        let findedPortfolioData = await this.searchPortfolioTable(
          searchPortfolioObj
        );

        this.tradeForm.buySell == "Buy"
          ? (totalNotional = parseFloat(this.tradeForm.notional))
          : (totalNotional = parseFloat(this.tradeForm.notional * -1));

        // amend kısmı için burayı kullanıcaz hatırlatma?? excel dosyasında var!!!

        if (findedPortfolioData.length > 0) {
          findedPortfolioData.forEach((data) => {
            if (data.BuySell == "Buy") {
              totalNotional = totalNotional + parseFloat(data.Notional);
            } else {
              totalNotional = totalNotional - parseFloat(data.Notional);
            }
          });
        }

        if (this.tradeForm.buySell == "Buy") {
          if (totalNotional > 0) {
            if (totalNotional > this.tradeForm.notional) {
              this.tradeForm.clientOpen = this.tradeForm.notional;
            } else {
              this.tradeForm.clientOpen = totalNotional;
            }
          } else {
            this.tradeForm.clientOpen = 0;
          }
        } else if (this.tradeForm.buySell == "Sell") {
          if (totalNotional < 0) {
            if (Math.abs(totalNotional) > this.tradeForm.notional) {
              this.tradeForm.clientOpen = this.tradeForm.notional;
            } else {
              this.tradeForm.clientOpen = Math.abs(totalNotional);
            }
          } else {
            this.tradeForm.clientOpen = 0;
          }
        }
      }
      this.arrangeInputFormats();
    },

    searchPortfolioTable(obj) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchPortfolioInFxOptions`, { searchData: obj })
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    // END calculate functions ********************************************************* ////////////////////////////////////////////
    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    checkTime(type) {
      if (type == "Order Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("fx_options.messages.order_time_later_execution_time")
            );
            this.tradeForm.orderTime = null;
          }
        }
      } else if ("Execution Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("fx_options.messages.execution_time_later_order_time")
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_options.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },

    resetTradeModal() {
      this.copyTradeModal = false;
      this.tradeModalLoading = false;
      this.checkedHolidayDate = null;
      this.showDefaultEntry = false;
      this.fetchedTradeData = {};

      this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        ccy1: null,
        ccy2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiryDate: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCurrency: null,
        notional: null,
        spotRef: null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        clientPremiumCurrency: null,
        clientPremium: null,
        tcCommisionCurrency: null,
        tcCommision: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlementDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
      };
      // this.optClients = [];
      this.optPriceTypes = [];
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optTcUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    exitAmendOperationMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.trade_amend_cancelled"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("fx_options.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fx_options/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("fx_options.messages.tc_uti_not_found"));
      }
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_options.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("fx_options.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.not_changes"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkAmendEntryTypeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    copiedTradeMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_options.toast_messages.trade_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (value == undefined || value == null) return Math.round(0);
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val != null) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },

    roundToPointFour(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 10000) / 10000;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setTradeData() {
      let setTrade = {
        CostCenter: this.tradeForm.costCenter,
        Client: this.tradeForm.client != null ? this.tradeForm.client : null,
        ClientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        Cpty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        CptyAcc:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,

        BuySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        Prop: this.tradeForm.prop,
        SettlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        Ccy1: this.tradeForm.ccy1 != null ? this.tradeForm.ccy1 : null,
        Ccy2: this.tradeForm.ccy2 != null ? this.tradeForm.ccy2 : null,
        OptionType:
          this.tradeForm.optionType != null ? this.tradeForm.optionType : null,
        OptionExercise:
          this.tradeForm.optionExercise != null
            ? this.tradeForm.optionExercise
            : null,
        Type1: this.tradeForm.type1 != null ? this.tradeForm.type1 : null,
        Type1Level:
          this.tradeForm.type1Level != null
            ? parseFloat(this.tradeForm.type1Level)
            : null,
        Type1Exercise:
          this.tradeForm.type1Exercise != null
            ? this.tradeForm.type1Exercise
            : null,
        Type2: this.tradeForm.type2 != null ? this.tradeForm.type2 : null,
        Type2Level:
          this.tradeForm.type2Level != null
            ? parseFloat(this.tradeForm.type2Level)
            : null,
        Type2Exercise:
          this.tradeForm.type2Exercise != null
            ? this.tradeForm.type2Exercise
            : null,
        WindowStart:
          this.tradeForm.windowStart != null
            ? this.YMDdateFormat(this.tradeForm.windowStart)
            : null,
        WindowEnd:
          this.tradeForm.windowEnd != null
            ? this.YMDdateFormat(this.tradeForm.windowEnd)
            : null,
        CallPut1:
          this.tradeForm.callPut1 != null ? this.tradeForm.callPut1 : null,
        CallPut2:
          this.tradeForm.callPut2 != null ? this.tradeForm.callPut2 : null,
        Strike:
          this.tradeForm.strike != null
            ? parseFloat(this.tradeForm.strike)
            : null,
        Expiry:
          this.tradeForm.expiryDate != null
            ? this.YMDdateFormat(this.tradeForm.expiryDate)
            : null,
        ValueDate:
          this.tradeForm.valueDate != null
            ? this.YMDdateFormat(this.tradeForm.valueDate)
            : null,
        NotionalCcy:
          this.tradeForm.notionalCurrency != null
            ? this.tradeForm.notionalCurrency
            : null,
        Notional:
          this.tradeForm.notional != null
            ? parseFloat(this.tradeForm.notional)
            : null,
        OpenNotional:
          this.tradeForm.openNotional != null
            ? parseFloat(this.tradeForm.openNotional)
            : null,
        SpotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        // counterpartyPctPremiumCurrency
        PremCcy:
          this.tradeForm.counterpartyPctPremiumCurrency != null
            ? this.tradeForm.counterpartyPctPremiumCurrency
            : null,
        CptyPctPrem:
          this.tradeForm.counterpartyPctPremium != null
            ? parseFloat(this.tradeForm.counterpartyPctPremium)
            : null,
        ClientPctPrem:
          this.tradeForm.clientPctPremium != null
            ? parseFloat(this.tradeForm.clientPctPremium)
            : null,
        CptyPrem:
          this.tradeForm.counterpartyPremium != null
            ? parseFloat(this.tradeForm.counterpartyPremium)
            : null,
        ClientPrem:
          this.tradeForm.clientPremium != null
            ? parseFloat(this.tradeForm.clientPremium)
            : null,
        TcComm:
          this.tradeForm.tcCommision != null
            ? parseFloat(this.tradeForm.tcCommision)
            : null,
        TradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        SettlDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,

        OrderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        ExecutionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,

        CptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,

        TcUser: this.tradeForm.tcUser != null ? this.tradeForm.tcUser : null,

        OrderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,

        OrderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,

        ActingCap:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,

        TraderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        CollateralPct:
          this.tradeForm.collateralPct != null
            ? parseFloat(this.tradeForm.collateralPct)
            : null,

        Collateral:
          this.tradeForm.collateral != null
            ? parseFloat(this.tradeForm.collateral)
            : null,

        CollateralCcy:
          this.tradeForm.collateralCcy != null
            ? this.tradeForm.collateralCcy
            : null,

        ClientOpen:
          this.tradeForm.clientOpen != null
            ? parseFloat(this.tradeForm.clientOpen)
            : null,

        CpRef: this.tradeForm.cpRef != null ? this.tradeForm.cpRef : null,

        CutOffTime:
          this.tradeForm.cutOffTime != null ? this.tradeForm.cutOffTime : null,

        DeliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,

        ConfirmationDate:
          this.tradeForm.confirmationDate != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDate)
            : null,

        ConfirmationTime:
          this.tradeForm.confirmationTime != null
            ? this.timeFormat(this.tradeForm.confirmationTime)
            : null,

        ConfirmationTrader:
          this.tradeForm.confirmationTrader != null
            ? this.tradeForm.confirmationTrader
            : null,

        CptyUti: this.tradeForm.cptyUti != null ? this.tradeForm.cptyUti : null,

        TcUti: this.tradeForm.tcUti != null ? this.tradeForm.tcUti : null,

        UniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,

        ClearingMemberId:
          this.tradeForm.clearingMemberId != null
            ? this.tradeForm.clearingMemberId
            : null,

        ConfirmationDateClient:
          this.tradeForm.confirmationDateClient != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDateClient)
            : null,

        ConfirmationTimeClient:
          this.tradeForm.confirmationTimeClient != null
            ? this.timeFormat(this.tradeForm.confirmationTimeClient)
            : null,
        amendmentReason: this.tradeForm.amendmentReason,
        ID: this.tradeForm.tradeRef,
        // savedBy: this.user.name
        //   ? this.user.name + " " + this.user.surname
        //   : null,
        // saveDate: moment().format("YYYY-MM-DD"),
        // saveTime: moment().format("HH:mm:ss"),
        // amendedBy: null,
        // amendmentDate: null,
        // amentmentTime: null,
        // canceled: 0,
        // canceledBy: null,
        // canceledDate: null,
        // canceledTime: null,
        // canceledReason: null,
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.calcFromSpread": {
    //   handler: function(val, before) {
    //     console.log(val)

    //   },
    //   deep: true,
    // },
    copyTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc != null ? val.ClientAcc : null,
            counterparty: val.Cpty != null ? val.Cpty : null,
            cptyAccount: val.CptyAcc != null ? val.CptyAcc : null,
            buySell: val.BuySell ?? null,
            settlementType: val.SettlementType ?? null,
            ccy1: val.Ccy1 ?? null,
            ccy2: val.Ccy2 ?? null,
            optionExercise: val.OptionExercise ?? null,
            optionType: val.OptionType ?? null,
            type1Exercise: val.Type1Exercise ?? null,
            type1: val.Type1 ?? null,
            type1Level:
              val.Type1Level != null ? parseFloat(val.Type1Level) : null,
            type2Exercise: val.Type2Exercise ?? null,
            type2: val.Type2 ?? null,
            type2Level:
              val.Type2Level != null ? parseFloat(val.Type2Level) : null,
            windowStart:
              val.WindowStart != null ? this.dateFormat(val.WindowStart) : null,
            windowEnd:
              val.WindowEnd != null ? this.dateFormat(val.WindowEnd) : null,
            callPut1: val.CallPut1 ?? null,
            callPut2: val.CallPut1 == "Call" ? "Put" : "Call",
            strike: val.Strike != null ? parseFloat(val.Strike) : null,
            expiryDate: val.Expiry != null ? this.dateFormat(val.Expiry) : null,
            valueDate:
              val.ValueDate != null ? this.dateFormat(val.ValueDate) : null,
            notionalCurrency: val.NotionalCcy ?? null,
            notional: val.Notional != null ? parseFloat(val.Notional) : null,
            openNotional:
              val.OpenNotional != null ? parseFloat(val.OpenNotional) : null,
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            counterpartyPctPremiumCurrency: val.PremCcy ?? null,
            counterpartyPctPremium:
              val.CptyPctPrem != null ? parseFloat(val.CptyPctPrem) : null,
            clientPctPremium:
              val.ClientPctPrem != null ? parseFloat(val.ClientPctPrem) : null,
            counterpartyPremium:
              val.CptyPrem != null ? parseFloat(val.CptyPrem) : null,
            clientPremium:
              val.ClientPrem != null ? parseFloat(val.ClientPrem) : null,
            tcCommision: val.TcComm != null ? parseFloat(val.TcComm) : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlDate != null ? this.dateFormat(val.SettlDate) : null,
            orderTime:
              val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
            executionTime:
              val.ExecutionTime != null
                ? this.HmstimeFormat(val.ExecutionTime)
                : null,
            counterpartyTrader: val.CptyTrader ?? null,
            tcUser: val.TcUser ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            actingCapaticy: val.ActingCap ?? null,
            note: val.TraderNote ?? null,
            collateralPct:
              val.CollateralPct != null ? parseFloat(val.CollateralPct) : null,
            collateral:
              val.Collateral != null ? parseFloat(val.Collateral) : null,
            clientOpen:
              val.ClientOpen != null ? parseFloat(val.ClientOpen) : null,
            cpRef: val.CpRef ?? null,
            cutOffTime: val.CutOffTime ?? null,
            deliverableCcy: val.DeliverableCcy ?? null,
            confirmationDate:
              val.ConfirmationDate != null
                ? this.dateFormat(val.ConfirmationDate)
                : null,
            confirmationTime:
              val.ConfirmationTime != null
                ? this.HmstimeFormat(val.ConfirmationTime)
                : null,
            confirmationTrader: val.ConfirmationTrader ?? null,
            cptyUti: val.CptyUti ?? null,
            tcUti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            clearingMemberId: val.ClearingMemberId ?? null,
            confirmationDateClient:
              val.ConfirmationDateClient != null
                ? this.dateFormat(val.ConfirmationDateClient)
                : null,
            confirmationTimeClient:
              val.ConfirmationTimeClient != null
                ? this.HmstimeFormat(val.ConfirmationTimeClient)
                : null,
            tradeRef: val.id,
          };

          //set trade && settle date && expiry date name
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          let expiryDate = new Date(
            this.MDYdateFormat(this.tradeForm.expiryDate)
          );
          this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

          let valueDate = new Date(
            this.MDYdateFormat(this.tradeForm.valueDate)
          );
          this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          this.arrangeType1();
          this.arrangeInputFormats();

          if (
            this.tradeForm.counterpartyPctPremium == null &&
            this.tradeForm.clientPctPremium == null
          ) {
            this.tradeForm.usePercentOrPrice =
              "Calculate Client & Cpty from Percent";
          } else {
            this.tradeForm.usePercentOrPrice =
              "Calculate Client & Cpty from Price";
          }

          if (this.tradeForm.ccy1 != null) {
            this.copyPremiumCCY1Currencies();
          } else if (this.tradeForm.counterpartyPctPremiumCurrency != null) {
            this.copyCounterpartyPctCurrencies();
          } else if (this.tradeForm.notionalCurrency != null) {
            this.tradeForm.collateralCcy = this.tradeForm.notionalCurrency;
            this.tradeForm.openNotionalCcy = this.tradeForm.notionalCurrency;
            this.tradeForm.clientOpenCcy = this.tradeForm.notionalCurrency;
          }
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
