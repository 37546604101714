<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="viewSelectedTradeModal"
      :title="$t('fx_options.titles.view_selected_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_options.modals.view_trade_modal_ok')"
      :cancel-title="$t('fx_options.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >

    <template #modal-header="{ close }">
        <h5 style="color: white;">{{ $t('fx_options.titles.view_selected_trade') }}</h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
              <b style="color: white;"> {{$t('fx_options.modals.trade_not_verified')}} </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
              <b style="color: white;"> {{$t('fx_options.modals.verified_trade')}} </b>
        </p>

      </template>
      <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->

                  <b-card
                    :title="$t('fx_options.titles.client_counterparty_details')"
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_options.trade_form.client') "
                        >
                          <div>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.client')"
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                class="compact_vue-select"
                                :placeholder="
                                  $t('fx_options.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                ref="client"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.client_account') 
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.client_account')"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.client_account')
                              "
                              :disabled="true"
                              ref="clientAcc"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counterparty -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty') 
                          "
                        >
                          <div>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_options.trade_form.counterparty')"
                            >
                              <v-select
                                ref="cpty"
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.cpty_account') 
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cpty_account')"
                          >
                            <b-form-input
                              ref="cptyAcc"
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* trade Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_date')"
                          >
                            <b-input-group :append="tradeForm.tradeDateName">
                              <flat-pickr
                                ref="TradeDate"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="6"
                        style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.orderTPlus"
                              name="T+"
                              placeholder="T+"
                              type="number"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Settlement Date -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="5"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_date')"
                          >
                            <b-input-group
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                ref="settlDate"
                                :placeholder="
                                  $t('fx_options.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- order time-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_time')"
                          >
                            <cleave
                              id="orderTime"
                              ref="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.execution_time')"
                           
                          >
                            <cleave
                              id="executionTime"
                              ref="executionTime"
                              v-model="tradeForm.executionTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party trader -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              ref="cptyTrader"
                              v-model="tradeForm.counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- TC user -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.sfc_user')"
                          >
                            <v-select
                              ref="tcUser"
                              v-model="tradeForm.tcUser"
                              :options="optTcUsers"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.order_taken_via')"
                          >
                            <v-select
                              ref="orderTakenVia"
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken given through -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              ref="orderGivenThrough"
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.acting_capacity')"
                          >
                            <v-select
                              ref="actingCap"
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cut off time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.cut_off_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.cut_off_time')"
                          >
                            <v-select
                              v-model="tradeForm.cutOffTime"
                              :options="optCutOffTimes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.cut_off_time')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- deliverable ccy-->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.deliverable_ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.deliverable_ccy')"
                          >
                            <v-select
                              ref="deliverableCcy"
                              v-model="tradeForm.deliverableCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.deliverable_ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="traderNote"
                              id="form-textarea"
                              :placeholder="$t('fx_options.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('fx_options.titles.settlement_details')">
                    <b-row>
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationDate')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ConfirmationTime')"
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Confo Date -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationDateClient')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.ConfirmationTimeClient')
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col
                        cols="12"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CpRef')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.CptyUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('fx_options.trade_form.TcUti')"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.UniqueLinkId')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Clearing Member Id -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClearingMemberId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="clearingMemberId"
                          >
                            <b-form-input
                              id="clearingMemberId"
                              ref="clearingMemberId"
                              v-model="tradeForm.clearingMemberId"
                              :name="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_options.trade_form.ClearingMemberId')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- trade ref -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                      >
                        <b-form-group
                          :label="$t('fx_options.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('fx_options.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.trade_ref')
                                "
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                <!-- /*******************************Option Details***************************************************************** */ -->

                <b-card
                    :title="$t('fx_options.titles.option_details')"
                  >
                    <b-row>
                      <!-- buy/sell -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.buy_sell')"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="false"
                              ref="buySell"
                              :placeholder="
                                $t('fx_options.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- settlement type-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.settlement_type')"
                          >
                            <v-select
                              ref="settlementType"
                              v-model="tradeForm.settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                          >
                            <v-select
                              ref="costCenter"
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Currency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency1')"
                          >
                            <v-select
                              ref="ccy1"
                              v-model="tradeForm.ccy1"
                              :options="optCurrency1Types"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- currency 2 -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_options.trade_form.currency2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.currency2')"
                          >
                            <v-select
                              ref="ccy2"
                              v-model="tradeForm.ccy2"
                              :options="optCurrency2Types"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_currency2')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Exercise -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_exercise')"
                          >
                            <v-select
                              ref="optionExercise"
                              v-model="tradeForm.optionExercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.select_option_exercise'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Option Type -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.option_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.option_type')"
                          >
                            <v-select
                              ref="optionType"
                              v-model="tradeForm.optionType"
                              :options="optOptionTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.select_option_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    
                      <!-- Type1 Exercise -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_exercise')"
                          >
                            <v-select
                              ref="type1Exercise"
                              v-model="tradeForm.type1Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type1_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 1 -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1')"
                          >
                            <v-select
                              ref="type1"
                              v-model="tradeForm.type1"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type1')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 1 Level -->
                      <b-col  v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type1_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type1_level')"
                          >
                            <cleave
                              id="type1Level"
                              ref="type1Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type1Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type1_level')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 Exercise -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_exercise')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_exercise')"
                          >
                            <v-select
                              ref="type2Exercise"
                              v-model="tradeForm.type2Exercise"
                              :options="optOptionExercise"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.type2_exercise')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Type 2 -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2')"
                          >
                            <v-select
                              ref="type2"
                              v-model="tradeForm.type2"
                              :options="optTypes"
                              :clearable="false"
                              :placeholder="$t('fx_options.trade_form.type2')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Type 2 Level -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.type2_level')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.type2_level')"
                          >
                            <cleave
                              id="type2Level"
                              ref="type2Level"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.type2Level"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.type2_level')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window Start-->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_start')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_start')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_start')
                              "
                              ref="windowStart"
                              v-model="tradeForm.windowStart"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Window End -->
                      <b-col v-if="tradeForm.optionType == 'Exotic'" cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.window_end')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.window_end')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_options.trade_form.window_end')
                              "
                              ref="windowEnd"
                              v-model="tradeForm.windowEnd"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- CallPut 1 -->
                      <b-col  cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut1')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut1')"
                          >
                            <v-select
                              ref="callPut1"
                              v-model="tradeForm.callPut1"
                              :options="optCallPut"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut1')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- callPut 2 -->
                      <b-col  cols="6" xl="2" lg="2" md="4" sm="6" class="animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.CallPut2')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CallPut2')"
                          >
                            <b-form-input
                              id="callPut2"
                              ref="callPut2"
                              v-model="tradeForm.callPut2"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              :placeholder="
                                $t('fx_options.trade_form.CallPut2')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Strike -->
                      <b-col  cols="6" xl="3" lg="3" md="4" sm="6" class="padleft-0 animate__animated animate__fadeIn">
                        <b-form-group
                          :label="$t('fx_options.trade_form.Strike')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Strike')"
                            
                          >
                            <cleave
                              id="strike"
                              ref="strike"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.strike"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="$t('fx_options.trade_form.Strike')"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Expiry date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Expiry')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Expiry')"
                          >
                            <b-input-group :append="tradeForm.expiryDateName">
                              <flat-pickr
                                ref="expiry"
                                :placeholder="
                                  $t('fx_options.trade_form.Expiry')
                                "
                                v-model="tradeForm.expiryDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- option arrange T+ -->
                      <b-col cols="6" xl="1" lg="1" md="2" sm="6"
                      
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.optionTplus"
                              name="T+"
                              placeholder="T+"
                              :disabled="true"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- value Date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ValueDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ValueDate')"
                          >
                            <b-input-group :append="tradeForm.valueDateName">
                              <flat-pickr
                                ref="valueDate"
                                :placeholder="
                                  $t('fx_options.trade_form.ValueDate')
                                "
                                v-model="tradeForm.valueDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- NotionalCurrency 1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.NotionalCurrency')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.NotionalCurrency')"
                          >
                            <v-select
                              ref="notionalCcy"
                              v-model="tradeForm.notionalCurrency"
                              :options="optNotionalCurrencies"
                              :clearable="false"
                              :placeholder="
                                $t('fx_options.trade_form.NotionalCurrency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notional -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Notional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Notional')"
                          >
                            <cleave
                              id="notional"
                              ref="notional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.notional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.Notional')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- spotRef -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            
                          >
                            <cleave
                              id="spot_ref"
                              ref="spotRef"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave4DecimalOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.spot_ref')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty prem Counterparty Pct Premium Currency -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t(
                              'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyPctPremiumCurrency"
                              :options="optCounterpartyPctPreCurrencies"
                              :clearable="false"
                              ref="premCcy"
                              :placeholder="
                                $t(
                                  'fx_options.trade_form.CounterpartyPctPremiumCurrency'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPctPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPctPremium')
                            "
                          >
                            <b-input-group prepend="%" :append="tradeForm.counterpartyPctPremiumCurrency">
                              <b-form-input
                                id="counterpartyPctPremium"
                                v-model.number="
                                  tradeForm.counterpartyPctPremium
                                "
                                name="counterpartyPctPremium"
                                type="number"
                                ref="cptyPctPrem"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_options.trade_form.CounterpartyPctPremium'
                                  )
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Percent -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPctPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPctPremium')"
                          >
                            <b-input-group :append="tradeForm.clientPctPremiumCurrency" prepend="%">
                              <b-form-input
                                id="clientPctPremium"
                                v-model.number="tradeForm.clientPctPremium"
                                name="clientPctPremium"
                                ref="clientPctPrem"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.ClientPctPremium')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- use client cpty percent or client percent value radio button -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" class="mt-1 padleft-0">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.usePercentOrPrice"
                            :options="optPercentOrPrice"
                            name="radios-stacked"
                            stacked
                            plain
                            class="fxDetailRadio"
                            :disabled="true"
                          />
                        </b-form-group>
                      </b-col>

                      

                      <!-- CounterpartyPremium -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t('fx_options.trade_form.CounterpartyPremium')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_options.trade_form.CounterpartyPremium')
                            "
                          >
                          <b-input-group :append="tradeForm.counterpartyPremiumCurrency" class="compact_form_input-group" ref="groupAppend">
                            <cleave
                              id="counterpartyPremium"
                              ref="cptyPrem"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyPremium"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.CounterpartyPremium')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ClientPremium -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientPremium')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientPremium')"
                            
                          >
                          <b-input-group :append="tradeForm.clientPremiumCurrency" class="compact_form_input-group" ref="groupAppend">
                            <cleave
                              id="clientPremium"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPremium"
                              class="form-control"
                              ref="clientPrem"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.ClientPremium')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    

                      <!-- Tc Commision -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.TcCommision')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.TcCommision')"
                          >
                          <b-input-group :append="tradeForm.tcCommisionCurrency" class="compact_form_input-group" ref="groupAppend">
                            <cleave
                              id="tcCommision"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.tcCommision"
                              ref="tcComm"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.TcCommision')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.Collateral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.Collateral')"
                          >
                          <b-input-group :append="tradeForm.collateralCcy" class="compact_form_input-group" ref="groupAppend">

                            <cleave
                              ref="collateral"
                              id="collateral"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.collateral"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.Collateral')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- CollateralPct -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.CollateralPct')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.CollateralPct')"
                            
                          >


                            <b-input-group ref="groupAppend" prepend="%" :append="tradeForm.collateralCcy" class="compact_form_input-group">
                              <b-form-input
                                ref="collateralPct"
                                id="collateralPct"
                                v-model.number="tradeForm.collateralPct"
                                name="collateralPct"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_options.trade_form.CollateralPct')
                                "
                                :disabled="true"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>


                      <!-- cpty open  -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.OpenNotional')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.OpenNotional')"
                          >
                          <b-input-group ref="groupAppend" :append="tradeForm.openNotionalCcy" class="compact_form_input-group">

                            <cleave
                              ref="openNotional"
                              id="openNotional"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.openNotional"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.OpenNotional')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                     
                      <!-- Client Open -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_options.trade_form.ClientOpen')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_options.trade_form.ClientOpen')"
                          >
                          <b-input-group ref="groupAppend" :append="tradeForm.clientOpenCcy" class="compact_form_input-group">
                            <cleave
                              id="clientOpen"
                              ref="clientOpen"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientOpen"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_options.trade_form.ClientOpen')
                              "
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  </b-card>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-container>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("fx_options.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("fx_options.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>

            <!-- changed input message -->
            <p class="d-inline ml-1 mb-0" v-if="changedInputs.length > 0">
              <b-icon
                class="ml-1"
                title=""
                icon="record-fill"
                style="color:orange!important"
                data-toggle="tooltip"
                data-placement="top"
                font-scale="0.5"
              ></b-icon
              ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
                $t("fx_options.modals.inputs_written_red_inputs")
              }}</b>
            </p>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center mobile-footer"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("fx_options.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{ $t("fx_options.modals.view_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** view for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXOptionsStoreModule from "./FXOptionsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
      const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
      // Register module
      if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
        store.registerModule(
          FXOPTION_APP_STORE_MODULE_NAME,
          fXOptionsStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
          store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},

  props: {
     
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},

  data() {
    
    return {
      checkVerify: false,
      viewSelectedTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      showDefaultEntry: false,
      defaultEntryName: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optbuySells: ["Buy", "Sell"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optOptionExercise: ["American", "European"],
      optCallPut: ["Call", "Put"],
      optOptionTypes: ["Vanilla", "Exotic"],
      optTypes: ["KI", "KO", "OT", "NT"],
      optPercentOrPrice: [
        {
          text: "Calculate Client & Cpty from Percent",
          value: "Calculate Client & Cpty from Percent",
        },
        {
          text: "Calculate Client & Cpty from Price",
          value: "Calculate Client & Cpty from Price",
        },
      ],
      optPriceTypes: [],
      optCurrency1Types: [],
      optCurrency2Types: [],
      optNotionalCurrencies: [],
      optCounterpartyPctPreCurrencies: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optCounterPartyTraders: [],
      optTcUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,
      optDefaultEntries: [],
      actionsData: null,
      changedInputs: [],
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        ccy1: null,
        ccy2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiryDate: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCurrency: null,
        notional: null,
        spotRef: null,
        counterpartyPctPremiumCurrency: null,
        counterpartyPctPremium: null,
        clientPctPremiumCurrency: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        counterpartyPremium: null,
        clientPremiumCurrency: null,
        clientPremium: null,
        tcCommisionCurrency: null,
        tcCommision: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlementDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        counterpartyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        cpRef: null,
        clearingMemberId: null,
        //
        tradeRef:null
      },
      changedValuesForm: {
        costCenter: null,
        client: null,
        clientAcc: null,
        cpty: null,
        cptyAcc: null,

        //option details
        buySell: "Buy",
        prop: false,
        settlementType: null,
        ccy1: null,
        ccy2: null,
        optionExercise: null,
        optionType: null,
        type1Exercise: null,
        type1: null,
        type1Level: null,
        type2Exercise: null,
        type2: null,
        type2Level: null,
        windowStart: null,
        windowEnd: null,
        callPut1: null,
        callPut2: null,
        strike: null,
        expiry: null,
        expiryDateName: null,
        optionTplus: null,
        valueDate: null,
        valueDateName: null,
        notionalCcy: null,
        notional: null,
        spotRef: null,
        premCcy: null,
        cptyPctPrem: null,
        clientPctPrem: null,
        clientPctPremium: null,
        usePercentOrPrice: "Calculate Client & Cpty from Percent",
        counterpartyPremiumCurrency: null,
        cptyPrem: null,
        clientPremiumCurrency: null,
        clientPrem: null,
        tcCommisionCurrency: null,
        tcComm: null,
        collateralPct: null,
        collateralCcy: null,
        collateral: null,
        openNotionalCcy: null,
        openNotional: null,
        clientOpenCcy: null,
        clientOpen: null,
        //

        //order details
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        orderTPlus: null,
        settlDate: null,
        settlementDateName: null,
        orderTime: null,
        executionTime: null,
        cptyTrader: null,
        tcUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCap: null,
        traderNote: null,
        cutOffTime: null,
        deliverableCcy: null,
        //

        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkId: null,
        cpRef: null,
        clearingMemberId: null,
        //
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },

      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxOptions"]),

    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fx_options/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fx_options/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("fx_options.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fx_options/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
            this.optCurrency1Types = res.data.currencies;
            this.optCurrency2Types = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.viewSelectedTradeModal = false;
      this.actionsData = null
    },
    copyPremiumCCY1Currencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.ccy1;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.ccy1;

      this.tradeForm.openNotionalCcy = this.tradeForm.ccy1;
      this.tradeForm.clientOpenCcy = this.tradeForm.ccy1;
      this.tradeForm.collateralCcy = this.tradeForm.ccy1;
    },
    copyCounterpartyPctCurrencies() {
      this.tradeForm.clientPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.clientPctPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.counterpartyPremiumCurrency = this.tradeForm.counterpartyPctPremiumCurrency;
      this.tradeForm.tcCommisionCurrency = this.tradeForm.counterpartyPctPremiumCurrency;

    },
    resetTradeModal() {
      this.viewSelectedTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.checkVerify = false;
      this.changedInputs = [];
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    arrangeInputFormats() {
      if (this.tradeForm.strike != null) {
        // this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.strike = Number.parseFloat(
          this.tradeForm.strike
        ).toFixed(4);
      }
      if (this.tradeForm.spotRef != null) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(4);
      }

      if (this.tradeForm.notional != null) {
        this.tradeForm.notional = Number.parseFloat(
          this.tradeForm.notional
        ).toFixed(2);
      }

      if (this.tradeForm.counterpartyPctPremium != null) {
        this.tradeForm.counterpartyPctPremium = Number.parseFloat(
          this.tradeForm.counterpartyPctPremium
        ).toFixed(2);
      }

      if (this.tradeForm.clientPctPremium != null) {
        this.tradeForm.clientPctPremium = Number.parseFloat(
          this.tradeForm.clientPctPremium
        ).toFixed(2);
      }

      if (this.tradeForm.tcCommision != null) {
        this.tradeForm.tcCommision = Number.parseFloat(
          this.tradeForm.tcCommision
        ).toFixed(2);
      }

      if (this.tradeForm.counterpartyPremium != null) {
        this.tradeForm.counterpartyPremium = Number.parseFloat(
          this.tradeForm.counterpartyPremium
        ).toFixed(2);
      }

      if (this.tradeForm.clientPremium != null) {
        this.tradeForm.clientPremium = Number.parseFloat(
          this.tradeForm.clientPremium
        ).toFixed(2);
      }

      if (this.tradeForm.collateralPct != null) {
        this.tradeForm.collateralPct = Number.parseFloat(
          this.tradeForm.collateralPct
        ).toFixed(2);
      }

      if (this.tradeForm.collateral != null) {
        this.tradeForm.collateral = Number.parseFloat(
          this.tradeForm.collateral
        ).toFixed(2);
      }

      if (this.tradeForm.openNotional != null) {
        this.tradeForm.openNotional = Number.parseFloat(
          this.tradeForm.openNotional
        ).toFixed(2);
      }

      if (this.tradeForm.clientOpen != null) {
        this.tradeForm.clientOpen = Number.parseFloat(
          this.tradeForm.clientOpen
        ).toFixed(2);
      }

      if (this.tradeForm.type1Level != null) {
        this.tradeForm.type1Level = Number.parseFloat(
          this.tradeForm.type1Level
        ).toFixed(4);
      }

      if (this.tradeForm.type2Level != null) {
        this.tradeForm.type2Level = Number.parseFloat(
          this.tradeForm.type2Level
        ).toFixed(4);
      }
    },
  },
  watch: {
    viewSelectedTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();

          setTimeout(() => {
            if (this.changedInputs.length > 0) {
              this.changedInputs.forEach((i) => {
                if (this.changedValuesForm.hasOwnProperty(_.camelCase(i.key))) {
                  let refKey = _.camelCase(i.key);
                  if (this.$refs[refKey]) {
                    if (
                      this.$refs[refKey].$el.classList.length > 0 ||
                      this.$refs[refKey].$el.classList.indexOf("v-select") == 1
                    ) {
                      this.$refs[refKey].$el.classList.add(
                        "change_selectbox_style"
                      ); // for v-select box
                    }

                    this.$refs[refKey].$el.style = "color:orange";
                  }
                }
              });
            }
          }, 500);
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc != null ? val.ClientAcc : null,
            counterparty: val.Cpty != null ? val.Cpty : null,
            cptyAccount: val.CptyAcc != null ? val.CptyAcc : null,
            buySell: val.BuySell ?? null,
            settlementType: val.SettlementType ?? null,
            ccy1: val.Ccy1 ?? null,
            ccy2: val.Ccy2 ?? null,
            optionExercise: val.OptionExercise ?? null,
            optionType: val.OptionType ?? null,
            type1Exercise: val.Type1Exercise ?? null,
            type1: val.Type1 ?? null,
            type1Level: val.Type1Level != null ? parseFloat(val.Type1Level) : null,
            type2Exercise: val.Type2Exercise ?? null,
            type2: val.Type2 ?? null,
            type2Level: val.Type2Level != null ? parseFloat(val.Type2Level) : null,
            windowStart:
              val.WindowStart != null
                ? this.dateFormat(val.WindowStart)
                : null,
            windowEnd:
              val.WindowEnd != null
                ? this.dateFormat(val.WindowEnd)
                : null,
            callPut1: val.CallPut1 ?? null,
            callPut2: val.CallPut1 == 'Call' ? 'Put' : 'Call',
            strike: val.Strike != null ? parseFloat(val.Strike) : null,
            expiryDate:
              val.Expiry != null
                ? this.dateFormat(val.Expiry)
                : null,
            valueDate:
              val.ValueDate != null
                ? this.dateFormat(val.ValueDate)
                : null,
            notionalCurrency: val.NotionalCcy ?? null,
            notional: val.Notional != null ? parseFloat(val.Notional) : null,
            openNotional: val.OpenNotional != null ? parseFloat(val.OpenNotional) : null,
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            counterpartyPctPremiumCurrency: val.PremCcy ?? null,
            counterpartyPctPremium: val.CptyPctPrem != null ? parseFloat(val.CptyPctPrem) : null,
            clientPctPremium: val.ClientPctPrem != null ? parseFloat(val.ClientPctPrem) : null,
            counterpartyPremium: val.CptyPrem != null ? parseFloat(val.CptyPrem) : null,
            clientPremium: val.ClientPrem != null ? parseFloat(val.ClientPrem) : null,
            tcCommision: val.TcComm != null ? parseFloat(val.TcComm) : null,
            tradeDate:
              val.TradeDate != null
                ? this.dateFormat(val.TradeDate)
                : null,
            settlementDate:
              val.SettlDate != null
                ? this.dateFormat(val.SettlDate)
                : null,
            orderTime:
              val.OrderTime != null
                ? this.HmstimeFormat(val.OrderTime)
                : null,
            executionTime:
              val.ExecutionTime != null
                ? this.HmstimeFormat(val.ExecutionTime)
                : null,
            counterpartyTrader: val.CptyTrader ?? null,
            tcUser: val.TcUser ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            actingCapaticy: val.ActingCap ?? null,
            note: val.TraderNote ?? null,
            collateralPct: val.CollateralPct != null ? parseFloat(val.CollateralPct) : null,
            collateral: val.Collateral != null ? parseFloat(val.Collateral) : null,
            clientOpen: val.ClientOpen != null ? parseFloat(val.ClientOpen) : null,
            cpRef: val.CpRef ?? null,
            cutOffTime: val.CutOffTime ?? null,
            deliverableCcy: val.DeliverableCcy ?? null,
            confirmationDate:
              val.ConfirmationDate != null
                ? this.dateFormat(val.ConfirmationDate)
                : null,
            confirmationTime:
              val.ConfirmationTime != null
                ? this.HmstimeFormat(val.ConfirmationTime)
                : null,
            confirmationTrader: val.ConfirmationTrader ?? null,
            cptyUti: val.CptyUti ?? null,
            tcUti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            clearingMemberId: val.ClearingMemberId ?? null,
            confirmationDateClient:
              val.ConfirmationDateClient != null
                ? this.dateFormat(val.ConfirmationDateClient)
                : null,
            confirmationTimeClient:
              val.ConfirmationTimeClient != null
                ? this.HmstimeFormat(val.ConfirmationTimeClient)
                : null,
            tradeRef: val.id
            
          };

          //set trade && settle date && expiry date name
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          let expiryDate = new Date(
            this.MDYdateFormat(this.tradeForm.expiryDate)
          );
          this.tradeForm.expiryDateName = this.weekday[expiryDate.getDay()];

          let valueDate = new Date(
            this.MDYdateFormat(this.tradeForm.valueDate)
          );
          this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];

          this.arrangeInputFormats();


          if(this.tradeForm.ccy1 != null){
            this.copyPremiumCCY1Currencies();
          } else if(this.tradeForm.counterpartyPctPremiumCurrency != null){
            this.copyCounterpartyPctCurrencies();
          } else if(this.tradeForm.notionalCurrency != null){
            this.tradeForm.collateralCcy = this.tradeForm.notionalCurrency;
            this.tradeForm.openNotionalCcy = this.tradeForm.notionalCurrency;
            this.tradeForm.clientOpenCcy = this.tradeForm.notionalCurrency;
          }

          
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle {
    .vs__selected-options {
      .vs__selected {
        color: orange;
      }
    }
  }
}
</style>

<!-- compact design scss code -->
<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
