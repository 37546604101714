<template>
  <div>
    <b-card class="mb-1" :title="$t('fx_options.titles.fx_options')">
      <b-overlay :show="orderColumnsOverlay">
        <hr />
        <b-row class="mb-2 align-items-center">

           <!-- filter trade date -->
           <b-col cols="6" md="6" xl="2" lg="2" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('fx_options.trade_date')"
                    onClose="testClose"
                    v-model="tradeRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getfilterTradeDate()"
                    
                  
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearTradeDate()"
                      variant="success"
                      size="sm"
                      
                     
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- filter settle date -->
              <b-col cols="6" md="6" xl="2" lg="2" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('fx_options.settle_date')"
                    onClose="testClose"
                    v-model="SettleRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterSettleDate"
                    
           
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearSettleDate()"
                      variant="success"
                      size="sm"
                      
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- filter expiry date -->
              <b-col cols="6" md="6" xl="2" lg="2" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('fx_options.expiry_date')"
                    onClose="testClose"
                    v-model="expiryRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterExpiryDate"
                    
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearExpiryDate()"
                      variant="success"
                      size="sm"
                      
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>

              <!-- list page buttons -->
              <b-col  cols="12" md="6" xl="6" lg="6" sm="12" class="d-flex justify-content-end align-items-center list-pages-buttons">
               
                <!-- search for trades -->
                <b-button
                v-if="$Can('search_fxoptions')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-info"
                size="sm"
                class="compact_nav_icon-text-button mr-1"
                @click="searchForTradesModal"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="14"
                  style="margin-bottom: 0.3rem;"
                />
                <span class="trades-font">{{
                  $t("fx_options.buttons.search_for_trades")
                }}</span>
              </b-button>
              <!-- run last search -->
                <b-button
                  v-if="$Can('search_fxoptions')"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="compact_nav_icon-text-button mr-1"
                  @click="runLastSearch(true)"
                 
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="14"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("fx_options.buttons.run_last_search")
                  }}</span>
                </b-button>
                <!-- enter new trade -->
                <b-button
                  
                  v-if="$Can('add_fxoptions')"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="gradient-success"
                  class="compact_nav_icon-text-button"
                  @click="createNewTrade"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="14"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("fx_options.buttons.enter_new_trade")
                  }}</span>
                </b-button>
              
              </b-col>
            </b-row>
            <!-- order columns overlay -->
          <template #overlay>
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <p><strong id="form-confirm-label">{{ $t("general_messages.save_order_columns_message") }}</strong></p>
  
              <p class="mb-0" style="font-size:0.8rem;">{{ $t("general_messages.filter_columns_message") }}</p>
              <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-md-0"
                >
                  <treeselect
                    value-consists-of="LEAF_PRIORITY"
                    :multiple="true"
                    :options="tableColumnsOptions"
                    :clearable="false"
                    :searchable="true"
                    v-model="treeTablecolumns"
                    @select="selectTreeColumn"
                    @deselect="deSelectTreeColumn"
                    valueFormat="object"
                    :limit="9"
                    :placeholder="$t('fx_options.get_selected_columns')"
                    v-if="visibleFxOptionStatusInput"
                    class="treeSelect"
                  />
                </b-col>
              <div class="d-flex justify-content-center mt-1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="gradient-danger"
                  class="mr-3"
                  size="sm"
                  @click="onCancelOrderColumn"
                >
                {{ $t("general_buttons.cancel") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="gradient-warning"
                  size="sm"
                  @click="onSaveOrderColumns"
                >
                {{ $t("general_buttons.save_columns_order") }}
                </b-button>
                
              </div>
            </div>
          </template>
        </b-overlay>
      </b-card>


         
            <!-- second card -->
          <b-overlay :show="loading" rounded="sm" no-fade>
        <b-card>
          <b-row class="mb-1 align-items-center">
             <!-- Per Page -->
          <b-col cols="6" md="3" sm="6" xl="2" lg="2"
                class="d-flex align-items-center justify-content-start mb-md-0"
                v-if="visibleFxOptionStatusInput"
              >
                <label style="font-size:0.75rem;font-weight:400;">Show</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-100 compact_form_vue-select"
                />
                <label style="font-size:0.75rem;font-weight:400;">entries</label>
              </b-col>
              <!-- filter status -->
              <b-col cols="6" md="4" sm="6" xl="3" lg="3"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="statusOptions"
                  v-model="treeStatus"
                  valueFormat="object"
                  :limit="4"
                  clearable
                  :placeholder="$t('fx_options.selections')"
                  v-if="visibleFxOptionStatusInput"
                  class="treeSelect compact_form_vue-treeselect"
                />
              </b-col >
              <!-- export && legend button -->
              <b-col cols="6" md="5" sm="6" xl="7" lg="7" class="d-flex justify-content-end align-items-center" v-if="visibleFxOptionStatusInput">
              <!-- legend -->
              <b-button
                    id="info-legend-button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="compact_nav_icon-text-button mr-1"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
  
                    <span class="text-nowrap">{{
                      $t("fx_options.buttons.legend_table")
                    }}</span>
                  </b-button>
                  <b-popover
                    target="info-legend-button"
                    placement="left"
                    triggers="click blur" 
                  >
                    <template #title>
                      {{ $t("fx_options.legend_table.legend") }}
                    </template>
                    <b-table-simple hover small caption-top responsive bordered>
                      <b-tbody>
                        <b-tr>
                          <td style="width: 60%;">
                            {{ $t("fx_options.legend_table.execution") }}
                          </td>
                          <td style="background-color: paleturquoise;"></td>
                        </b-tr>
                        <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.verified") }}
                          </td>
                          <td style="background-color: springgreen;"></td>
                        </b-tr>
                        <!-- <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.journal_created") }}
                          </td>
                          <td style="background-color: mediumpurple;"></td>
                        </b-tr> -->
                        <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.expired_order") }}
                          </td>
                          <td style="background-color: silver;"></td>
                        </b-tr>
                        <!-- <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.revers_initiated") }}
                          </td>
                          <td style="background-color: #FFFF99;"></td>
                        </b-tr> -->
                        <!-- <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.reversed") }}
                          </td>
                          <td style="background-color: coral;"></td>
                        </b-tr> -->
                        <b-tr>
                          <td>
                            {{ $t("fx_options.legend_table.cancelled") }}
                          </td>
                          <td style="background-color: #ffb8b8 !important;"></td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-popover>
                  <!-- export excell -->
                  <b-button
                    id="info-export-button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="compact_nav_icon-text-button"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
  
                    <span class="text-nowrap">{{
                      $t("fx_options.buttons.export")
                    }}</span>
                  </b-button>
                  <b-popover
                    target="info-export-button"
                    placement="right"
                    triggers="click blur" 
                  >
                    <template #title>
                      {{ $t("fx_options.buttons.export") }}
                    </template>
  
                    <b-button size="sm" class="compact_nav_icon-text-button" @click="exportXlsx()">
                      <span class="text-nowrap">Save as .xlsx</span>
                    </b-button>
  
                    <b-button
                      size="sm"
                      class="compact_nav_icon-text-button mt-1"
                      @click="exportCsv()"
                    >
                      <span class="text-nowrap">Save as .csv</span>
                    </b-button>
                  </b-popover>
  
              </b-col>
          </b-row>


             <!-- list table -->
        <div
          @contextmenu.prevent="$refs.contextMenu.open"
          v-if="allFxOptionsData.length > 0"
        >
          <div
            style="background-color:white;"
            :style="'height:' + (windowHeight - 350) + 'px'"
            id="table-container"
          >
            <b-table
              :sticky-header="true"
              :no-border-collapse="true"
              hover
              :bordered="true"
              ref="refFXoptionsTable"
              class="compact_table"
              thead-class="animate__animated animate__fadeIn"
              :items="filteredTrades"
              :filter="filter"
              filter-debounce="250"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              selectable
              select-mode="single"
              :small="true"
              head-variant="dark"
              :fields="tableColumns"
              show-empty
              empty-text="No matching records found"
              :current-page="currentPage"
              :per-page="perPage"
              :tbody-tr-class="legendClass"
              caption-top
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortByDesc"
            >
            <!-- @row-clicked="onRowSelected" -->
            
            <!-- sort columns draggable -->
            <template slot="thead-top" slot-scope="data">
              <draggable v-model="tableColumns" tag="tr" id="draggableColumns" style="display: none;" class="animate__animated animate__fadeIn compact_draggable_table_columns">
                <th v-for="header in tableColumns" :key="header.key" scope="col" style="white-space: nowrap;padding: 1rem;cursor:grab">{{ header.label }}</th>
              </draggable>
            </template>

              <!-- filter columns -->
              <template
                slot="top-row"
                slot-scope="{ fields }"
                v-if="showColumnsFilter"
              >
                <td
                  :class="field.key == 'ID' ? 'sticky-column' : ''"
                  v-for="field in tableColumns"
                  :key="field.key"
                >
                  <v-select
                    v-if="field.key == 'SettlementType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input compact_form_vue-select'"
                    :options="optSettlementTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <input
                    v-else-if="field.key == 'ID'"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="'ID_column_search_input compact_form-input'"
                    style="max-width: 75px;"
                  />

                  <input
                    v-else
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input compact_form-input'"
                  />
                  <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
                </td>
              </template>

              <!-- Column: ID -->
              <template #cell(ID)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <b
                    ><span class="font-weight-bold text-center">
                      <strong style="text-decoration:underline;">{{
                        data.item.ID ? data.item.ID : "-"
                      }}</strong>
                    </span></b
                  >
                </div>

                <!-- trade controls -->
                <div v-if="!showMultiplecheckboks && filteredTrades.length > 0" class="d-flex justify-content-center align-items-center compact_controls_button">
                  <b-button
                    :id="'trade-controls-' + data.item ? data.item.ID.toString() : ''"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="top-btns animate__animated animate__fadeIn"
                    @click="
                      showTradeControlsPopover(
                        'trade-controls-' + data.item ? data.item.ID.toString() : '',
                        data.item
                      )
                    "
                  >
                    <i class="fa-solid fa-bars"></i>
                  </b-button>
                  <b-popover
                    :target="'trade-controls-' + data.item ? data.item.ID.toString() : ''"
                    triggers="focus"
                    variant="primary"
                  >
                    <template #title>
                      {{ $t("fx_options.titles.trade_controls") }}
                    </template>

                    
                    <!-- <b-button
                      v-if="$Can('verify_fxOption') && seeVerifyButton"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      :title="$t('fx_options.buttons.verify_trade')"
                      size="sm"
                      class="top-btns mt-1"
                      @click="verifySelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="CheckSquareIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.verify_trade") }}</span
                      >
                    </b-button> -->

                    <b-button
                      v-if="$Can('amend_fxOption') && seeAmendButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-warning"
                      size="sm"
                      class="top-btns mt-1"
                      @click="amendUpdate(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EditIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.amend_trade") }}</span
                      >
                    </b-button>

                    <!-- <b-button
                      v-if="$Can('journal_fxOption') && seeJournalButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="createJournalEntries(data.item)"
                    >
                      
                      <span class="trades-font"><feather-icon
                        icon="ClipboardIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("fx_options.buttons.create_Journal_entries")
                      }}</span>
                    </b-button> -->

                    <b-button
                      v-if="$Can('view_fxOption')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="viewSelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EyeIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.view_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('copy_fxOption') && seeCopyButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="copySelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="CopyIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.copy_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('history_fxOption') && seeAmendDetailsButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="getAmendDetails(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ListIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.get_amend_details") }}</span
                      >
                    </b-button>

                    <!-- <b-button
                      v-if="$Can('view_journals_fxOption') && seeJorurnalEntriesButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="viewJournalEntries(data.item)"
                    >
                      
                      <span class="trades-font"> <feather-icon
                        icon="EyeIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("fx_options.buttons.view_journal_entries")
                      }}</span>
                    </b-button> -->

                    <!-- <b-button
                      v-if="$Can('initiate_fxOption') && seeReversalInitiatedButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-dark"
                      size="sm"
                      class="top-btns mt-1"
                      @click="initiateReversal(data.item)"
                    >
                      
                      <span class="trades-font"> <feather-icon
                        icon="PlayIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("fx_options.buttons.initiate_revesal")
                      }}</span>
                    </b-button> -->

                    <!-- <b-button
                      v-if="$Can('reverse_fxOption') && seeReversedButton "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-dark"
                      size="sm"
                      class="top-btns mt-1"
                      @click="reverseEntry(data.item)"
                    >
                      
                      <span class="trades-font"> <feather-icon
                        icon="CornerUpLeftIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("fx_options.buttons.reverse_entry")
                      }}</span>
                    </b-button> -->

                    <!-- <b-button
                      v-if="$Can('undo_fxOption') && seeUndoInitiatedButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-dark"
                      size="sm"
                      class="top-btns mt-1"
                      @click="undoInitiateReversal(data.item)"
                    >
                      
                      <span class="trades-font"> <feather-icon
                        icon="ChevronsLeftIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("fx_options.buttons.undo_initiate_reversal")
                      }}</span>
                    </b-button> -->

                    <b-button
                      v-if="$Can('cancel_fxOption') && seeCancelledButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      class="top-btns mt-1"
                      @click="cancelSelectedTrade(data.item)"
                      :disabled="data.item.Cancelled == 1"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="XSquareIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("fx_options.buttons.cancel_trade") }}</span
                      >
                    </b-button>

                    <b-button
                        v-if="$Can('bring_same_fxOption') && bringSameOptions"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="getSameOptionsSelectedClient(data.item)"
                        :disabled="data.item.Cancelled == 1"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="GitMergeIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("fx_options.buttons.bring_same_options") }}</span
                        >
                      </b-button>

                    <b-button
                        v-if="$Can('get_paired_fxOption') && seeGetPairedOpr"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="getPairedSelectedTrade(data.item)"
                        :disabled="data.item.Cancelled == 1"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="GitMergeIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("fx_options.buttons.get_paired_trade") }}</span
                        >
                      </b-button>

                      <b-button
                        v-if="$Can('see_exercise_fxOption') && seeExerciseOption"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="top-btns mt-1"
                        @click="getExerciseSelectedOption(data.item)"
                        :disabled="data.item.Cancelled == 1"
                      >
                        <span class="trades-font">
                          <feather-icon
                            icon="GitMergeIcon"
                            style="margin-right: 0.3rem;"
                          />{{ $t("fx_options.buttons.exercise_selected_option") }}</span
                        >
                      </b-button>
                  </b-popover>
                </div>
                <!-- multiple checkboks trade controls -->
                <div
                    v-if="showMultiplecheckboks"
                    class="d-flex justify-content-center align-items-center compact_controls_button"
                  >
                    <b-form-checkbox
                      plain
                      v-model="data.item.selectedTrade"
                      @change="
                        multiSelectRow(data.item.selectedTrade, data.item)
                      "
                      class="animate__animated animate__fadeIn"
                    >
                    </b-form-checkbox>
                </div>
              </template>

              <!-- Column: TcUti -->
              <template #cell(TcUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUti ? data.item.TcUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: counterparty -->
              <template #cell(Cpty)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Cpty ? data.item.Cpty : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client -->
              <template #cell(Client)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Client ? data.item.Client : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TradeDate -->
              <template #cell(TradeDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TradeDate
                        ? dateFormat(data.item.TradeDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Expiry date -->
              <template #cell(Expiry)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.Expiry ? dateFormat(data.item.Expiry) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ValueDate -->
              <template #cell(ValueDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ValueDate
                        ? dateFormat(data.item.ValueDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SettlDate -->
              <template #cell(SettlDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SettlDate
                        ? dateFormat(data.item.SettlDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TcUser -->
              <template #cell(TcUser)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.TcUser ? data.item.TcUser : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: BuySell -->
              <template #cell(BuySell)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.BuySell ? data.item.BuySell : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Strike -->
              <template #cell(Strike)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Strike ? formatPrice(data.item.Strike, 4) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Notional -->
              <template #cell(Notional)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Notional
                        ? formatPrice(data.item.Notional, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: NotionalCcy -->
              <template #cell(NotionalCcy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.NotionalCcy ? data.item.NotionalCcy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientPremium -->
              <template #cell(ClientPremium)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientPremium != null
                        ? formatPrice(data.item.ClientPremium, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientPremiumCurrency && PremCcy -->
              <template #cell(PremCcy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.PremCcy != null
                        ? data.item.PremCcy
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

             

              <!-- Column: OptionType -->
              <template #cell(OptionType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OptionType ? data.item.OptionType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OptionExercise -->
              <template #cell(OptionExercise)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OptionExercise ? data.item.OptionExercise : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SettlementType -->
              <template #cell(SettlementType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SettlementType ? data.item.SettlementType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CutOffTime -->
              <template #cell(CutOffTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CutOffTime ? data.item.CutOffTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientOpen -->
              <template #cell(ClientOpen)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientOpen
                        ? formatPrice(data.item.ClientOpen, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CptyPrem -->
              <template #cell(CptyPrem)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyPrem
                        ? formatPrice(data.item.CptyPrem, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TcComm -->
              <template #cell(TcComm)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcComm ? formatPrice(data.item.TcComm, 2) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CptyPctPrem -->
              <template #cell(CptyPctPrem)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyPctPrem
                        ? data.item.CptyPctPrem + '%'
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientPctPrem -->
              <template #cell(ClientPctPrem)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientPctPrem
                        ? data.item.ClientPctPrem + '%'
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTime -->
              <template #cell(OrderTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTime ? data.item.OrderTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ExecutionTime -->
              <template #cell(ExecutionTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExecutionTime ? data.item.ExecutionTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyAccount -->
              <template #cell(CptyAcc)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyAcc ? data.item.CptyAcc : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientAccount -->
              <template #cell(ClientAcc)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientAcc ? data.item.ClientAcc : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyTrader -->
              <template #cell(CptyTrader)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyTrader ? data.item.CptyTrader : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTakenVia  -->
              <template #cell(OrderTakenVia)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderGivenThrough  -->
              <template #cell(OrderGivenThrough)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderGivenThrough
                        ? data.item.OrderGivenThrough
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ActingCapacity  -->
              <template #cell(ActingCapacity)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ActingCapacity ? data.item.ActingCapacity : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SpotRef  -->
              <template #cell(SpotRef)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SpotRef
                        ? formatPrice(data.item.SpotRef, 4)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TraderNote  -->
              <template #cell(TraderNote)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TraderNote ? data.item.TraderNote : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Prop  -->
              <template #cell(Prop)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Prop ? "Yes" : "No" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CollateralCcy  -->
              <template #cell(CollateralCcy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CollateralCcy ? data.item.CollateralCcy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Collateral  -->
              <template #cell(Collateral)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Collateral
                        ? formatPrice(data.item.Collateral, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CollateralPct  -->
              <template #cell(CollateralPct)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CollateralPct ? data.item.CollateralPct : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: WindowStart  -->
              <template #cell(WindowStart)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.WindowStart
                        ? dateFormat(data.item.WindowStart)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: WindowEnd  -->
              <template #cell(WindowEnd)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.WindowEnd
                        ? dateFormat(data.item.WindowEnd)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ConfirmationDate  -->
              <template #cell(ConfirmationDate)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.ConfirmationDate
                        ? dateFormat(data.item.ConfirmationDate)
                        : "-"
                    }}</strong>
                  </span></b
                > </template
              >,

              <!-- Column: ConfirmationTime  -->
              <template #cell(ConfirmationTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ConfirmationTime
                        ? data.item.ConfirmationTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ConfirmationTrader  -->
              <template #cell(ConfirmationTrader)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ConfirmationTrader
                        ? data.item.ConfirmationTrader
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Counterparty UTI  -->
              <template #cell(CptyUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyUti ? data.item.CptyUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: UniqueLinkId  -->
              <template #cell(UniqueLinkId)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClearingMemberId  -->
              <template #cell(ClearingMemberId)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClearingMemberId
                        ? data.item.ClearingMemberId
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: T&C Confo Date  -->
              <template #cell(ConfirmationDateClient)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.ConfirmationDateClient
                        ? dateFormat(data.item.ConfirmationDateClient)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: T&C Confo Time  -->
              <template #cell(ConfirmationTimeClient)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ConfirmationTimeClient
                        ? data.item.ConfirmationTimeClient
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Counterparty Ref  -->
              <template #cell(CpRef)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CpRef ? data.item.CpRef : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: EntryType  -->
              <template #cell(EntryType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.EntryType ? data.item.EntryType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SavedBy -->
              <template #cell(SavedBy)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.SavedBy ? data.item.SavedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveDate -->
              <template #cell(SaveDate)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.SaveDate != null
                        ? dateFormat(data.item.SaveDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveTime -->
              <template #cell(SaveTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SaveTime != null ? data.item.SaveTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendedBy -->
              <template #cell(AmendedBy)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.AmendedBy ? data.item.AmendedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentDate -->
              <template #cell(AmendmentDate)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.AmendmentDate != null
                        ? dateFormat(data.item.AmendmentDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentTime -->
              <template #cell(AmendmentTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentTime != null
                        ? data.item.AmendmentTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentReason -->
              <template #cell(AmendmentReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentReason
                        ? data.item.AmendmentReason
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Cancelled -->
              <template #cell(Cancelled)="data">
                <b
                  ><span class="font-weight-bold text-white">
                    <strong>{{
                      data.item.Cancelled == 1 ? "Cancelled" : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelledBy -->
              <template #cell(CancelledBy)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.CancelledBy ? data.item.CancelledBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelDate -->
              <template #cell(CancelDate)="data">
                <b
                  ><span class="font-weight-bold" style="white-space: nowrap;">
                    <strong>{{
                      data.item.CancelDate != null
                        ? dateFormat(data.item.CancelDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelTime -->
              <template #cell(CancelTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelTime != null ? data.item.CancelTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelReason -->
              <template #cell(CancelReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelReason ? data.item.CancelReason : "-"
                    }}</strong>
                  </span></b
                >
              </template>
            </b-table>
          </div>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkbox"
                  : "Hide multiple checkbox"
              }}</span>
            </b-link>
          </li>
          <li v-if="!showOrderColumns">
              <b-link
                class="d-flex align-items-center"
                @click="optionRightClicked('orderTableColumns')"
              >
              <i class="fa-solid fa-arrow-up-wide-short"></i>
                <span class="ml-75">{{ 'Sort columns'
                }}</span>
              </b-link>
            </li>
          <li v-if="selectedMultipleTrades.length > 0">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon icon="CopyIcon" size="16" />
              <span class="ml-75">{{
                $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>

        <div class="mt-1" v-if="allFxOptionsData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted" style="font-size:0.8rem"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 compact_paginate"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="allFxOptionsData.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{ $t("general_title.not_found_data") }}</strong>
                <br />
                {{ $t("fx_options.messages.not_found_fxoptions_data") }}</span
              >
            </div>
          </b-alert>
        </div>
      </b-card>
        </b-overlay>

      {{ /*search for trades modal*/ }}

      <SearchTrades
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counter-parties.sync="searchedCounterparties"
        ref="searchTrades"
        @searchedData="getSearchedData"
      />

      {{ /*View Selected Trade for trades modal*/ }}
      <ViewSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counter-parties.sync="searchedCounterparties"
        ref="ViewSelectedTrade"
      />

      {{ /*Enter New Trade for trades modal*/ }}
      <EnterNewTrade
        ref="refEnterNewTrade"
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        @createdFxoption="createdFxoption"
      />
      {{ /*Verify selected Trade for trades modal*/ }}
      <VerifyTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refVerifyTrade"
        @verified="verifiedTrade"
      />
      {{ /*Amend selected Trade for trades modal*/ }}
      <AmendSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refAmendTrade"
        @updatedData="getUpdatedAmendData"
      />
      {{ /*Copy selected Trade for trades modal*/ }}
      <CopySelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refCopyTrade"
        @copiedFxoption="copiedFxoption"
      />
      {{ /*Option exercise selected Trade for trades modal*/ }}
      <OptionExerciseSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refOptionExerciseTrade"
        @exercisedOption="exercisedOption"
      />
      {{ /*Create Journal selected Trade for trades modal*/ }}
      <!-- <CreateJournal
        :opt-counter-parties.sync = "optCounterParties"
          :opt-clients.sync = "optClients"
          :opt-cost-centers.sync = "optCostCenters"
          :searched-clients.sync = "searchedClients"
          :searched-counter-parties.sync = "searchedCounterparties"
          ref="refCreateJournalTrade"
          @createdJournalEntryArray="createdJournalEntryArray"
        /> -->
      {{ /*View Journal selected Trade for trades modal*/ }}
      <!-- <ViewJournalEntries
        :opt-counter-parties.sync = "optCounterParties"
          :opt-clients.sync = "optClients"
          :opt-cost-centers.sync = "optCostCenters"
          :searched-clients.sync = "searchedClients"
          :searched-counter-parties.sync = "searchedCounterparties"
          ref="refViewJournalEntries"
          @createdJournalEntry="createdJournalEntry"
        /> -->
      {{ /*Inititate reversal Trade for trades modal*/ }}
      <!-- <InitiateReversal
        :opt-counter-parties.sync = "optCounterParties"
          :opt-clients.sync = "optClients"
          :opt-cost-centers.sync = "optCostCenters"
          :searched-clients.sync = "searchedClients"
          :searched-counter-parties.sync = "searchedCounterparties"
          ref="refInitiateReversal"
          @reversaledTrade="reversaledTrade"
        /> -->
      <!-- <ReverseEntry
        :opt-counter-parties.sync = "optCounterParties"
          :opt-clients.sync = "optClients"
          :opt-cost-centers.sync = "optCostCenters"
          :searched-clients.sync = "searchedClients"
          :searched-counter-parties.sync = "searchedCounterparties"
        ref="refReverseEntry" @reversedEntry="reversedEntry" /> -->
      {{ /*Undo selected Trade for trades modal*/ }}
      <!-- <UndoInitiateReversal
        :opt-counter-parties.sync = "optCounterParties"
          :opt-clients.sync = "optClients"
          :opt-cost-centers.sync = "optCostCenters"
          :searched-clients.sync = "searchedClients"
          :searched-counter-parties.sync = "searchedCounterparties"
          ref="refUndoInitiateReversal"
          @undoInitiate="undoInitiate"
        /> -->
    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.failes_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(Tcuti)="data">
                  <strong>{{ data.item.Tcuti }}</strong>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>
    {{/****END*** view modal failed multi select process*/}}
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXOptionsStoreModule from "./FXOptionsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState, mapGetters, mapActions } from "vuex";
import SearchTrades from "./SearchTrades.vue";
import ViewSelectedTrade from "./ViewSelectedTrade.vue";
import EnterNewTrade from "./EnterNewTrade.vue";
import VerifyTrade from "./VerifyTrade.vue";
import AmendSelectedTrade from "./AmendSelectedTrade.vue";
import CopySelectedTrade from "./CopySelectedTrade.vue";
import OptionExerciseSelectedTrade from "./OptionExerciseSelectedTrade.vue";
//   import CreateJournal from "./CreateJournal.vue";
//   import ViewJournalEntries from "./ViewJournalEntries.vue";
//   import InitiateReversal from "./InitiateReversal.vue";
//   import ReverseEntry from "./ReverseEntry.vue";
//   import UndoInitiateReversal from "./UndoInitiateReversal.vue";
import VueHorizontal from "vue-horizontal";
import VueContext from "vue-context";
import { debounce } from "lodash";
import draggable from 'vuedraggable'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    SearchTrades,
    ViewSelectedTrade,
    EnterNewTrade,
    VerifyTrade,
    AmendSelectedTrade,
    CopySelectedTrade,
    OptionExerciseSelectedTrade,
    //   CreateJournal,
    //   ViewJournalEntries,
    //   InitiateReversal,
    //   ReverseEntry,
    //   UndoInitiateReversal,
    Treeselect,
    VueHorizontal,
    VueContext,
    draggable
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
    // Register module
    if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXOPTION_APP_STORE_MODULE_NAME,
        fXOptionsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getOrderFxOptionColumns();

    //option status
    let treeOptionsStatus = [
      {
        id: 1000000,
        label: this.$t("fx_options.status"),
        children: [],
      },
    ];
    this.fxOptionsStatusOptions.forEach((element) => {
      let setStatus = {
        id: element.text,
        label: element.text,
        value: element.text,
        is: "status",
      };
      treeOptionsStatus[0].children.push(setStatus);
    });

    this.statusOptions = treeOptionsStatus;
    //************************** */

    this.getLast7DaysTrades();
    this.getAllAccounts();

    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    const fxOptionsStatusOptions = [
      { text: "Verified" },
      { text: "Cancelled" },
    ];

    const fxOptiontableColumns = [
      // { key: "show_details", label: "" },

      {
        stickyColumn: true,
        key: "ID",
        label: "ID",
        // thClass: "text-left",
        tdClass: "text-center ID_background",
        thClass: "sticky-column ",
        thStyle: "width: 50px",
        sortable: true,
        class: "text-center table-padding sticky-column",
        variant: "primary",
      },
      {
        key: "TcUti",
        label: this.$t("fx_options.table.tcuti"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "TcUser",
        label: this.$t("fx_options.table.operations_user"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "Cpty",
        label: this.$t("fx_options.table.counterparty"),
        class: "text-start table-padding",
      },
      {
        key: "CptyAcc",
        label: this.$t("fx_options.table.CounterpartyAccount"),
        class: "text-start table-padding",
      },
      {
        key: "Client",
        label: this.$t("fx_options.table.client"),
        class: "text-start table-padding",
      },

      {
        key: "ClientAcc",
        label: this.$t("fx_options.table.ClientAccount"),
        class: "text-start table-padding",
      },
      {
        key: "CptyTrader",
        label: this.$t("fx_options.table.CounterpartyTrader"),
        class: "text-start table-padding",
      },
      {
        key: "TradeDate",
        label: this.$t("fx_options.table.trade_date"),
        class: "text-start table-padding text-nowrap",
      },

      {
        key: "Expiry",
        label: this.$t("fx_options.table.expiry"),
        class: "text-start table-padding",
      },

      {
        key: "ValueDate",
        label: this.$t("fx_options.table.value_date"),
        class: "text-start table-padding text-nowrap",
      },

      {
        key: "SettlDate",
        label: this.$t("fx_options.table.settle_date"),
        class: "text-start table-padding text-nowrap",
      },

      {
        key: "BuySell",
        label: this.$t("fx_options.table.buy_sell"),
        class: "text-start table-padding",
      },
      {
        key: "Strike",
        label: this.$t("fx_options.table.strike"),
        class: "text-start table-padding",
      },

      {
        key: "Notional",
        label: this.$t("fx_options.table.notional"),
        class: "text-start table-padding",
      },

      {
        key: "NotionalCcy",
        label: this.$t("fx_options.table.notional_currency"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "ClientPremium",
        label: this.$t("fx_options.table.client_premium"),
        class: "text-start table-padding",
      },

      {
        key: "PremCcy",
        label: this.$t("fx_options.table.client_premium_currency"),
        class: "text-start table-padding",
        sortable: true,
      },

      {
        key: "OptionType",
        label: this.$t("fx_options.table.option_type"),
        class: "text-start table-padding",
      },
      {
        key: "OptionExercise",
        label: this.$t("fx_options.table.option_exercise"),
        class: "text-start table-padding",
      },
      {
        key: "SettlementType",
        label: this.$t("fx_options.table.settlement_type"),
        class: "text-start table-padding",
      },
      {
        key: "CutOffTime",
        label: this.$t("fx_options.table.cutofftime"),
        class: "text-start table-padding",
      },
      {
        key: "ClientOpen",
        label: this.$t("fx_options.table.client_open"),
        class: "text-start table-padding",
      },

      {
        key: "CptyPrem",
        label: this.$t("fx_options.table.cpty_open"),
        class: "text-start table-padding",
      },

      {
        key: "TcComm",
        label: this.$t("fx_options.table.tc_comission"),
        class: "text-start table-padding",
        sortable: true,
      },

      {
        key: "CptyPctPrem",
        label: this.$t("fx_options.table.CounterpartyPctPremium"),
        class: "text-start table-padding",
      },

      {
        key: "ClientPctPrem",
        label: this.$t("fx_options.table.ClientPctPremium"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "OrderTime",
        label: this.$t("fx_options.table.OrderTime"),
        class: "text-start table-padding",
      },
      {
        key: "ExecutionTime",
        label: this.$t("fx_options.table.ExecutionTime"),
        class: "text-start table-padding",
      },

      {
        key: "OrderTakenVia",
        label: this.$t("fx_options.table.OrderTakenVia"),
        class: "text-start table-padding",
      },
      {
        key: "OrderGivenThrough",
        label: this.$t("fx_options.table.OrderGivenThrough"),
        class: "text-start table-padding",
      },
      {
        key: "ActingCapacity",
        label: this.$t("fx_options.table.ActingCapacity"),
        class: "text-start table-padding",
      },
      {
        key: "SpotRef",
        label: this.$t("fx_options.table.SpotRef"),
        class: "text-start table-padding",
      },
      {
        key: "TraderNote",
        label: this.$t("fx_options.table.TraderNote"),
        class: "text-start table-padding",
      },
      {
        key: "Prop",
        label: this.$t("fx_options.table.Prop"),
        class: "text-start table-padding",
      },
      {
        key: "CollateralCcy",
        label: this.$t("fx_options.table.CollateralCcy"),
        class: "text-start table-padding",
      },
      {
        key: "Collateral",
        label: this.$t("fx_options.table.Collateral"),
        class: "text-start table-padding",
      },
      {
        key: "CollateralPct",
        label: this.$t("fx_options.table.CollateralPct"),
        class: "text-start table-padding",
      },
      {
        key: "WindowStart",
        label: this.$t("fx_options.table.WindowStart"),
        class: "text-start table-padding",
      },
      {
        key: "WindowEnd",
        label: this.$t("fx_options.table.WindowEnd"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationDate",
        label: this.$t("fx_options.table.CptyConfoDate"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationTime",
        label: this.$t("fx_options.table.CptyConfoTime"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationTrader",
        label: this.$t("fx_options.table.ConfirmationTrader"),
        class: "text-start table-padding",
      },
      {
        key: "CptyUti",
        label: this.$t("fx_options.table.CptyUti"),
        class: "text-start table-padding",
      },
      {
        key: "UniqueLinkId",
        label: this.$t("fx_options.table.UniqueLinkId"),
        class: "text-start table-padding",
      },
      {
        key: "ClearingMemberId",
        label: this.$t("fx_options.table.ClearingMemberId"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationDateClient",
        label: this.$t("fx_options.table.ConfirmationDateClient"),
        class: "text-start table-padding",
      },
      {
        key: "ConfirmationTimeClient",
        label: this.$t("fx_options.table.ConfirmationTimeClient"),
        class: "text-start table-padding",
      },
      {
        key: "CpRef",
        label: this.$t("fx_options.table.CpRef"),
        class: "text-start table-padding",
      },
      {
        key: "EntryType",
        label: this.$t("fx_options.table.EntryType"),
        class: "text-start table-padding",
      },
      {
        key: "SavedBy",
        label: this.$t("fx_options.table.saved_by"),
        class: "text-start table-padding",
      },
      {
        key: "SaveDate",
        label: this.$t("fx_options.table.save_date"),
        class: "text-start text-nowrap table-padding",
        sortable: true,
      },
      {
        key: "SaveTime",
        label: this.$t("fx_options.table.save_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendedBy",
        label: this.$t("fx_options.table.amended_by"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentDate",
        label: this.$t("fx_options.table.amendment_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "AmendmentTime",
        label: this.$t("fx_options.table.amendment_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentReason",
        label: this.$t("fx_options.table.amendment_reason"),
        class: "text-start table-padding",
      },

      {
        key: "Cancelled",
        label: this.$t("fx_options.table.cancelled"),
        class: "text-start table-padding",
      },
      {
        key: "CancelledBy",
        label: this.$t("fx_options.table.cancelled_by"),
        class: "text-start table-padding",
      },
      {
        key: "CancelDate",
        label: this.$t("fx_options.table.canceled_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "CancelTime",
        label: this.$t("fx_options.table.cancelled_time"),
        class: "text-start table-padding",
      },
      {
        key: "CancelReason",
        label: this.$t("fx_options.table.cancelled_reason"),
        class: "text-start table-padding",
      },
      // {
      //   stickyColumn: true,
      //   key: "TradeActions",
      //   label: "Actions",
      //   class: "text-center",
      //   isRowHeader: true,
      //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
      // },
    ];

    return {
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.id"
          ),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "Tcuti",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.tcUti"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 30%",
        },
        {
          key: "reason",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.reason"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 50%",
        },
      ],
      windowHeight: window.innerHeight,
      userData: JSON.parse(localStorage.getItem("userData")),
      visibleFxOptionStatusInput: false,
      allFxOptionsData: [],
      allFxOptionsNotFilterData: [],
      visibleFxOptionStatusInput: false,
      sortByDesc: true,
      sortBy: "ID",
      filterOn: [
        "TcUser",
        "Cpty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      optSettlementTypes: [
        { title: "all", value: "" },
        { title: "Physical", value: "Physical" },
        { title: "Cash", value: "Cash" },
      ],
      filters: {
        SettlementType: "",
        TcUti: "",
        TcUser: "",
      },
      tableColumns: null,
      tableOrjinalColumns: null,
      fxOptiontableColumns:fxOptiontableColumns,
      perPage: localStorage.getItem("FxOptionTradesShow") ?? 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      filter: null,
      loading: false,
      filterSettleDate: null,
      filterTradeDate: null,
      canceledReasonText: null,
      statusOptions: [],
      tableColumnsOptions: [],
      fxOptionsStatusOptions: fxOptionsStatusOptions,
      treeStatus: null,
      treeTablecolumns: [],
      expiryRangeDate: null,
      filterExpiryDateDateStart: null,
      filterExpiryDateDateEnd: null,
      SettleRangeDate: null,
      filterSettleDateDateStart: null,
      filterSettleDateDateEnd: null,
      tradeRangeDate: null,
      filterTradeDateStart: null,
      filterTradeDateEnd: null,
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode: "range",
      },

      optClients: [],
      optCounterParties: [],
      searchedClients: [],
      searchedCounterparties: [],
      optCostCenters: [],
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      showOrderColumns:false,
      orderColumnsOverlay:false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeAmendDetailsButton: false,
      seeVerifyButton: false,
      seeAmendButton:false,
      seeCopyButton:false,
      seeJournalButton: false,
      seeJorurnalEntriesButton: false,
      seeReversalInitiatedButton: false,
      seeUndoInitiatedButton: false,
      seeReversedButton: false,
      seeCancelledButton: false,
      seeGetPairedOpr:false,
      bringSameOptions:false,
      seeExerciseOption:false,
      
    };
  },
  computed: {
    ...mapState({
      lastSearchData: (state) => state.runLastFxOptionsSearchData,
      userColumnsData: (state) => state.usersOrderColumnsFxOption,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["getUserOrderColumnsFxOption"]),

    filteredTrades() {
      if (this.allFxOptionsData.length > 0) {
        const filtered = this.allFxOptionsData.filter((item) => {
          return Object.keys(this.filters).every((key) =>
            String(item[key]).includes(this.filters[key])
          );
        });
        return filtered.length > 0
          ? filtered
          : filtered
      }
    },

    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    ...mapActions(["saveOrderColumnsFxOption","getOrderColumnsFxOption"]),

    getWindowHeight() {
      this.windowHeight = window.innerHeight;
      //console.log(this.windowHeight)
      this.updateStickyHeaderHeight()

      },

      updateStickyHeaderHeight() {
        this.$nextTick(() => {
              const headerElements = document.getElementsByClassName("b-table-sticky-header");
              if (headerElements.length > 0) {
                const newHeight = window.innerHeight - 550;
                this.stickyHeaderStyle = `height: ${newHeight}px`;
              }
            });
          },



    getLast7DaysTrades() {
      this.$checkAccessRight("FXOptions", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("fx_options/getLast7DaysFxOptionTrades")
            .then((res) => {
              this.allFxOptionsData = [];
              if (res.data.length > 0) {
                this.visibleFxOptionStatusInput = true;
              } else {
                this.visibleFxOptionStatusInput = false;
              }
              this.totalRows = res.data.length;
              this.allFxOptionsData = res.data;
              this.allFxOptionsNotFilterData = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    showTradeControlsPopover(targetTrade, trade) {
      if (trade) {
        // set default
        this.seeAmendDetailsButton = false;
        this.seeVerifyButton = false;
        this.seeJournalButton = false;
        this.seeCopyButton = false;
        this.seeAmendButton = false;
        this.seeJorurnalEntriesButton = false;
        this.seeReversalInitiatedButton = false;
        this.seeUndoInitiatedButton = false;
        this.seeReversedButton = false;
        this.seeCancelledButton = false;
        this.seeGetPairedOpr = false;
        this.bringSameOptions = false;
        this.seeExerciseOption = false;

        // set suitable flow buttons

        // see amend details
        if (
          trade.AmendedBy != null &&
          trade.AmendmentDate != null &&
          trade.AmendmentTime != null && 
          trade.Cancelled == 0
        ) {
          this.seeAmendDetailsButton = true;
        }

        // see amend trade button
        if(trade.Cancelled == 0){
            this.seeAmendButton = true;
          }
          // see copy trade button
        if(trade.Cancelled == 0){
          this.seeCopyButton = true;
        }

        // see verify
        if (trade.Verified == 0 && trade.Cancelled == 0) {
          this.seeVerifyButton = true;
        }
        // see journal
        if (trade.Verified == 1 && trade.JournalCreated == 0 && trade.Cancelled == 0) {
          this.seeJournalButton = true;
        }

        // see trade journalEntries
        if (trade.Verified == 1 && trade.JournalCreated == 1 && trade.Cancelled == 0) {
          this.seeJorurnalEntriesButton = true;
        }

        // see revers initiated
        if (
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeReversalInitiatedButton = true;
        }

        // see undo initiated
        if (
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 1 &&
          trade.Reversed == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeUndoInitiatedButton = true;
        }

        // see reversed
        if (
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 1 &&
          trade.Reversed == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeReversedButton = true;
        }

        // if cancelled trade it can not do
        if (
          trade.CancelDate == null &&
          trade.Cancelled == 0 &&
          trade.CancelTime == null
        ) {
          this.seeCancelledButton = true;
        }

        // Bring same options selected client
        if (
            trade.CancelDate == null &&
            trade.Cancelled == 0 &&
            trade.CancelTime == null
          ) {
            this.bringSameOptions = true;
          }

        // get paired trades
        if (
            trade.CancelDate == null &&
            trade.Cancelled == 0 &&
            trade.CancelTime == null
          ) {
            this.seeGetPairedOpr = true;
          }

          // see exercşse selected option trades
        if (
            trade.CancelDate == null &&
            trade.Cancelled == 0 &&
            trade.CancelTime == null
          ) {
            this.seeExerciseOption = true;
          }
      }

      this.$root.$emit("bv::show::popover", targetTrade);
    },

    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }

      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];

        // this.tableColumns[1].class = this.showMultiplecheckboks
        //   ? "text-center"
        //   : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allFxOptionsData, (v) => (v.selectedTrade = false));
      }
      if(click == "orderTableColumns"){
          this.showOrderColumns = !this.showOrderColumns
          this.orderColumnsOverlay = true
          // hide main columns
          this.$refs.refFXoptionsTable.$el.classList.add("hide-rows")
  
          // visible draggable order columns
          var draggableColumns = document.getElementById("draggableColumns")
          draggableColumns.style.display = ""
        }
    },
    getOrderFxOptionColumns(){
  
      this.getOrderColumnsFxOption().then((res) => {
        // get order user columns
        if (this.userColumnsData.find((data) => data.user == this.user.name)) {
          let orderedColumnsData = this.getUserOrderColumnsFxOption
          this.tableColumns = orderedColumnsData
          this.tableOrjinalColumns = this.fxOptiontableColumns
          
        } else {
        
          this.tableColumns = this.fxOptiontableColumns
          this.tableOrjinalColumns = this.fxOptiontableColumns
        }
        
        //******************************* */

          // tree columns options
          let treeColumnsOption = [
        {
          id: 1000000,
          label: this.$t("fx_options.columns"),
          children: [],
        },
      ];

      this.tableOrjinalColumns.forEach((element, index) => {
        if (element.key != "ID") {
          var setColumn = {
            id: index,
            label: element.key,
            value: element.key,
            is: "column",
          };
          treeColumnsOption[0].children.push(setColumn);

          if(this.tableColumns.find((column) => column.key === element.key)){
            this.treeTablecolumns.push(setColumn);
          }
        }
        
      });
      this.tableColumnsOptions = treeColumnsOption;
      
      //this.treeTablecolumns = treeColumnsOption[0].children;
      //******************* */

      }).catch((error) => {
        this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
        console.log(error);
      });
    },
    onSaveOrderColumns(){
      if(this.tableColumns && this.tableColumns.length > 0){
          this.saveOrderColumnsFxOption(this.tableColumns).then((res) => {
            // visible main columns
          this.$refs.refFXoptionsTable.$el.classList.remove("hide-rows");
          // hide draggable order columns
          var draggableColumns = document.getElementById("draggableColumns");
          draggableColumns.style.display = "none";
          this.showOrderColumns = false;
          this.orderColumnsOverlay = false
          this.successOrderColumnsMessage()
          }).catch((error) => {
            console.log(error);
          });
      } else {
        this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
      }
    },
    onCancelOrderColumn(){
      // visible main columns
      this.$refs.refFXoptionsTable.$el.classList.remove("hide-rows");
      // hide draggable order columns
      var draggableColumns = document.getElementById("draggableColumns");
      draggableColumns.style.display = "none";
      this.showOrderColumns = false;
      this.orderColumnsOverlay = false;
    },
    selectTreeColumn(node){

      if(this.tableOrjinalColumns.find((c) => c.key === node.value)){
        let columnIndex = this.tableOrjinalColumns.findIndex((i) => i.key === node.value)
        let columnData = this.tableOrjinalColumns.find((c) => c.key === node.value)
        this.tableColumns.splice(columnIndex,0,columnData)
      }        
      
    },
    deSelectTreeColumn(node){
      if(this.tableColumns.find((c) => c.key === node.value)){
        let columnIndex = this.tableColumns.findIndex((i) => i.key === node.value)
        this.tableColumns.splice(columnIndex,1)
      }  
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          ID: trade.ID,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];
      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Create Journal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Revers Inititated") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Reversed") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Undo Initiate Reversal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Cancel") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }
      
      if (status == "copyTrades") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (trades.length > 0 && status) {
        if (status == "copyTrades") {
         
          this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
          this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },


    getAllAccounts() {
      store
        .dispatch("fx_options/getAccountCostCenter", { type: "FxOption" })
        .then((res) => {
          this.optClients = [];
          this.optCounterParties = [];
          this.searchedClients = res.data.clients;
          this.searchedCounterparties = res.data.clients;
          this.optCostCenters = [];
          this.optCostCenters = res.data.cost_centers;
          this.$refs.refEnterNewTrade.tradeForm.costCenter =
            res.data.default_cost_center;
          this.$refs.refAmendTrade.tradeForm.costCenter =
            res.data.default_cost_center;
          if (res.data.clients.length > 0) {
            res.data.clients.forEach((data) => {
              this.optClients.push(data.AccountName);
            });

            res.data.clients.forEach((data) => {
              this.optCounterParties.push(data.AccountName);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initiateReversal(item) {
      this.$checkAccessRight("FXOptions", "InitiateReversal").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.checkJournalCreated("InitiateReversal", item.TcUti).then(
                (res) => {
                  if (res.data.info == true) {
                    this.checkEntryReversed("Initiate", item.TcUti).then(
                      (res) => {
                        if (res.data.info == true) {
                          this.checkReversalInitiated(
                            "InitiateReversal",
                            item.TcUti
                          ).then((res) => {
                            if (res.data.info == true) {
                              store
                                .dispatch(
                                  "fx_options/getAmendFxOptionInitialise",
                                  {
                                    auth: this.user,
                                    tradeId: item.ID,
                                    tcUti: item.TcUti,
                                  }
                                )
                                .then((res) => {
                                  if (res.data.info == "Pass") {
                                    if (this.getChoice()) {
                                      this.$refs.refInitiateReversal.fetchedTradeData =
                                        res.data.trade;

                                      //set timeline datas
                                      let actionsArray = [];
                                      if (
                                        res.data.trade.SavedBy != null &&
                                        res.data.trade.SavedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Saved",
                                          user: res.data.trade.SavedBy ?? "",
                                          date:
                                            res.data.trade.SaveDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.SaveTime.split(
                                              " "
                                            )[1],
                                          reason: "-",
                                        });
                                      }

                                      if (
                                        res.data.trade.AmendedBy != null &&
                                        res.data.trade.AmendedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Amended",
                                          user: res.data.trade.AmendedBy ?? "",
                                          date:
                                            res.data.trade.AmendmentDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.AmendmentTime.split(
                                              " "
                                            )[1],
                                          reason:
                                            res.data.trade.AmendmentReason,
                                        });
                                      }
                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .VerificationUser != null &&
                                          res.data.actions[0]
                                            .VerificationUser != ""
                                        ) {
                                          this.$refs.refInitiateReversal.checkVerify = true;
                                          actionsArray.push({
                                            type: "Verified",
                                            user:
                                              res.data.actions[0]
                                                .VerificationUser ?? "",
                                            date:
                                              res.data.actions[0]
                                                .VerificationDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].JournalUser !=
                                            null &&
                                          res.data.actions[0].JournalUser != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Journal Created",
                                            user:
                                              res.data.actions[0].JournalUser ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .JournalDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (
                                        res.data.trade.CancelledBy != null &&
                                        res.data.trade.CancelledBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Canceled",
                                          user:
                                            res.data.trade.CancelledBy ?? "",
                                          date:
                                            res.data.trade.CancelDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.CancelTime.split(
                                              " "
                                            )[1],
                                          reason: res.data.trade.CancelReason,
                                        });
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != null &&
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversal Initiated",
                                            user:
                                              res.data.actions[0]
                                                .ReversalInitiatedBy ?? "",
                                            date:
                                              res.data.actions[0]
                                                .ReversalInitiatedDateTime,
                                            reason:
                                              res.data.actions[0]
                                                .ReversalInitiatedReason,
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].ReversedBy !=
                                            null &&
                                          res.data.actions[0].ReversedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversed",
                                            user:
                                              res.data.actions[0].ReversedBy ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .ReversedDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      this.$refs.refInitiateReversal.actionsData = actionsArray;
                                      this.$refs.refInitiateReversal.initiateReversalTradeModal = true;
                                    } else {
                                      let verifyList = [];
                                      verifyList.push(item);
                                      this.tradeMultipleChangeStatusOpr(
                                        1,
                                        verifyList,
                                        "Revers Inititated"
                                      );
                                    }
                                  } else {
                                    this.notFoundMessage();
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            } else {
                              this.checkReversalInitiatedMessage(
                                res.data.message
                              );
                            }
                          });
                        } else {
                          this.checkEntryReversedMessage(res.data.message);
                        }
                      }
                    );
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                }
              );
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    reverseEntry(item) {
      this.$checkAccessRight("FXOptions", "Reverse").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.checkJournalCreated("Reverse", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  this.checkEntryReversed("Reverse", item.TcUti).then((res) => {
                    if (res.data.info == true) {
                      this.checkReversalInitiated("Reverse", item.TcUti).then(
                        (res) => {
                          if (res.data.info == true) {
                            store
                              .dispatch("fx_options/getFxOptionReverseEntry", {
                                auth: this.user,
                                tradeId: item.ID,
                                tcUti: item.TcUti,
                              })
                              .then((res) => {
                                if (res.data.info == "Pass") {
                                  if (this.getChoice()) {
                                    this.$refs.refReverseEntry.fetchedTradeData =
                                      res.data.trade;

                                    //set timeline datas
                                    let actionsArray = [];
                                    if (
                                      res.data.trade.SavedBy != null &&
                                      res.data.trade.SavedBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Saved",
                                        user: res.data.trade.SavedBy ?? "",
                                        date:
                                          res.data.trade.SaveDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.SaveTime.split(" ")[1],
                                        reason: "-",
                                      });
                                    }

                                    if (
                                      res.data.trade.AmendedBy != null &&
                                      res.data.trade.AmendedBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Amended",
                                        user: res.data.trade.AmendedBy ?? "",
                                        date:
                                          res.data.trade.AmendmentDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.AmendmentTime.split(
                                            " "
                                          )[1],
                                        reason: res.data.trade.AmendmentReason,
                                      });
                                    }
                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].VerificationUser !=
                                          null &&
                                        res.data.actions[0].VerificationUser !=
                                          ""
                                      ) {
                                        this.$refs.refReverseEntry.checkVerify = true;
                                        actionsArray.push({
                                          type: "Verified",
                                          user:
                                            res.data.actions[0]
                                              .VerificationUser ?? "",
                                          date:
                                            res.data.actions[0]
                                              .VerificationDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].JournalUser !=
                                          null &&
                                        res.data.actions[0].JournalUser != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Journal Created",
                                          user:
                                            res.data.actions[0].JournalUser ??
                                            "",
                                          date:
                                            res.data.actions[0].JournalDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (
                                      res.data.trade.CancelledBy != null &&
                                      res.data.trade.CancelledBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Canceled",
                                        user: res.data.trade.CancelledBy ?? "",
                                        date:
                                          res.data.trade.CancelDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.CancelTime.split(
                                            " "
                                          )[1],
                                        reason: res.data.trade.CancelReason,
                                      });
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != null &&
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Reversal Initiated",
                                          user:
                                            res.data.actions[0]
                                              .ReversalInitiatedBy ?? "",
                                          date:
                                            res.data.actions[0]
                                              .ReversalInitiatedDateTime,
                                          reason:
                                            res.data.actions[0]
                                              .ReversalInitiatedReason,
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].ReversedBy !=
                                          null &&
                                        res.data.actions[0].ReversedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Reversed",
                                          user:
                                            res.data.actions[0].ReversedBy ??
                                            "",
                                          date:
                                            res.data.actions[0]
                                              .ReversedDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    this.$refs.refReverseEntry.actionsData = actionsArray;
                                    this.$refs.refReverseEntry.reverseEntryTradeModal = true;
                                  } else {
                                    let verifyList = [];
                                    verifyList.push(item);
                                    this.tradeMultipleChangeStatusOpr(
                                      1,
                                      verifyList,
                                      "Reversed"
                                    );
                                  }
                                } else {
                                  this.notFoundMessage();
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          } else {
                            this.checkReversalInitiatedMessage(
                              res.data.message
                            );
                          }
                        }
                      );
                    } else {
                      this.checkEntryReversedMessage(res.data.message);
                    }
                  });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    undoInitiateReversal(item) {
      this.$checkAccessRight("FXOptions", "UndoInitiateReversal").then(
        (res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
              if (item.ID != 0) {
                this.checkEntryReversed(
                  "UndoInitiateReversal",
                  item.TcUti
                ).then((res) => {
                  if (res.data.info == true) {
                    this.checkReversalInitiated(
                      "UndoInitiateReversal",
                      item.TcUti
                    ).then((res) => {
                      if (res.data.info == true) {
                        store
                          .dispatch(
                            "fx_options/getFxOptionUndoInitiateReversal",
                            {
                              auth: this.user,
                              tradeId: item.ID,
                              tcUti: item.TcUti,
                            }
                          )
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              if (this.getChoice()) {
                                this.$refs.refUndoInitiateReversal.fetchedTradeData =
                                  res.data.trade;

                                //set timeline datas
                                let actionsArray = [];
                                if (
                                  res.data.trade.SavedBy != null &&
                                  res.data.trade.SavedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Saved",
                                    user: res.data.trade.SavedBy ?? "",
                                    date:
                                      res.data.trade.SaveDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.SaveTime.split(" ")[1],
                                    reason: "-",
                                  });
                                }

                                if (
                                  res.data.trade.AmendedBy != null &&
                                  res.data.trade.AmendedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Amended",
                                    user: res.data.trade.AmendedBy ?? "",
                                    date:
                                      res.data.trade.AmendmentDate.split(
                                        " "
                                      )[0] +
                                      " " +
                                      res.data.trade.AmendmentTime.split(
                                        " "
                                      )[1],
                                    reason: res.data.trade.AmendmentReason,
                                  });
                                }
                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].VerificationUser !=
                                      null &&
                                    res.data.actions[0].VerificationUser != ""
                                  ) {
                                    this.$refs.refUndoInitiateReversal.checkVerify = true;
                                    actionsArray.push({
                                      type: "Verified",
                                      user:
                                        res.data.actions[0].VerificationUser ??
                                        "",
                                      date:
                                        res.data.actions[0]
                                          .VerificationDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].JournalUser != null &&
                                    res.data.actions[0].JournalUser != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Journal Created",
                                      user:
                                        res.data.actions[0].JournalUser ?? "",
                                      date: res.data.actions[0].JournalDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (
                                  res.data.trade.CancelledBy != null &&
                                  res.data.trade.CancelledBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Canceled",
                                    user: res.data.trade.CancelledBy ?? "",
                                    date:
                                      res.data.trade.CancelDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.CancelTime.split(" ")[1],
                                    reason: res.data.trade.CancelReason,
                                  });
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      null &&
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversal Initiated",
                                      user:
                                        res.data.actions[0]
                                          .ReversalInitiatedBy ?? "",
                                      date:
                                        res.data.actions[0]
                                          .ReversalInitiatedDateTime,
                                      reason:
                                        res.data.actions[0]
                                          .ReversalInitiatedReason,
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversedBy != null &&
                                    res.data.actions[0].ReversedBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversed",
                                      user:
                                        res.data.actions[0].ReversedBy ?? "",
                                      date:
                                        res.data.actions[0].ReversedDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                this.$refs.refUndoInitiateReversal.actionsData = actionsArray;
                                this.$refs.refUndoInitiateReversal.undoInitiateTradeModal = true;
                              } else {
                                let verifyList = [];
                                verifyList.push(item);
                                this.tradeMultipleChangeStatusOpr(
                                  1,
                                  verifyList,
                                  "Undo Initiate Reversal"
                                );
                              }
                            } else {
                              this.notFoundMessage();
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.checkReversalInitiatedMessage(res.data.message);
                      }
                    });
                  } else {
                    this.checkEntryReversedMessage(res.data.message);
                  }
                });
              } else {
                this.tradeIDDoesntZeroMessage();
              }
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    cancelSelectedTrade(item) {
      this.$checkAccessRight("FXOptions", "Cancel").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
                  this.tradeCancelReasonText().then((res) => {
                    if (res) {
                      store
                        .dispatch("fx_options/getCanceledFxOptionById", {
                          auth: this.user,
                          tradeId: item.ID,
                          reason: this.canceledReasonText,
                          trade: item,
                        })
                        .then((res) => {
                          if (res.data.info == "Pass") {
                            this.tradeCancelledMessage();
                            this.runLastSearch();
                            this.canceledReasonText = null;
                          } else {
                            this.tradeCancelledErrorMessage(res.data.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      this.cancelOpearionNotDoneMessage();
                    }
                  });
                } else {
                  this.tradeIDDoesntZeroMessage();
                }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getPairedSelectedTrade(item) {
      this.$checkAccessRight("FXOptions", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            if (item.ID != 0) {
              this.$router.push({
                path: `/fx-option/selected-paired-trades/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
      },
    getSameOptionsSelectedClient(item) {
      this.$checkAccessRight("FXOptions", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            if (item.ID != 0) {
              this.$router.push({
                path: `/fx-option/selected-same-options-client/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    getExerciseSelectedOption(item) {
      this.$checkAccessRight("FXOptions", "ExerciseOption").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
                if (item.ID != 0) {
                    store
                      .dispatch("fx_options/getExerciseSelectedOption", {
                        auth: this.user,
                        tradeId: item.ID
                      })
                      .then((res) => {
                        if(res.data.info == "Pass"){
                          this.getAllAccounts();
                          this.$refs.refOptionExerciseTrade.fetchedTradeData = res.data.trade;
                          this.$refs.refOptionExerciseTrade.optionExerciseTradeModal = true;
                          this.$refs.refOptionExerciseTrade.exerciseCptyTrader = res.data.counterpartyTrader; // set found cpty traders
                        } else {
                          this.warningToastMessage(res.data.message);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                } else {
                  this.tradeIDDoesntZeroMessage();
                }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    async tradeCancelReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("fx_options.reason.cancel_reason"),
        text: self.$t("fx_options.reason.please_enter_cancel_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "fx_options.reason.please_enter_cancel_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("fx_options.reason.submit"),
        cancelButtonText: self.$t("fx_options.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.canceledReasonText = reason;
              resolve();
            } else {
              resolve(self.$t("fx_options.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          //if status journal set journal data
          // if (status == "Create Journal") {
          //   if (trades.length > 0) {
          //     var setTradesData = [];
          //     trades.forEach((trade) => {
          //       setTradesData.push(self.setTradeData(trade));
          //     });
          //   }
          //   trades = setTradesData;
          // }

          store
            .dispatch("fx_options/tradeMultipleChangeFxOptionStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
              reason: result.value ? result.value : null,
            })
            .then((res) => {
              self.loading = false;
              if (res.data.failedProcesses.length > 0) {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData =
                  res.data.failedProcesses;
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();

                if (trades.length == 1 && status == "Revers Inititated") {
                  let val = {
                    info: true,
                    tradeId: trades[0].ID,
                    tcUti: trades[0].tcUti,
                    reason: result.value ? result.value : null,
                  };
                  this.reversaledTrade(val);
                }
                _.mapValues(
                  this.allFxOptionsData,
                  (v) => (v.selectedTrade = false)
                );
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    tradeMultipleSelectCopyOpr(tradesCount, trades) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_copy_title"),
        text: tradesCount + self.$t("multiCheckBoxTitles.trades_copy_message"),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // set trade data
          if (trades.length > 0) {
            var setTradesData = [];
            trades.forEach((trade) => {
              setTradesData.push(self.setTradeData(trade));
            });
          }
          console.log(setTradesData)
          trades = setTradesData; //change arranged trade datas

          store
            .dispatch("fx_options/tradeMultipleSelectFxOptionCopyOpr", {
              auth: self.user,
              trades: trades,
            })
            .then((res) => {
              self.loading = false;
              self.selectedMultipleTrades = [];

              if (res.data.failedCopyOpr.length > 0) {
                self.multiSelectCopyWarningMessage();
                self.okMultiSelectProcessModal();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
              } else if (res.data.info == "not_found") {
                self.wrongToastMessage(res.data.message);
              } else {
                self.multiSelectAllTradesCopySuccessMessage();
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    okMultiSelectProcessModal() {
      this.showMultiSelectProcessModal = false;
      this.multiSelectProcessModalTableData = [];
      _.mapValues(this.allFxOptionsData, (v) => (v.selectedTrade = false));

        this.getLast7DaysTrades();
   
    },

    searchForTradesModal() {
      this.$checkAccessRight("FXOptions", "Search").then((res) => {
        if (res.data.valid) {
          this.$refs.searchTrades.searchForTradesModal = true;
          this.$refs.searchTrades.getSavedSearches();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createNewTrade() {
      this.$checkAccessRight("FXOptions", "Save").then((res) => {
        if (res.data.valid) {
          this.getAllAccounts();

          this.$refs.refEnterNewTrade.enterNewTradeModal = true;
          this.$refs.refEnterNewTrade.getSavedDefaultEntries();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getSearchedData(data) {
      this.totalRows = data.length;
      if (data.length > 0) {
        this.visibleFxOptionStatusInput = true;
      }
      this.allFxOptionsData = data;
      this.allFxOptionsNotFilterData = data;
      this.$refs.searchTrades.searchForTradesModal = false;
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.filterTradeDate = null;
      this.filterSettleDateDateStart = null;
      this.filterSettleDateDateEnd = null;
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.SettleRangeDate = null;
      this.expiryRangeDate = null;
      this.filterSettleDate = null;
    },
    getUpdatedAmendData(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdFxoption(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    copiedFxoption(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    exercisedOption(){
      if (val) {
        this.runLastSearch();
      }
    },
    createdJournalEntryArray(journalData) {
      if (journalData.success) {
        this.$refs.refViewJournalEntries.fetchedTradeData = journalData.trade;
        this.$refs.refViewJournalEntries.journalEntries = journalData.entries;
        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
        this.$refs.refViewJournalEntries.createJournalOperation = true;
      }
    },
    createdJournalEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    reversaledTrade(val) {
      const self = this;
      if (val.info) {
        this.runLastSearch();
        this.$swal({
          title: self.$t("fx_options.mail.title"),
          text: self.$t("fx_options.mail.message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("fx_options.mail.send"),
          cancelButtonText: self.$t("fx_options.mail.not_send"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("fx_options/sendInitiateReversalMail", {
                auth: self.user,
                tcUti: val.tcUti,
                tradeId: val.tradeId,
                reason: val.reason,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  self.sendedMailSuccess();
                } else {
                  self.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    reversedEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    undoInitiate(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    verifiedTrade(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    legendClass(item, type) {
      // const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      // const journalCreateRowColor = "table-journalCreate";
      // const expiredOrderRowColor = "table-expiredOrder";
      // const reversedInitiateRowColor = "table-reverseInitiated";
      // const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      // if (item.Reversed == 1) {
      //   return reversedRowColor;
      // }
      // if (item.ReversalInitiated == 1) {
      //   return reversedInitiateRowColor;
      // }
      // if (item.ExpiredOrder == 1) {
      //   return expiredOrderRowColor;
      // }
      // if (item.JournalCreated == 1) {
      //   return journalCreateRowColor;
      // }
      // if (item.Verified == 1) {
      //   return verifiedRowColor;
      // }
      // if (item.EntryType == "Execution") {
      //   return executedRowColor;
      // }
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    runLastSearch(buttonSearch = false) {
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.filterTradeDate = null;
      this.filterSettleDateDateStart = null;
      this.filterSettleDateDateEnd = null;
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.SettleRangeDate = null;
      this.expiryRangeDate = null;
      this.filterSettleDate = null;

        this.$checkAccessRight("FXOptions", "Search").then((res) => {
          if (res.data.valid) {
            this.loading = true;
            store
                .dispatch("fx_options/getLastSearch", {
                  auth: this.user,
                  searchType:'FxOption'
                })
                .then((res) => {

                  if(res.data != 'Not Found'){

                if(res.data.length>0){

                  this.allFxOptionsData = [];
                  if (res.data.length > 0) {
                    this.visibleFxOptionStatusInput = true;
                  } else {
                    this.visibleFxOptionStatusInput = true;
                  }
                  this.totalRows = res.data.length;
                  this.allFxOptionsData = res.data;
                  this.allFxOptionsNotFilterData = res.data;
                  this.loading = false;
  
                    }
                    else{
                      if(buttonSearch) {  
                      this.notFoundSearchData();
                      }
                      this.getLast7DaysTrades();
                    }
                    }
                    else{
                      if(buttonSearch) {  
                      this.notFoundSearchData();
                      }
                    this.getLast7DaysTrades();
                    this.loading = false;
                    }


                
                })
                .catch((error) => {
                  this.loading = false;
                  console.log(error);
                });
        
          } else {
            this.notAuthToastMessage();
          }
        });
  
    },
    getfilterTradeDate(e) {
      if (this.tradeRangeDate != null) {
        this.$checkAccessRight("FxOptions", "Search").then((res) => {
          if (res.data.valid) {
              let filterTradeDateStart = null;
              let filterTradeDateEnd = null;
              if (
                this.filterTradeDateStart != null &&
                this.filterTradeDateEnd != null
              ) {
                filterTradeDateStart = this.YMDdateFormat(
                  this.filterTradeDateStart
                );
                filterTradeDateEnd = this.YMDdateFormat(this.filterTradeDateEnd);
              }
  
              
                if (filterTradeDateStart != null && filterTradeDateEnd != null) {
                  this.loading = true;
                  store
                    .dispatch("fx_options/fetchFilterTradeDate", {
                      auth: this.user,
                      tradeStartDate: filterTradeDateStart,
                      tradeEndDate: filterTradeDateEnd,
                    })
                    .then((res) => {
                      this.allFxOptionsData = [];
                      if (res.data.filterDatas.length > 0) {
                        this.visibleFxOptionStatusInput = true;
                        this.totalRows = res.data.filterDatas.length;
                        this.allFxOptionsData = res.data.filterDatas;
                      } else {
                        this.visibleFxOptionStatusInput = false;
                      }
  
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              // else {
              //   this.notAuthToastMessage();
              // }
            } 
          //   else {
          //   this.notAuthToastMessage();
          // }
        });
      }
    },
    getFilterSettleDate() {
      if (this.SettleRangeDate != null) {
        this.$checkAccessRight("FXOptions", "Search").then((res) => {
          if (res.data.valid) {
              let filterSettleDateDateStart = null;
              let filterSettleDateDateEnd = null;
              if (
                this.filterSettleDateDateStart != null &&
                this.filterSettleDateDateEnd != null
              ) {
                filterSettleDateDateStart = this.YMDdateFormat(
                  this.filterSettleDateDateStart
                );
                filterSettleDateDateEnd = this.YMDdateFormat(this.filterSettleDateDateEnd);
              }
  
              
                if (filterSettleDateDateStart != null && filterSettleDateDateEnd != null) {
                  this.loading = true;
                  store
                    .dispatch("fx_options/fetchFilterSettleDate", {
                      auth: this.user,
                      settleStartDate: filterSettleDateDateStart,
                      settleEndDate: filterSettleDateDateEnd,
                    })
                    .then((res) => {
                      this.allFxOptionsData = [];
                      if (res.data.filterDatas.length > 0) {
                        this.visibleFxOptionStatusInput = true;
                        this.totalRows = res.data.filterDatas.length;
                        this.allFxOptionsData = res.data.filterDatas;
                      } else {
                        this.visibleFxOptionStatusInput = false;
                      }
  
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              // else {
              //   this.notAuthToastMessage();
              // }
            } 
          //   else {
          //   this.notAuthToastMessage();
          // }
        });
      }
    },
    getFilterExpiryDate() {
      if (this.expiryRangeDate != null) {
        this.$checkAccessRight("FXOptions", "Search").then((res) => {
          if (res.data.valid) {
              let filterExpiryDateDateStart = null;
              let filterExpiryDateDateEnd = null;
              if (
                this.filterExpiryDateDateStart != null &&
                this.filterExpiryDateDateEnd != null
              ) {
                filterExpiryDateDateStart = this.YMDdateFormat(
                  this.filterExpiryDateDateStart
                );
                filterExpiryDateDateEnd = this.YMDdateFormat(this.filterExpiryDateDateEnd);
              }
  
              
                if (filterExpiryDateDateStart != null && filterExpiryDateDateEnd != null) {
                  this.loading = true;
                  store
                    .dispatch("fx_options/fetchFilterExpiryDate", {
                      auth: this.user,
                      expiryStartDate: filterExpiryDateDateStart,
                      expiryEndDate: filterExpiryDateDateEnd,
                    })
                    .then((res) => {
                      this.allFxOptionsData = [];
                      if (res.data.filterDatas.length > 0) {
                        this.visibleFxOptionStatusInput = true;
                        this.totalRows = res.data.filterDatas.length;
                        this.allFxOptionsData = res.data.filterDatas;
                      } else {
                        this.visibleFxOptionStatusInput = false;
                      }
  
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              // else {
              //   this.notAuthToastMessage();
              // }
            } 
          //   else {
          //   this.notAuthToastMessage();
          // }
        });
      }
    },

    

    verifySelectedTrade(item) {
      this.$checkAccessRight("FXOptions", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkTradeOperations(item).then((res) => {
                if (res == true) {
                  store
                    .dispatch("fx_options/getVerifySelectedFxOptionTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        if (this.getChoice()) {
                          this.$refs.refVerifyTrade.verifySelectedTradeModal = true;
                          this.$refs.refVerifyTrade.fetchedTradeData =
                            res.data.trade;

                          // set changes inputs
                          if (res.data.changedInputs) {
                            let setChangedInputs = _.map(
                              res.data.changedInputs,
                              (value, key) => ({ key, value })
                            );

                            if (setChangedInputs.length > 0) {
                              this.$refs.refVerifyTrade.changedInputs = setChangedInputs;
                            }
                          }

                          //set timeline datas
                          let actionsArray = [];
                          if (
                            res.data.trade.SavedBy != null &&
                            res.data.trade.SavedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Saved",
                              user: res.data.trade.SavedBy ?? "",
                              date:
                                res.data.trade.SaveDate.split(" ")[0] +
                                " " +
                                res.data.trade.SaveTime,
                              reason: "-",
                            });
                          }

                          if (
                            res.data.trade.AmendedBy != null &&
                            res.data.trade.AmendedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Amended",
                              user: res.data.trade.AmendedBy ?? "",
                              date:
                                res.data.trade.AmendmentDate.split(" ")[0] +
                                " " +
                                res.data.trade.AmendmentTime,
                              reason: res.data.trade.AmendmentReason,
                            });
                          }
                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].VerificationUser != null &&
                              res.data.actions[0].VerificationUser != ""
                            ) {
                              actionsArray.push({
                                type: "Verified",
                                user:
                                  res.data.actions[0].VerificationUser ?? "",
                                date: res.data.actions[0].VerificationDateTime,
                                reason: "-",
                              });
                            }
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].JournalUser != null &&
                              res.data.actions[0].JournalUser != ""
                            ) {
                              actionsArray.push({
                                type: "Journal Created",
                                user: res.data.actions[0].JournalUser ?? "",
                                date: res.data.actions[0].JournalDateTime,
                                reason: "-",
                              });
                            }
                          }

                          if (
                            res.data.trade.CancelledBy != null &&
                            res.data.trade.CancelledBy != ""
                          ) {
                            actionsArray.push({
                              type: "Canceled",
                              user: res.data.trade.CancelledBy ?? "",
                              date:
                                res.data.trade.CancelDate.split(" ")[0] +
                                " " +
                                res.data.trade.CancelTime,
                              reason: res.data.trade.CancelReason,
                            });
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].ReversalInitiatedBy != null &&
                              res.data.actions[0].ReversalInitiatedBy != ""
                            ) {
                              actionsArray.push({
                                type: "Reversal Initiated",
                                user:
                                  res.data.actions[0].ReversalInitiatedBy ?? "",
                                date:
                                  res.data.actions[0].ReversalInitiatedDateTime,
                                reason:
                                  res.data.actions[0].ReversalInitiatedReason,
                              });
                            }
                          }

                          if (res.data.actions.length > 0) {
                            if (
                              res.data.actions[0].ReversedBy != null &&
                              res.data.actions[0].ReversedBy != ""
                            ) {
                              actionsArray.push({
                                type: "Reversed",
                                user: res.data.actions[0].ReversedBy ?? "",
                                date: res.data.actions[0].ReversedDateTime,
                                reason: "-",
                              });
                            }
                          }

                          this.$refs.refVerifyTrade.actionsData = actionsArray;
                        } else {
                          let verifyList = [];
                          verifyList.push(item);
                          this.tradeMultipleChangeStatusOpr(
                            1,
                            verifyList,
                            "Verify"
                          );
                        }
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    amendUpdate(item) {
      this.$checkAccessRight("FXOptions", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              
                  store
                    .dispatch("fx_options/getAmendSelectedFxOption", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.getAllAccounts();
                        this.$refs.refAmendTrade.fetchedTradeData =
                          res.data.trade;
                        this.$refs.refAmendTrade.amendTradeModal = true;
                        

                        // set changes inputs
                        if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );

                          if (setChangedInputs.length > 0) {
                            this.$refs.refAmendTrade.changedInputs = setChangedInputs;
                          }
                        }

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime,
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime,
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refAmendTrade.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime,
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refAmendTrade.actionsData = actionsArray;
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createJournalEntries(item) {
      this.$checkAccessRight("FXOptions", "CreateJournal").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("fx_options/checkJournalTradeVerifyOperation", {
              auth: this.user,
              trade: item,
            })
            .then((res) => {
              if (res.data.info == "verified") {
                this.checkJournalCreated("Journal", item.TcUti).then((res) => {
                  if (res.data.info == true) {
                    store
                      .dispatch("fx_options/getCreateJournalFxOptionTrade", {
                        auth: this.user,
                        tradeId: item.ID,
                        tcUti: item.TcUti,
                      })
                      .then((res) => {
                        if (res.data.info == "Pass") {
                          if (res.data.trade.Cancelled == "0") {
                            if (this.getChoice()) {
                              this.$refs.refCreateJournalTrade.fetchedTradeData =
                                res.data.trade;

                              // set changes inputs
                              if (res.data.changedInputs) {
                                let setChangedInputs = _.map(
                                  res.data.changedInputs,
                                  (value, key) => ({ key, value })
                                );

                                if (setChangedInputs.length > 0) {
                                  this.$refs.refCreateJournalTrade.changedInputs = setChangedInputs;
                                }
                              }

                              //set timeline datas
                              let actionsArray = [];
                              if (
                                res.data.trade.SavedBy != null &&
                                res.data.trade.SavedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Saved",
                                  user: res.data.trade.SavedBy ?? "",
                                  date:
                                    res.data.trade.SaveDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.SaveTime.split(" ")[1],
                                  reason: "-",
                                });
                              }

                              if (
                                res.data.trade.AmendedBy != null &&
                                res.data.trade.AmendedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Amended",
                                  user: res.data.trade.AmendedBy ?? "",
                                  date:
                                    res.data.trade.AmendmentDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.AmendmentTime.split(" ")[1],
                                  reason: res.data.trade.AmendmentReason,
                                });
                              }
                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].VerificationUser !=
                                    null &&
                                  res.data.actions[0].VerificationUser != ""
                                ) {
                                  this.$refs.refCreateJournalTrade.checkVerify = true;
                                  actionsArray.push({
                                    type: "Verified",
                                    user:
                                      res.data.actions[0].VerificationUser ??
                                      "",
                                    date:
                                      res.data.actions[0].VerificationDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].JournalUser != null &&
                                  res.data.actions[0].JournalUser != ""
                                ) {
                                  actionsArray.push({
                                    type: "Journal Created",
                                    user: res.data.actions[0].JournalUser ?? "",
                                    date: res.data.actions[0].JournalDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (
                                res.data.trade.CancelledBy != null &&
                                res.data.trade.CancelledBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Canceled",
                                  user: res.data.trade.CancelledBy ?? "",
                                  date:
                                    res.data.trade.CancelDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.CancelTime.split(" ")[1],
                                  reason: res.data.trade.CancelReason,
                                });
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversalInitiatedBy !=
                                    null &&
                                  res.data.actions[0].ReversalInitiatedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversal Initiated",
                                    user:
                                      res.data.actions[0].ReversalInitiatedBy ??
                                      "",
                                    date:
                                      res.data.actions[0]
                                        .ReversalInitiatedDateTime,
                                    reason:
                                      res.data.actions[0]
                                        .ReversalInitiatedReason,
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversedBy != null &&
                                  res.data.actions[0].ReversedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversed",
                                    user: res.data.actions[0].ReversedBy ?? "",
                                    date: res.data.actions[0].ReversedDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              this.$refs.refCreateJournalTrade.actionsData = actionsArray;
                              this.$refs.refCreateJournalTrade.createJournalTradeModal = true;
                            } else {
                              let verifyList = [];
                              verifyList.push(item);
                              this.tradeMultipleChangeStatusOpr(
                                1,
                                verifyList,
                                "Create Journal"
                              );
                            }
                          } else {
                            this.canceledToastMessage();
                          }
                        } else {
                          this.notFoundMessage();
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                });
              } else {
                this.checkTradeVerifyOperationMessage(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("FXOptions", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            store
              .dispatch("fx_options/getViewSelectedFxOptionTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                  this.$refs.ViewSelectedTrade.fetchedTradeData =
                    res.data.trade;

                    // set changes red color inputs
                    if (res.data.changedInputs) {
                        let setChangedInputs = _.map(
                          res.data.changedInputs,
                          (value, key) => ({ key, value })
                        );
                        if (setChangedInputs.length > 0) {
                          this.$refs.ViewSelectedTrade.changedInputs = setChangedInputs;
                        }
                      }

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? "",
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? "",
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != ""
                    ) {
                      this.$refs.ViewSelectedTrade.checkVerify = true;
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? "",
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != ""
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? "",
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != ""
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? "",
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? "",
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? "",
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    copySelectedTrade(item) {
      this.$checkAccessRight("FXOptions", "Save").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("fx_options/getCopySelectedFxOptionTrade", {
              auth: this.user,
              tradeId: item.ID,
              tcUti: item.TcUti,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                if (this.getChoice()) {
                  this.getAllAccounts();
                  this.$refs.refCopyTrade.fetchedTradeData = res.data.trade;
                  this.$refs.refCopyTrade.copyTradeModal = true;
                  

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? "",
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? "",
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != ""
                    ) {
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? "",
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != ""
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? "",
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != ""
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? "",
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? "",
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? "",
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.refCopyTrade.actionsData = actionsArray;
                } else {
                  let verifyList = [];
                  verifyList.push(item);
                  this.tradeMultipleSelectCopyOpr(1, verifyList);
                }
              } else {
                this.notFoundMessage();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getAmendDetails(item) {
      this.$checkAccessRight("FXOptions", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            if (item.ID != 0) {
              this.$router.push({
                path: `/fx-option/amend-details/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    viewJournalEntries(item) {
      this.$checkAccessRight("Journal", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            this.checkJournalCreated("JournalDisplay", item.TcUti).then(
              (res) => {
                if (res.data.info == true) {
                  //set and check trade data
                  let tradeData = this.setTradeData(item);
                  /////

                  store
                    .dispatch("fx_options/getViewFxOptionJournalTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                      trade: tradeData,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refViewJournalEntries.journalEntries =
                          res.data.entries;

                        this.$refs.refViewJournalEntries.createJournalOperation = false;

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[1],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[1],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refViewJournalEntries.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[1],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refViewJournalEntries.actionsData = actionsArray;
                        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;

                        if (res.data.amount_info == "not_zero") {
                          this.checkJournalOperationsMessage(
                            res.data.message,
                            10000
                          );
                        }
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              }
            );
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    checkTradeOperations(trade) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("fx_options/checkTradeVerifyOperation", {
            auth: this.user,
            trade: trade,
          })
          .then((res) => {
            if (res.data.info == "not_verified") {
              store
                .dispatch("fx_options/checkFxOptionTradeUserOperation", {
                  auth: this.user,
                  trade: trade,
                })
                .then((res) => {
                  if (res.data.info == "user_doesnt_same") {
                    if (trade.EntryType == "Order") {
                      this.$swal({
                        title: this.$t("fx_options.swal.order_trade"),
                        text: this.$t(
                          "fx_options.swal.entry_saved_order_want_amend_trade"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: this.$t(
                          "fx_options.swal.confirm_button"
                        ),
                        cancelButtonText: this.$t(
                          "fx_options.swal.cancel_button"
                        ),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.amendUpdate(trade);
                        }
                      });
                    } else {
                      resolve(true);
                    }
                  } else if (
                    res.data.info == "same_user_amend" ||
                    res.data.info == "same_user_save"
                  ) {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  } else {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.checkTradeVerifyOperationMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUserOperationMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fx_options/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("fx_options.messages.tc_uti_not_found"));
      }
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fx_options/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("fx_options.messages.tc_uti_not_found"));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fx_options/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("fx_options.messages.tc_uti_not_found"));
      }
    },

    setTradeData(val) {
      let setTrade = {
          CostCenter: val.CostCenter != null ? val.CostCenter : null,
          Client: val.Client != null ? val.Client : null,
          ClientAcc: val.ClientAcc != null ? val.ClientAcc : null,
          Cpty: val.Cpty != null ? val.Cpty : null,
          CptyAcc: val.CptyAcc != null ? val.CptyAcc : null,
          BuySell: val.BuySell != null ? val.BuySell : null,
          Prop: val.Prop,
          SettlementType: val.SettlementType != null ? val.SettlementType : null,
          Ccy1: val.Ccy1 != null ? val.Ccy1 : null,
          Ccy2: val.Ccy2 != null ? val.Ccy2 : null,
          OptionType: val.OptionType != null ? val.OptionType : null,
          OptionExercise: val.OptionExercise != null ? val.OptionExercise : null,
          Type1: val.Type1 != null ? val.Type1 : null,
          Type1Level: val.Type1Level != null ? parseFloat(val.Type1Level) : null,
          Type1Exercise: val.Type1Exercise != null ? val.Type1Exercise : null,
          Type2: val.Type2 != null ? val.Type2 : null,
          Type2Level: val.Type2Level != null ? parseFloat(val.Type2Level) : null,
          Type2Exercise: val.Type2Exercise != null ? val.Type2Exercise : null,
          WindowStart: val.WindowStart != null ? this.dateFormat(val.WindowStart) : null,
          WindowEnd: val.WindowEnd != null ? this.dateFormat(val.WindowEnd) : null,
          CallPut1: val.CallPut1 != null ? val.CallPut1 : null,
          CallPut2: val.CallPut2 != null ? val.CallPut2 : null,
          Strike: val.Strike != null ? parseFloat(val.Strike) : null,
          Expiry: val.Expiry != null ? this.dateFormat(val.Expiry) : null,
          ValueDate: val.ValueDate != null ? this.dateFormat(val.ValueDate) : null,
          NotionalCcy: val.NotionalCcy != null ? val.NotionalCcy : null,
          Notional: val.Notional != null ? parseFloat(val.Notional) : null,
          OpenNotional: val.OpenNotional != null ? parseFloat(val.OpenNotional) : null,
          SpotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
          PremCcy: val.PremCcy != null ? val.PremCcy : null,
          CptyPctPrem: val.CptyPctPrem != null ? parseFloat(val.CptyPctPrem) : null,
          ClientPctPrem: val.ClientPctPrem != null ? parseFloat(val.ClientPctPrem) : null,
          CptyPrem: val.CptyPrem != null ? parseFloat(val.CptyPrem) : null,
          ClientPrem: val.ClientPrem != null ? parseFloat(val.ClientPrem) : null,
          TcComm: val.TcComm != null ? parseFloat(val.TcComm) : null,
          TradeDate: val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
          SettlDate: val.SettlDate != null ? this.dateFormat(val.SettlDate) : null,
          OrderTime: val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
          ExecutionTime: val.ExecutionTime != null ? this.HmstimeFormat(val.ExecutionTime) : null,
          CptyTrader: val.CptyTrader != null ? val.CptyTrader : null,
          TcUser: val.TcUser != null ? val.TcUser : null,
          OrderTakenVia: val.OrderTakenVia != null ? val.OrderTakenVia : null,
          OrderGivenThrough: val.OrderGivenThrough != null ? val.OrderGivenThrough : null,
          ActingCap: val.ActingCap != null ? val.ActingCap : null,
          TraderNote: val.TraderNote != null ? val.TraderNote : null,
          CollateralPct: val.CollateralPct != null ? parseFloat(val.CollateralPct) : null,
          Collateral: val.Collateral != null ? parseFloat(val.Collateral) : null,
          CollateralCcy: val.CollateralCcy != null ? val.CollateralCcy : null,
          ClientOpen: val.ClientOpen != null ? parseFloat(val.ClientOpen) : null,
          CpRef: val.CpRef != null ? val.CpRef : null,
          CutOffTime: val.CutOffTime != null ? val.CutOffTime : null,
          DeliverableCcy: val.DeliverableCcy != null ? val.DeliverableCcy : null,
          ConfirmationDate: val.ConfirmationDate != null ? this.dateFormat(val.ConfirmationDate) : null,
          ConfirmationTime: val.ConfirmationTime != null ? this.HmstimeFormat(val.ConfirmationTime) : null,
          ConfirmationTrader: val.ConfirmationTrader != null ? val.ConfirmationTrader : null,
          CptyUti: val.CptyUti != null ? val.CptyUti : null,
          TcUti: val.TcUti != null ? val.TcUti : null,
          UniqueLinkId: val.UniqueLinkId != null ? val.UniqueLinkId : null,
          ClearingMemberId: val.ClearingMemberId != null ? val.ClearingMemberId : null,
          ConfirmationDateClient: val.ConfirmationDateClient != null ? this.dateFormat(val.ConfirmationDateClient) : null,
          ConfirmationTimeClient: val.ConfirmationTimeClient != null ? this.HmstimeFormat(val.ConfirmationTimeClient) : null,
          ID: val.id
        };


      return setTrade;
    },

    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_options.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("fx_options.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    canceledToastMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.trade_cancelled_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cancelOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.cancelled_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    sendedMailSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_options.mail.mail_send_success"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    tradeCancelledErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("fx_options.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    tradeCancelledMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("fx_options.toast_messages.trade_cancelled_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMatchPairedTrades(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.messages.not_match_paired"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_options.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss")._i;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    clearTradeDate() {
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.filterTradeDate = null;
      this.runLastSearch();
    },
    clearSettleDate() {
      this.filterSettleDateDateStart = null;
      this.filterSettleDateDateEnd = null;
      this.SettleRangeDate = null;
      this.filterSettleDate = null;
      this.runLastSearch();
    },
    clearExpiryDate() {
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.expiryRangeDate = null;
      this.filterExpiryDate = null;
      this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // if(this.windowTop > 300) {
      //   console.log(this.$refs.refFXoptionsTable.$el)
      // }
    },
    getChoice() {
      return this.userData.personal_settings.find(
        (e) => e.key == "fx_options_ticket"
      ).choice;
    },

    exportXlsx() {
      let columns = [];
      this.tableColumns.forEach((element) => {
        columns.push(element.key);
      });

      let datas = this.filteredTrades.map((item) => {
        const orderedItem = {};
        columns.forEach((column) => {
          if (column == "EntryType2") {
            orderedItem[column] = "Main Entry";
          }

          if (column in item) {
            if (column == "Cancelled") {
              if (item[column] == 1) {
                orderedItem[column] = "Cancelled";
              } else {
                orderedItem[column] = "Not Cancelled";
              }
            } else {
              orderedItem[column] = item[column];
            }
          }
        });
        return orderedItem;
      });

      let columnNames = [];

      this.tableColumns.forEach((element) => {
        columnNames.push(element.label);
      });

      store
        .dispatch("fx_options/exportXlsx", {
          data: datas,
          headings: columnNames,
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "FxOption.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportCsv() {
      let columns = [];
      this.tableColumns.forEach((element) => {
        columns.push(element.key);
      });

      let datas = this.filteredTrades.map((item) => {
        const orderedItem = {};
        columns.forEach((column) => {
          if (column == "EntryType2") {
            orderedItem[column] = "Main Entry";
          }

          if (column in item) {
            if (column == "Cancelled") {
              if (item[column] == 1) {
                orderedItem[column] = "Cancelled";
              } else {
                orderedItem[column] = "Not Cancelled";
              }
            } else {
              orderedItem[column] = item[column];
            }
          }
        });
        return orderedItem;
      });

      let columnNames = [];

      this.tableColumns.forEach((element) => {
        columnNames.push(element.label);
      });

      store
        .dispatch("fx_options/exportCsv", {
          data: datas,
          headings: columnNames,
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "FxOption.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("fx_optionsShow", val);
      },
    },
    SettleRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterSettleDateDateStart = this.SettleRangeDate.split(
              " to "
            )[0];
            this.filterSettleDateDateEnd = this.SettleRangeDate.split(
              " to "
            )[1];

            if (
              this.filterSettleDateDateStart != "null" &&
              this.filterSettleDateDateEnd != "null"
            ) {
            } else {
              this.filterSettleDateDateStart = null;
              this.filterSettleDateDateEnd = null;
            }
          } else {
            this.filterSettleDateDateStart = this.SettleRangeDate;
          }
        }
      },
    },
    expiryRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterExpiryDateDateStart = this.expiryRangeDate.split(
              " to "
            )[0];
            this.filterExpiryDateDateEnd = this.expiryRangeDate.split(
              " to "
            )[1];

            if (
              this.filterExpiryDateDateStart != "null" &&
              this.filterExpiryDateDateEnd != "null"
            ) {
            } else {
              this.filterExpiryDateDateStart = null;
              this.filterExpiryDateDateEnd = null;
            }
          } else {
            this.filterExpiryDateDateStart = this.expiryRangeDate;
          }
        }
      },
    },
    tradeRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterTradeDateStart = this.tradeRangeDate.split(" to ")[0];
            this.filterTradeDateEnd = this.tradeRangeDate.split(" to ")[1];

            // this.listRefresh();
            if (
              this.filterTradeDateStart != "null" &&
              this.filterTradeDateEnd != "null"
            ) {
              //this.listRefresh();
            } else {
              this.filterTradeDateStart = null;
              this.filterTradeDateEnd = null;
            }
          } else {
            this.filterTradeDateStart = this.tradeRangeDate;
          }
        }
      },
    },
    // treeTablecolumns: {
    //   handler: function(val, before) {
    //     this.loading = true;
    //     if (val.length > 0) {
    //       let filteredColumns = [];
    //       filteredColumns.push({
    //         key: "ID",
    //         label: "ID",
    //         // thClass: "text-left",
    //         tdClass: "text-center ID_background",
    //         // thStyle: 'width: 30%',
    //         // sortable: true,
    //         class: "text-center sticky-column",
    //         variant: "primary",
    //       });
    //       this.tableOrjinalColumns.forEach((o) => {
    //         if (val.find((v) => v.value == o.key)) {
    //           filteredColumns.push(o);
    //         }
    //       });
    //       this.tableColumns = filteredColumns;
    //     } else {
    //       this.tableColumns = this.tableOrjinalColumns;
    //     }
    //     this.loading = false;
    //   },
    // },
    treeStatus: {
      handler: function(val, before) {
        let allFilteredData = [];

        if (val.length > 0) {
          val.forEach((s) => {
            if (s.value == "Verified") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxOptionsNotFilterData)
              );

              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Journal Created") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxOptionsNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              console.log(filteredData);

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Revers Inititated") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxOptionsNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reversed") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxOptionsNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 1,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Cancelled") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allFxOptionsNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Cancelled: 1,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            }
          });

          let uniqueData = _.uniqBy(allFilteredData, function(e) {
            return e.ID;
          });

          this.allFxOptionsData = uniqueData;
          this.totalRows = uniqueData.length;
        } else {
          this.allFxOptionsData = this.allFxOptionsNotFilterData;
          this.totalRows = this.allFxOptionsNotFilterData.length;
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          // if (
          //   _.filter(val, {
          //     Verified: 0,
          //     Cancelled: 0,
          //   }).length > 0
          // ) {
          //   this.multiSelectListStatusData.push({
          //     key: "Verify",
          //     title: this.$t("multiCheckBoxTitles.verify"),
          //     icon: "CheckSquareIcon",
          //     color: "green",
          //   });
          // }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Create Journal",
              title: this.$t("multiCheckBoxTitles.journal"),
              icon: "ClipboardIcon",
              color: "#0b4666",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Revers Inititated",
              title: this.$t("multiCheckBoxTitles.revers_initiated"),
              icon: "PlayIcon",
              color: "rgb(255, 255, 153)",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Reversed",
              title: this.$t("multiCheckBoxTitles.reversed"),
              icon: "CornerUpLeftIcon",
              color: "coral",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Undo Initiate Reversal",
              title: this.$t("multiCheckBoxTitles.undo_initiate_reversal"),
              icon: "ChevronsLeftIcon",
              color: "black",
            });
          }

          if (
            _.filter(val, {
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Cancel",
              title: this.$t("multiCheckBoxTitles.cancel"),
              icon: "XSquareIcon",
              color: "#ea5455",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>

.table-executed {
  background-color: #e9fafa !important;
}

.table-verified {
  background-color: #00ff7f94 !important;
}

.table-journalCreate {
  background-color: #e1d7f5 !important;
}

.table-expiredOrder {
  background-color: silver !important;
}

.table-reverseInitiated {
  background-color: #ffff99 !important;
}

.table-reversed {
  background-color: #f8ac91 !important;
}

.table-canceled {
  background-color: #ffb8b8 !important;
}

.TcUti {
  width: 100%;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

/* .b-table-sticky-column:last-child {
  right: 0;
} */

.ID_background {
  background-color: gainsboro !important;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}


.b-table-sticky-header{
  max-height : unset !important;
  height: inherit !important;

}

.hide-rows thead tr[role="row"] { display: none; }
  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(77, 73, 73); 
  }
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>

 <!-- compact design scss code -->
  
 <style lang="scss" scoped>
  
 @import "../../assets/scss/compact-design.scss";
 
 
 .card::v-deep .card-body {
     padding: 0.8rem 0.7rem !important;
   
     .card-title {
         font-weight: 500 !important;
         font-size: 1.285rem !important;
         margin-bottom: 0.6rem !important;
         text-decoration: none !important;
     }
   }
 
 // trade controls buttons
   .top-btns {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
 }
 
 </style>
